import { PT_Org } from "./ModulePermitTypes";
import getOrgClearanceStatuses from "../../util/sys-calls/getOrgClearanceStatuses";
import {
  T_Major,
  T_LeafBool,
  T_LeafOptionsMulti,
  T_LeafOptionsSingle,
} from "./ModulePropTypes";
import trackOrigins from "./trackOrigins";

export const _canPreviewOptions = {
  all: { id: "all", name: "All" },
  none: { id: "none", name: "None" },
  noRestricted: { id: "noRestricted", name: "Exclude Restricted" },
  noSS: { id: "noSS", name: "Exclude StopStream" },
  noResOrSS: { id: "noResOrSS", name: "Exclude Restricted or StopStream" },
};

export const _canPreview = {
  id: "canPreview",
  type: T_LeafOptionsSingle,
  options: _canPreviewOptions,
  default: _canPreviewOptions.none.id,
};

export const _canDownloadOptions = {
  all: { id: "all", name: "All" },
  none: { id: "none", name: "None" },
  noRestricted: { id: "noRestricted", name: "Exclude Restricted" },
  noRestrictedUnclear: {
    id: "noRestrictedUnclear",
    name: "Exclude Restricted with No Clearance",
  },
  noSD: { id: "noSD", name: "Exclude StopDownload" },

  noSDUnclear: {
    id: "noSDUnclear",
    name: "Exclude StopDownload with No Clearance",
  },
  noRestrictedOrSD: {
    id: "noRestrictedOrSD",
    name: "Exclude StopDownload or Restricted",
  },
  noRestrictedOrSDUnclear: {
    id: "noRestrictedOrSDUnclear",
    name: "Exclude StopDownload or Restricted with No Clearance",
  },
  noUnclear: { id: "noUnclear", name: "Exclude All with No Clearance" },
};

export const _canDownloadMp3 = {
  id: "canDownloadMp3",
  type: T_LeafOptionsSingle,
  options: _canDownloadOptions,
  default: _canDownloadOptions.none.id,
};

export const _canDownloadHighres = {
  id: "canDownloadHighres",
  type: T_LeafOptionsSingle,
  options: _canDownloadOptions,
  default: _canDownloadOptions.none.id,
};

const getCFExemptionOpt = ()=> { // ML20-3626 related updates 
  let optList = JSON.parse(JSON.stringify(trackOrigins));
  delete optList.originall;
  delete optList.originlive;
 return optList;
}

export const _clearanceExemptions = {
  id: "clearanceExemptions",
  type: T_LeafOptionsMulti,
  options: getCFExemptionOpt(),
};



export const _mlPlayer = {
  id: "mlPlayer",
  permitType: PT_Org,
  type: T_Major,
  modules: [_clearanceExemptions, _canPreview, _canDownloadMp3, _canDownloadHighres],
};
