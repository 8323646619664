import {
  T_LeafBool,
  T_LeafOptionsMultiOrdered,
  T_Major,
} from "./ModulePropTypes";
import { PT_Setting } from "./ModulePermitTypes";
import trackFields from "./trackFields";

export const _settingSearchShowUserPref = {
  id: "suf1",
  permitType: PT_Setting,
  type: T_LeafBool,
  default: false,
};

export const _settingSearchUserPrefFields = {
  id: "suf11",
  permitType: PT_Setting,
  type: T_LeafOptionsMultiOrdered,
  options: () => {
    let nonetagtype = {};
    Object.keys(trackFields).map((key) => {
      if (!trackFields[key].sys && !trackFields[key].skipUserPreff) nonetagtype[key] = trackFields[key];
    });
    return nonetagtype;
  },
  default: [
    trackFields.id.id,
    trackFields.trackTitle.id,
    trackFields.libName.id,
    trackFields.prodName.id,
    trackFields.catNo.id,
    trackFields.isrc.id,
    trackFields.iswc.id,
    trackFields.prs.id,
    trackFields.alternativeTitle.id,
    trackFields.trackVersionTitle.id,
    trackFields.duration.id,
    trackFields.position.id,
    trackFields.subIndex.id,

    trackFields.prodArtist.id,
    trackFields.performer.id,
    trackFields.composer.id,
    trackFields.arranger.id,
    trackFields.publisher.id,
    trackFields.translator.id,
    trackFields.recordLabel.id,
    trackFields.lyricist.id,

    trackFields.prodYear.id,
    trackFields.prodRelease.id,
    trackFields.prodNumberOfDiscs.id,
    trackFields.prodDiscNr.id,
    
    trackFields.genres.id,
    trackFields.styles.id,
    trackFields.moods.id,
    trackFields.instruments.id,
    trackFields.bpm.id,
    trackFields.tempo.id,
    trackFields.keywords.id,
    trackFields.trackNotes.id,
    trackFields.prodNotes.id,
    trackFields.wsId.id,
    trackFields.wsName.id,
    trackFields.dateCreated.id,
    trackFields.dateLastEdited.id,
  ],
};

export const _settingSearchUserPrefCanSave = {
  id: "suf2",
  permitType: PT_Setting,
  type: T_LeafBool,
  default: true,
};

export const _settingMusicSearchPref = {
  id: "settingMusicSearchPref",
  permitType: PT_Setting,
  type: T_Major,
  modules: [
    _settingSearchShowUserPref,
    _settingSearchUserPrefFields,
    _settingSearchUserPrefCanSave,
  ],
};
