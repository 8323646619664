import { T_LeafBool, T_Major } from "./ModulePropTypes";
import {PT_System } from "./ModulePermitTypes";

export const _manageUsageReports = {
  id: "manageUsageReports",
  type: T_LeafBool,
  default: false,
};

export const _smContentAdmin = {
  id: "smContentAdmin",
  permitType: PT_System,
  type: T_Major,
  modules: [_manageUsageReports],
};
