export const AuthSrc = (url) => {
  if (!url) {
    return process.env.PUBLIC_URL + "/artwork.jpg";
  } else if (url.match(/^\s*https?:\/\/.*\?.*Signature=/)) {
    return url;
  } else {
    return url.replace(
      /^\s*https?:(\/\/|\\\\)/i,
      `${process.env.REACT_APP_CENTRAL_API}/artwork/`
    );
  }
};
