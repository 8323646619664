import axios from "axios";
import store from "redux/Store";
import { pagingTotalRelation } from "redux/Types";
import { v4 as uuidv4 } from "uuid";
import { validateCreateAlbum } from "../../util/validations/content-validations/AlbumValidation";

module = "/updateAlbumContent/";
export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  // LOADING_MORE: "LOADING_MORE" + module,
  // LOAD_MORE: "LOAD_MORE" + module,
  // LOAD_NOMORE: "LOAD_NOMORE" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,
  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,
  SET_PLAYLIST: "SET_PLAYLIST" + module,
  ADD_SESSION_TRACKS: "ADD_SESSION_TRACKS" + module,
  UPDATE_OK: "UPDATE_OK" + module,
  UPDATING: "UPDATING" + module,
  UPLOADING: "UPLOADING" + module,
  SET_ALBUM_NAME: "SET_ALBUM_NAME" + module,
  REFRESH: "REFRESH" + module,
  GETTING_UPDATE_DATA: "GETTING_UPDATE_DATA" + module,
  UPDATE_DATA_OK: "UPDATE_DATA_OK" + module,
  EMPTY_ITEM: "EMPTY_ITEM" + module,
  UPLOAD_EDIT_ITEM: "UPLOAD_EDIT_ITEM" + module,
  TRACK_EDIT_ITEM: "TRACK_EDIT_ITEM" + module,
  ALBUM_OK: "ALBUM_OK" + module,
  ALBUM_EDIT_ITEM: "ALBUM_EDIT_ITEM" + module,
  ADD_TRACKS_SHOW: "ADD_TRACKS_SHOW" + module,
  REMOVE_OK: "REMOVE_OK" + module,
  GETTING_ALBUM_UPDATE_DATA: "GETTING_ALBUM_UPDATE_DATA" + module,
  ALBUM_DATA_OK: "ALBUM_DATA_OK" + module,
  REMOVE_ERRORS: "REMOVE_ERRORS" + module,
  
};

export const editItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.ALBUM_EDIT_ITEM,
    payload: item,
  });
};

export const removeErrors = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.REMOVE_ERRORS,
  });
};

export const addTracksShow = (status) => (dispatch) => {
  dispatch({
    type: actionTypes.ADD_TRACKS_SHOW,
    status: status,
  });
};

export const setRefresh = (status) => (dispatch) => {
  dispatch({
    type: actionTypes.REFRESH,
    status: status,
  });
};


export const uploadEditItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.UPLOAD_EDIT_ITEM,
    payload: item,
  });
};

export const addTracksToAlbum = (action, message, item, done = null) => (dispatch) => {

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/uploads/albums/addTracksToAlbum`, item)
    .then((res) => {
      dispatch({
        type: actionTypes.ALBUM_OK,
        payload: item
      });
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err,
      });
      if (done) done(null, err.message || "error");
    });
}

export const albumPost = (action, message, item, selectedAlbumTracks, sessionId, done = null) => (dispatch) => {
  item.albumMetadata.selectedTracks = selectedAlbumTracks;

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  if (item) {
    let errors = validateCreateAlbum(item.albumMetadata);
    if (errors) {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: errors
      });
      return;
    }
  }

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  var album_allTags = []
  if (item.albumMetadata && item.albumMetadata.org_album_adminTags) {
    item.albumMetadata.org_album_adminTags.forEach((tag) => {
      album_allTags.push({ 'Type': 'BBC_ADMIN_TAG', 'Value': tag });
    })
  }

  if (item.albumMetadata && item.albumMetadata.bbc_album_id) {
    album_allTags.push({ 'Type': 'BBC_ALBUM_ID', 'Value': item.albumMetadata.bbc_album_id });
  }

  if (item.albumMetadata && item.albumMetadata.org_album_admin_notes) {
    album_allTags.push({ 'Type': 'BBC_ADMIN_NOTES', 'Value': item.albumMetadata.org_album_admin_notes });
  }
  if (item.albumMetadata) {
    item.albumMetadata.album_orgTags = album_allTags;
  }

  axios
    .post(`${orgID}/uploads/session/uploadAlbum`, { 'albumdata': item.albumMetadata , 'sessionId': sessionId })
    .then((res) => {
      dispatch({
        type: actionTypes.ALBUM_OK,
        payload: item.albumMetadata
      });
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err.response?.data || { e: (err.message ? err.message : "Error in creating or updating. please retry") },
      });
      if (done) done(null, err.message || "error");
    });
}

export const albumEdit = (action, message, item, done = null) => (dispatch) => {

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  if (item) {
    let errors = validateCreateAlbum(item.albumMetadata);
    if (errors) {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: errors
      });
      return;
    }
  }

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  //Album Setting
  var album_allTags = []
  if (item.albumMetadata && item.albumMetadata.org_album_adminTags) {
    item.albumMetadata.org_album_adminTags.forEach((tag) => {
      album_allTags.push({ 'Type': 'BBC_ADMIN_TAG', 'Value': tag });
    })
  }

  if (item.albumMetadata && item.albumMetadata.bbc_album_id) {
    album_allTags.push({ 'Type': 'BBC_ALBUM_ID', 'Value': item.albumMetadata.bbc_album_id });
  }

  if (item.albumMetadata && item.albumMetadata.org_album_admin_notes) {
    album_allTags.push({ 'Type': 'BBC_ADMIN_NOTES', 'Value': item.albumMetadata.org_album_admin_notes });
  }
  if (item.albumMetadata) {
    item.albumMetadata.album_orgTags = album_allTags;
  }


  axios
    .post(`${orgID}/uploads/session/editAlbum`, { 'albumdata': item.albumMetadata , 'dHAlbum': item.dHAlbum},
      {
        headers: { orgid: orgID },
      }
    )
    .then((res) => {
      dispatch({
        type: actionTypes.ALBUM_OK,
        payload: item.albumMetadata
      });
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err.response?.data || { e: (err.message ? err.message : "Error in creating or updating. please retry") },
      });
      if (done) done(null, err.message || "error");
    });
}

export const saveItem = (items, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  axios
    .post(`${orgID}/uploads/session/save`)
    .then((res) => {
      // dispatch({
      // type: actionTypes.LOAD_EDITABLE_PL,
      // payload: res?.data?.results ? res.data.results : []
      // });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });

};

export const checkAndCreateAlbum = (item, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  axios
    .post(`${orgID}/uploads/session/checkAndCreateAlbum`, item, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      done(res.data, null);
    })
    .catch((err) => {
      done(null, err.message || "error");
    });
}

export const trackEditItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.TRACK_EDIT_ITEM,
    payload: item,
  });
};


export const getSessionById = (id, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  axios
    .post(`${orgID}/uploads/session/getSessionById`, id, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      console.log(err.message)

      if (done) done(null, err.message || "error");
    });
}

export const addSessionItems = (action, message, items, sessionId, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();


  dispatch({
    type: actionTypes.LOAD,
    payload: items,
    sessionId: sessionId
  });
};

export const updateUploadTrack = (message, item, done = null) => (dispatch) => {


  alert('REMOVE')
  
  const { user } = store.getState();

  //Track Setting
  var allTags = []

  if (item.trackMetadata && item.trackMetadata.org_adminTags) {
    item.trackMetadata.org_adminTags.forEach((tag) => {
      allTags.push({ 'Type': 'BBC_ADMIN_TAG', 'Value': tag });
    })
  }

  if (item.trackMetadata && item.trackMetadata.org_userTags) {
    item.trackMetadata.org_userTags.forEach((tag) => {
      allTags.push({ 'Type': `BBC_USER_TAG_${user.id}`, 'Value': tag });
    })
  }

  if (item.trackMetadata && item.trackMetadata.org_admin_notes) {
    allTags.push({ 'Type': 'BBC_ADMIN_NOTES', 'Value': item.trackMetadata.org_admin_notes });
  }

  if (item.trackMetadata) {
    item.trackMetadata.orgTags = allTags;

  }


  //Album Setting
  var album_allTags = []
  if (item.albumMetadata && item.albumMetadata.org_album_adminTags) {
    item.albumMetadata.org_album_adminTags.forEach((tag) => {
      album_allTags.push({ 'Type': 'BBC_ADMIN_TAG', 'Value': tag });
    })
  }

  if (item.albumMetadata && item.albumMetadata.org_album_userTags) {
    item.albumMetadata.org_album_userTags.forEach((tag) => {
      album_allTags.push({ 'Type': `BBC_USER_TAG_${user.id}`, 'Value': tag });
    })
  }

  if (item.albumMetadata && item.albumMetadata.org_album_admin_notes) {
    album_allTags.push({ 'Type': 'BBC_ADMIN_NOTES', 'Value': item.albumMetadata.org_album_admin_notes });
  }
  if (item.albumMetadata) {
    item.albumMetadata.album_orgTags = album_allTags;
  }




  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();
  dispatch({
    type: actionTypes.UPDATING,
    payload: message,
  });

  axios
    .post(
      `${orgID}/contentadmin/UpdateTrack`, item,
      {
        headers: { tid: tId, orgid: orgID },
      }
    )
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_OK,
        payload: res.data,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });

}

export const updateTrack = (message, item, albumItem, isAlbumAlbum, done = null) => (dispatch) => {
  const tId = uuidv4();
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  dispatch({
    type: actionTypes.UPDATING,
    payload: message,
  });

  axios
    .post(`${orgID}/uploads/tracks/trackUpdate`, { 'trackData': item, 'albumdata': albumItem, 'isAlbumEdit': isAlbumAlbum }, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_OK,
        payload: item
      });
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      if (done) done(null, err.message || "error");
    });
}

export const emptyItem = () => (dispatch) => {
  dispatch({
    type: actionTypes.EMPTY_ITEM,
  });
};

export const GetUpdateTrack = (message, item, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.GETTING_UPDATE_DATA,
    payload: message,

  });

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  item.orgId = orgID;
  const tId = uuidv4();

  axios
    .post(
      `${orgID}/contentadmin/GetTrackForEdit`, item,
      {
        headers: { tid: tId, orgid: orgID },
      }
    )
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_DATA_OK,
        payload: res.data,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};


export const GetUpdateAlbum = (message, item, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.GETTING_ALBUM_UPDATE_DATA,
    payload: message,

  });

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  item.orgId = orgID;
  const tId = uuidv4();

  axios
    .post(
      `${orgID}/contentadmin/GetAlbumForEdit`, item,
      {
        headers: { tid: tId, orgid: orgID },
      }
    )
    .then((res) => {
      dispatch({
        type: actionTypes.ALBUM_DATA_OK,
        payload: res.data,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};

export const RemoveTrack = (message, item, done = null) => (dispatch) => {

  dispatch({
    type: actionTypes.GETTING_UPDATE_DATA,
    payload: message,

  });

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  item.orgId = orgID;
  const tId = uuidv4();

  axios
    .post(
      `${orgID}/uploads/tracks/removeTrack`, item,
      {
        headers: { tid: tId, orgid: orgID },
      }
    )
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_DATA_OK,
        payload: res.data,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};


export const RemoveAlbum = (message, item, done = null) => (dispatch) => {

  dispatch({
    type: actionTypes.GETTING_UPDATE_DATA,
    payload: message,
  });

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  item.orgId = orgID;
  const tId = uuidv4();

  axios
    .post(
      `${orgID}/uploads/tracks/removeAlbum`, item,
      {
        headers: { tid: tId, orgid: orgID },
      }
    )
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_DATA_OK,
        payload: res.data,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};

export const setQuery = (query, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });

  if (done) done();
};


export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};


export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};

export const searchAlbums = (query, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  query.orderBy = 'id';


  dispatch({
    type: actionTypes.LOADING,
  });
  axios
    .post(`${orgID}/uploads/albums/search`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.LOAD,
        payload: res.data.results,
        total: res.data.total,
        totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
      });
      if (done) done(res.data.results, null);

    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      if (done) done(null, err);
    });
};

export const showAutoMessage = (message) => (dispatch) => {
  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });
  setTimeout(() => {
    dispatch({
      type: actionTypes.POST_OK,
    });
  }, 3000);
}

export const search = (query, sessionId, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  query.orderBy = 'id';
  if (sessionId) {
    query.filters = [];
    query.filters = [...query.filters, { 'field': 'session_id', 'operator': 'equals', 'value': [sessionId] }]
  }

  const tId = uuidv4();
  dispatch({
    type: actionTypes.LOADING,
  });
  axios
    .post(`${orgID}/uploads/tracks/search`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        // res.data.results.forEach((data) => {
        //   //data.status = "Created"
        // });
        dispatch({
          type: actionTypes.LOAD,
          payload: res.data.results,
          total: res.data.total,
          totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
        });
        if (done) done(res.data.results, null);
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      if (done) done(null, err);
    });
};

export const getAlbumById = (albumId, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const tId = uuidv4();

  axios
    .post(`${orgID}/uploads/session/getUploadAlbumById/${albumId}`, albumId, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.SET_ALBUM_NAME,
          payload: res.data.results,
        });
        if (done) done(res.data.results, null);
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      if (done) done(null, err);
    });
};

export const searchAlbumTracks = (query, sessionId, done = null) => (dispatch) => {

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  query.orderBy = 'id';
  if (sessionId) {
    query.filters = [...query.filters, { 'field': 'session_id', 'operator': 'equals', 'value': [sessionId] }]
  }

  const tId = uuidv4();
  dispatch({
    type: actionTypes.LOADING,
  });
  axios
    .post(`${orgID}/uploads/session/getUploadAlbumTracks`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.LOAD,
          payload: res.data.results,
          total: res.data.total,
          totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
        });
        if (done) done(res.data.results, null);
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      if (done) done(null, err);
    });
};



