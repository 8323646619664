import axios from "axios";
import store from "redux/Store";
import { pagingTotalRelation } from "redux/Types";
import { v4 as uuidv4 } from "uuid";
module = "/mllibrary";
export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  REFRESH_ITEMS: "REFRESH_ITEMS" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,
  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,
  RESYNC: "RESYNC" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,

};

var tId = null;
const ML_ADMIN_API = "https://localhost:44335/api/";

export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items
  });
};

export const editItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.EDIT_ITEM,
    payload: item
  });
};

export const setResync = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.RESYNC,
    payload: item
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};

export const refreshItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.REFRESH_ITEMS,
    payload: items,
  });
};

export const setQuery = (query, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });

  if (done) done();
};

export const postItem = (action, message, items, id, type, done = null) => (dispatch) => {

  if (id != null) {
    items = [];
    items.push(id);
  }

  let payload = {};
  payload.type = type;
  payload.ids = items;

  const {
    user: {
      credentials: { orgID },
    },
    mllibraryadmin: { query },
  } = store.getState();

  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/contentadmin/Workspace/${action}`, payload, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
        payload: items
      });

      search(query)(dispatch);

      if (done) done(res.data, null);

    })
    .catch((err) => {

      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err
      });

      if (done) done(null, err);
    });
};


export const updateMusicOrigin = (action, message, items, id, type, origin, done = null) => (dispatch) => {

  if (id != null) {
    items = [];
    items.push(id);
  }

  let payload = {};
  payload.type = type;
  payload.ids = items;
  payload.music_origin = origin

  const {
    user: {
      credentials: { orgID },
    },
    mllibraryadmin: { query },
  } = store.getState();

  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/contentadmin/Workspace/${action}`, payload, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
        payload: items
      });

      search(query)(dispatch);

      if (done) done(res.data, null);

    })
    .catch((err) => {

      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err
      });

      if (done) done(null, err);
    });
};


export const updateStatus = (val) => (dispatch) => {
  const param = { dh_status: val };

  const {
    mllibraryadmin: { selectedItems, query },
  } = store.getState();

  axios
    .post(
      `${ML_ADMIN_API}WorkspaceAPI/UpdateBulkStatus?id_list=${selectedItems}`,
      param
    )
    .then((res) => {
      search(query)(dispatch);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });
};

export const GetElasticTrackCount = (query, done = null) => (dispatch) => {
  tId = uuidv4();
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  query.orgId = orgID;

  axios
    .post(`${orgID}/contentadmin/GetTrackCounts`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        done(res.data.counts, null)
      }
    })
    .catch((err) => {
      done(0, null)
    });
};

export const search = (query) => (dispatch) => {

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  tId = uuidv4();
  if (!query.orderBy) {
    query.orderBy = 'date_last_edited';
  }

  if (query.filters && query.filters.length > 0) {
    query.filters = [...query.filters, { 'field': 'org_id', 'operator': 'equals', 'value': [orgID] }];

  }
  else {
    query.filters = [{ 'field': 'org_id', 'operator': 'equals', 'value': [orgID] }];
  }

  var _apiEndpoint = `${orgID}/contentadmin/ContentWorkspaceSearch`;
  if (query.type == 'lib') {
    _apiEndpoint = `${orgID}/contentadmin/ContentLibrarySearch`;
    if (query.orderBy == 'library_count') {
      query.orderBy = 'date_last_edited';
    }
  }
  const options = {
    headers: {
      tid: tId,
    },
  };
  dispatch({
    type: actionTypes.LOADING,
  });




  axios
    .post(_apiEndpoint, query, options)
    .then((res) => {
      dispatch({
        type: actionTypes.LOAD,
        payload: res.data.results.results,
        total: res.data.results.total,
        totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });



  // const {
  //   user: {
  //     credentials: { orgID },
  //   },
  // } = store.getState();

  // const tId = uuidv4();
  // dispatch({
  //   type: query.page === 0 ? actionTypes.LOADING : actionTypes.LOADING_MORE,
  // });

  // axios
  //   .post(`${ML_ADMIN_API}WorkspaceAPI/MLAdminSearch`, query, {
  //     headers: { tid: tId, orgid: orgID },
  //   })
  //   .then((res) => {
  //     dispatch({
  //       type:
  //         query.page === 0
  //           ? actionTypes.LOAD
  //           : res.data.data.length < query.size
  //           ? actionTypes.LOAD_NOMORE
  //           : actionTypes.LOAD_MORE,
  //       payload: res.data.data,
  //     });
  //   })
  //   .catch((err) => {
  //     dispatch({
  //       type: actionTypes.ERROR,
  //       payload: { e: err },
  //     });
  //   });
};
