import { actionTypes } from "../actions/UserActions";

const initialState = {
  loading: false,
  exporting:false, 
  exportingMessage:'', 
  resending: false,
  getCode: false,
  errors: {},
  twofa: {},
  authenticated: false,
  token: "",
  navExapnded: false,
  previewVisible: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_NAV:
      return {
        ...state,
        navExapnded: !action.payload,
      };
    case actionTypes.TOGGLE_PV:
      return {
        ...state,
        previewVisible: !action.payload,
      };
    case actionTypes.LOADING:
      return {
        ...state,
        errors : {},
        loading: true,
      };
    case actionTypes.EXPORTING:
      return {
        ...state,
        exporting: true,
        exportingMessage:action.payload
      };
    case actionTypes.EXPORTED:
      return {
        ...state,
        exporting: true,
        exportingMessage:action.payload
      };
    case actionTypes.EXPORTED_OK:
      return {
        ...state,
        exporting: false,
        exportingMessage:""
      };
    case actionTypes.RESENDING:
      return {
        ...state,
        resending: true,
        errors: {},
      };
    case actionTypes.GETCODE:
      return {
        ...state,
        getCode: true,
      };
    case actionTypes.LOAD:
      return {
        ...state,
        loading: false,
        errors: {},
      };

    case actionTypes.ERROR:
      return {
        ...state,
        loading: false,
        resending: false,
        getCode: false,
        errors: action.payload,
      };

    case actionTypes.SET_UNAUTHENTICATED:
      return initialState;
    case actionTypes.SET_USER_PERMISSION:
      return {
        ...state,
        credentials: action.payload,
      };
    case actionTypes.SET_2FA:
      return {
        ...initialState,
        twofa: action.payload,
      };
    case actionTypes.SET_USER:
      return {
        ...state,
        authenticated: true,
        loading: false,
        errors: {},
        twofa: {},
        ...action.payload,
      };
    case actionTypes.SET_USER_IMAGE:
      return {
        ...state, 
        credentials:{
          ...state.credentials, 
          orgs:[
            {
              ...state.credentials.orgs[0], 
              imageUrl:action.payload
            }
          ]
        }
      }
    case actionTypes.REMOVE_USER_IMAGE:
      return {
        ...state, 
        credentials:{
          ...state.credentials, 
          orgs:[
            {
              ...state.credentials.orgs[0], 
              imageUrl:null
            }
          ]
        }
      }
    case actionTypes.CLEAR_ERRORS:
        return {
          ...state,
          errors: action.payload,
        }; 
    default:
      return state;
  }
}
