import { __trackStatus } from "../../_clearance/definition/status";
import { PT_Org } from "./ModulePermitTypes";
import { T_Major, T_LeafBool, T_LeafOptionsSingle } from "./ModulePropTypes";

export const _mlLicensingCanCreate = {
  id: "mlL1",
  type: T_LeafBool,
  default: true,
};

export const _mlLicensingCanRunWizard = {
  id: "mlL2",
  type: T_LeafBool,
  default: true,
};

export const _mlLicensingFormAll = {
  id: "mlL3",
  type: T_LeafBool,
  default: true,
};

export const _mlLicensingTrackAll = {
  id: "mlL5",
  type: T_LeafBool,
  default: true,
};

export const _mlLicensingOverideRestrictions = {
  id: "mlL4",
  type: T_LeafBool,
  default: true,
};

export const _mlLicensingWorkflowAdmin = {
  id: "mlL6",
  type: T_LeafBool,
  default: false,
};

export const _mlLicensingWorkflowPermissionOptions = {
  default: { id: "default", name: "Default" },
  allAdmin: { id: "allAdmin", name: "All Admin" },
  musicRightsAdmin: { id: "musicRightsAdmin", name: "Music Rights Admin" },
};

export const _mlCfSharePermissionOptionsID = {
  canDownloadNoLimit: "canDownloadNoLimit",
  noAdditionalPermission: "noAdditionalPermission",
};

export const _mlCfSharePermissionOptions = [
  { id: _mlCfSharePermissionOptionsID.canDownloadNoLimit, name: "Can Download" },
  { id: _mlCfSharePermissionOptionsID.noAdditionalPermission , name: "No Additional Permission" },
]


export const _mlLicensingWorkflowPermission = {
  id: "mlL7",
  type: T_LeafOptionsSingle,
  options: _mlLicensingWorkflowPermissionOptions,
  default: _mlLicensingWorkflowPermissionOptions.default.id,
};

export const _mlLicensingCanAddToPPL = {
  id: "mlL8",
  type: T_LeafBool,
  default: false,
};

export const _mlLicensingCanShareCFOptions = {
  all: {id:"all", name:"All"},
  own: {id:"own", name:"Own"},
  none: {id:"none", name:"None"},
}

export const _mlLicensingCanShareCF = {
  id: "mlL9",
  type: T_LeafOptionsSingle,
  options: _mlLicensingCanShareCFOptions,
  default: _mlLicensingCanShareCFOptions.own.id,
};

export const _mlLicensing = {
  id: "mlLicensing",
  permitType: PT_Org,
  type: T_Major,
  modules: [
    _mlLicensingCanCreate,
    _mlLicensingCanRunWizard,
    _mlLicensingFormAll,
    _mlLicensingTrackAll,
    _mlLicensingOverideRestrictions,
    // _mlLicensingWorkflowAdmin,
    _mlLicensingCanAddToPPL,
    _mlLicensingWorkflowPermission,
    _mlLicensingCanShareCF,
  ],
};

