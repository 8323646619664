import { actionTypes } from "../actions/SmUsageReportsActions";
import { SortTypes, FilterOperatorTypes } from "../../util/table-view/TableTypes";
import { defaultResultsPerPage, pagingTotalRelation,  } from "../Types";

const initialState = {
  items: [],
  totalItems: 0,
  totalRelation: pagingTotalRelation.EQUALS,
  selectedItem: {},
  selectedItems: [],
  loading: false,
  loadingMore: false,
  posting: { busy: false, message: "", errors: null },
  editing: null,
  noMore: true,
  query: {
    order: SortTypes.desc,
    orderBy: "updatedAt",
    page: 0,
    size: defaultResultsPerPage,
    q: "",
    filters: [{ operator: FilterOperatorTypes.equals, value: [1], field: "status" }],
  },
  errors: null,
  errorsUsers: null,
  users: [],
  activities: [],
  workspaces: [],
};

export default function (state = initialState, action) {

  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        loading: true,
        loadingMore: false,
        errors: null,
        selectedItems: [],
        items: [],
      };

    case actionTypes.LOAD:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        errors: null,
        items: action.payload,
        totalItems: action.total,
        totalRelation: action.totalRelation,
        selectedItem: action.payload[0] || {},
        errors: null,
        selectedItems: [],
        noMore: false,
      };

    case actionTypes.ERROR:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        errors: action.payload,
        items: [],
        totalItems: 0,
        totalRelation: pagingTotalRelation.EQUALS,
        noMore: true,
      };

    case actionTypes.SELECT_ITEMS:
      return {
        ...state,
        selectedItems: action.payload,
      };

    case actionTypes.QUERY:
      return {
        ...state,
        query: action.payload,
      };

    case actionTypes.FOCUS_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
      };

    case actionTypes.EDIT_ITEM:
      // Check if we need to clear errors
      // We need to when the action.payload = null (user has cancelled operation)
      let posting = null;
      if (!action.payload)
        posting = {
          busy: false,
          message: "",
          errors: null,
        }

      return {
        ...state,
        editing: action.payload,
        // selectedItem: null,
        ...(posting && { posting: posting })
      };

    case actionTypes.POSTING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.SAVE_OK:
      var items = [action.payload, ...state.items];
      return {
        ...state,
        items,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
      };

    case actionTypes.POST_OK:
      if(action.payload){
        var items = state.items;
        let index = items.findIndex(v => v.id === action.payload.id)
        if (index > -1) {
          items[index] = action.payload;
        }
      }      
      return {
        ...state,
        items,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
      };

    case actionTypes.POST_ERROR:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: action.payload,
        },
      };

    case actionTypes.SET_USERS:
      return {
        ...state,
        users: action.users,
      };

    case actionTypes.SET_ERROR_LOADING_USERS:
      return {
        ...state,
        loadingUsers: false,
        errorsUsers: action.errors,
      };

    case actionTypes.SET_LOG_ACTIVITIES:
      return {
        ...state,
        activities: action.activities,
      };

    case actionTypes.SET_ERROR_LOADING_ACTIVITIES:
      return {
        ...state,
        loadingActivities: false,
        errorsActivities: action.errors,
      };

    case actionTypes.SET_WORKSPACES:
      return {
        ...state,
        workspaces: action.workspaces,
      };

    case actionTypes.SET_ERROR_LOADING_WORKSPACES:
      return {
        ...state,
        loadingWorkspaces: false,
        errorsWorkspaces: action.errors,
      };

    default:
      return state;
  }
}
