import { actionTypes } from "../actions/MlTrackLibraryActions";
import { SortTypes } from "../../util/table-view/TableTypes";
import { resultViews } from "../../_module/definition/MlLibraries";
import {
  _searchMain,
  _searchTag,
  _searchPersonal,
} from "../../_module/definition/Settings";
import trackOrigins from "../../_module/definition/trackOrigins";
import trackAllFileds from "../../_module/definition/trackAllFileds";
import { defaultContentResultsPerPage, defaultResultsPerPage, pagingTotalRelation } from "../Types";


export const initialState = {
  tagCloud: {
    open: false,
    items: [],
    loading: false,

    query: {
      suffle: false,
      tempFilter: null,
      module: "trackAll",
      size: 50,
      order: SortTypes.desc,
      orderBy: "count", // count, term
      tagType: "all",
      filters: [],
    },
    errors: null,
  },
  trackInputData:{
    items: [],
    totalItems: 0,
    totalRelation: pagingTotalRelation.EQUALS,
    originalItems: [],
    selectedItem: {},
    selectedItems: [],
    postUpdating: false,
    loading: false,
    prsLoading: false,
    errors: null,

    query: {
      advSearch: _searchMain.id,
      order: SortTypes.asc,
      orderBy: null,
      page: 0,
      size: defaultContentResultsPerPage,
      origin: trackOrigins.originall.val,
      q: "",
      qfs: trackAllFileds,
      filters: [],
      groupResults: false,
      discreteFlags: [],
      all: { q: "", f: "all" },
      tagAll: { q: "", f: "all" },
      cloudAll: [],
      cloudQuery: {},
    },
  },
  items: [],
  totalItems: 0,
  totalRelation: pagingTotalRelation.EQUALS,
  originalItems: [],
  selectedItem: {},
  selectedItems: [],
  loading: false,
  posting: { busy: false, message: "", errors: null },
  editing: null,
  trackEditing: null,
  ctagRule: null,
  resultView: resultViews.single.id,
  postUpdating: false,
  discreteEnabled: false,
  suggOptionList: [],
  query: {
    advSearch: _searchMain.id,
    order: SortTypes.asc,
    orderBy: null,
    page: 0,
    size: defaultContentResultsPerPage,
    origin: trackOrigins.originall.val,
    q: "",
    qfs: trackAllFileds,
    filters: [],
    groupResults: false,
    discreteFlags: [],
    all: { q: "", f: "all" },
    tagAll: { q: "", f: "all" },
    cloudAll: [],
    cloudQuery: {},
    searchId: null,
  },
  errors: null,
  showCtagRule: false,
  ctagRuleList: [],
  ctagRuleBusy: false,
  cTagType: null, 
  history:{}, 
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CLOUD_OPEN:
      return {
        ...state,
        tagCloud: { ...state.tagCloud, open: true },
      };

    case actionTypes.CLOUD_SET_PERS_FIELD:
      return {
        ...state,
        tagCloud: {
          ...state.tagCloud,
          query: { ...state.tagCloud.query, tagType: action.payload },
        },
      };

    case actionTypes.SET_SUGGESTIONS_LIST:
      return {
        ...state,
        suggOptionList: action.payload,
      };

    case actionTypes.UPDATE_DATA_OK:
      return {
        ...state,
        editing: action.payload,
        trackEditing: action.payload,
        posting: {
          busy: false,
          message: null,
          errors: null,
        },
      };

    case actionTypes.CLOUD_CLOSE:
      return {
        ...state,
        tagCloud: { ...state.tagCloud, open: false },
      };
    case actionTypes.CLOUD_LOADING:
      return {
        ...state,
        tagCloud: { ...state.tagCloud, loading: true, errors: null, items: [] },
      };
    case actionTypes.CLOUD_LOAD:
      return {
        ...state,
        tagCloud: {
          ...state.tagCloud,
          loading: false,
          errors: null,
          items: action.payload,
        },
      };

    case actionTypes.CLOUD_ERROR:
      return {
        ...state,
        tagCloud: {
          ...state.tagCloud,
          loading: false,
          errors: action.payload,
          items: [],
        },
      };

    case actionTypes.CLOUD_QUERY:
      return {
        ...state,
        tagCloud: { ...state.tagCloud, query: action.payload },
      };

    case actionTypes.LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
        selectedItems: [],
        items: [],
        editing: {},
      };
    case actionTypes.LOADING_TRACKINPUT:
      return {
        ...state,
        trackInputData: { 
          ...state.trackInputData, 
          loading: true,
          errors: null,
          selectedItems: [],
          items: [],
        },
       
      };
    case actionTypes.LOADING_PRS_SEARCH_TRACKINPUT:
        return {
          ...state,
          trackInputData: { 
            ...state.trackInputData, 
            prsLoading: true,
            errors: null,
            selectedItems: [],
            items: [],
          },
         
        };  

    case actionTypes.EDIT_ITEM:
      return {
        ...state,
        editing: action.payload,
      };

    case actionTypes.EMPTY_ITEM:
      return {
        ...state,
        editing: null,
        trackEditing: null
      };

    case actionTypes.UPDATING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.LOAD:
      return {
        ...state,
        loading: false,
        errors: null,
        items: action.payload.items,
        originalItems: action.payload.originalItems,
        totalItems: action.payload.total,
        totalRelation: action.payload.totalRelation,
        selectedItem: (action.payload.items && action.payload.items.length > 0) ? action.payload.items[0] : {},
        selectedItems: [],
        postUpdating: action.payload.postUpdating ? true : false,
      };
    case actionTypes.LOAD_TRACKINPUT:
      return {
        ...state,
        trackInputData: { 
          ...state.trackInputData, 
          loading: false,
          prsLoading: false,
          errors: null,
          items: action.payload.items,
          originalItems: action.payload.originalItems,
          totalItems: action.payload.total,
          totalRelation: action.payload.totalRelation,
          selectedItem: (action.payload.items && action.payload.items.length > 0) ? action.payload.items[0] : {},
          selectedItems: [],
          postUpdating: action.payload.postUpdating ? true : false,
        },
      };

    case actionTypes.POST_UPDATE:
      return {
        ...state,
        items: action.payload.items,
        originalItems: action.payload.originalItems,
        postUpdating: false,
      };
    case actionTypes.POST_UPDATE_TRACKINPUT:
      return {
        ...state,
        trackInputData: { 
          ...state.trackInputData, 
          items: action.payload.items,
          originalItems: action.payload.originalItems,
          postUpdating: false,
        },
      };

   


    case actionTypes.ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        items: [],
        totalItems: 0,
        totalRelation: pagingTotalRelation.EQUALS,
      };
    case actionTypes.ERROR_TRACKINPUT:
      return {
        ...state,
        trackInputData: { 
          ...state.trackInputData, 
          loading: false,
          prsLoading: false,
          errors: action.payload,
          items: [],
          totalItems: 0,
          totalRelation: pagingTotalRelation.EQUALS,
        },
      };

    case actionTypes.CLEAR:
      return {
        ...state,
        loading: false,
        errors: null,
        selectedItem: {},
        items: [],
        totalItems: 0,
        totalRelation: pagingTotalRelation.EQUALS,
        query: initialState.query,
      };
    case actionTypes.CLEAR_TRACKINPUT:
      return {
        ...state,
        

        trackInputData: { 
          ...state.trackInputData, 
          loading: false,
          prsLoading: false,
        errors: null,
        selectedItem: {},
        items: [],
        totalItems: 0,
        totalRelation: pagingTotalRelation.EQUALS,
        query: initialState.trackInputData.query,
        },

      };

    case actionTypes.SELECT_ITEMS:
      return {
        ...state,
        selectedItems: action.payload,
      };
    case actionTypes.QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case actionTypes.TRACK_INPUT_QUERY:
      return {
        ...state,
        trackInputData:{
          ...state.trackInputData, 
          query: action.payload,
        }
      };
    case actionTypes.FOCUS_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
        ctagRuleList: [],
        cTagType: null
      };
    case actionTypes.RESULT_VIEW:
      return {
        ...state,
        resultView: action.payload,
      };
    case actionTypes.ADV_SEARCH:
      return {
        ...initialState,
        query: {
          ...initialState.query,
          cloudAll: [],
          filters: [],
          ...action.payload,
        },
      };

    case actionTypes.POSTING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.POST_OK:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
      };

    case actionTypes.POST_ERROR:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: action.payload,
        },
      };

    case actionTypes.SET_MESSAGE:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.SWITCH_DISCRETE:
      return {
        ...state,
        discreteEnabled: action.payload
      };


    case actionTypes.CLEAR_MESSAGE:
      return {
        ...state,
        posting: {
          busy: false,
          message: null,
          errors: null,
        },
      };

    case actionTypes.SHOW_CTAG_RULE:
      return {
        ...state,
        showCtagRule: action.payload,
      };


    case actionTypes.GET_CTAG_RULE:
      return {
        ...state,
        ctagRuleList: action.payload,
        ctagRuleBusy: false,
        cTagType: action.tagtype

      };

      case actionTypes.RETRIEVING_CTAG_RULE:
      return {
        ...state,
        ctagRuleBusy: true,
        ctagRuleList: [],
        cTagType: null
      };

      case actionTypes.EMPTY_CTAG_RULE:
        return {
          ...state,
          ctagRuleList: [],
          ctagRuleBusy: false,
          cTagType: null
  
        };
    case actionTypes.UPDATE_OK:
      return {
        ...state,
        trackEditing: null,
        editing: null,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
      };

    case actionTypes.LOAD_QUERY:
      if (!action.payload.advSearch) {
        action.payload.advSearch = _searchMain.id;
        if (action.payload?.all?.q || action.payload?.filters?.length > 0) {
          action.payload.advSearch = _searchPersonal.id;
        } else if (action.payload?.tagAll?.q) {
          action.payload.advSearch = _searchTag.id;
        }
      }

      return {
        ...initialState,
        query: action.payload,
      };
    case actionTypes.LOAD_HISTORY:
      state.history[action.payload.trackId] = action.payload.history
      return {
        ...state
      };
    case actionTypes.REMOVE_HISTORY:
      delete state.history[action.payload.trackId];
      return {
        ...state
      };

    case actionTypes.REMOVE_HISTORY_ALL:
      return {
        ...state, 
        history: {}
      };

    default:
      return state;
  }
}
