import axios from "axios";
import store from "redux/Store";
import { pagingTotalRelation } from "redux/Types";
import { v4 as uuidv4 } from "uuid";
module = "/applogs/";
export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,
};

var tId = null;


export const setQuery = (query, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });

  if (done) done();
};

export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};

export const search = (query) => (dispatch) => {
  var url = ""
  if (query.filterType == 'content_api') {
    url = "contentApplogsearch"
    query['searchType'] = 'content_api'
    if (query.filters.length > 0) {
      var tempFilter = [];
      query.filters.forEach((item) => {
        if (item.field == "severity") {
          item.field = 'level'
          item.value[0] = item.value[0].charAt(0).toUpperCase() + item.value[0].slice(1);
        }
        if (item.field == "func") {
          item.field = 'message'
        }
        tempFilter.push(item)
      });
      query.filters = tempFilter
    }
    if (query.orderBy) {
      if (query.orderBy == "severity") {
        query.orderBy = "level"
      }
      if (query.orderBy == "func") {
        query.orderBy = 'message'
      }
    }
  }
  else if (query.filterType == 'central_api') {
    url = "applogsearch";
  }
  else {
    url = "contentApplogsearch"
    query['searchType'] = 'service'
    if (query.filters.length > 0) {
      var tempFilter = [];
      query.filters.forEach((item) => {
        if (item.field == "severity") {
          item.field = 'level'
          item.value[0] = item.value[0].charAt(0).toUpperCase() + item.value[0].slice(1);
        }
        if (item.field == "func") {
          item.field = 'message'
        }
        tempFilter.push(item)
      });
      query.filters = tempFilter
    }
    if (query.orderBy) {
      if (query.orderBy == "severity") {
        query.orderBy = "level"
      }
      if (query.orderBy == "func") {
        query.orderBy = 'message'
      }
    }
  }

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  dispatch({
    type: actionTypes.LOADING,
  });

  tId = uuidv4();

  axios
    .post(`${orgID}/${url}`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        //mapping data
        if (res.data.results) {
         
          res.data.results.forEach(item => {
      
            // obj["@timestamp"] = item["@timestamp"];
            // obj["severity"] = item.severity ? item.severity : item.level ? item.level : "";
            // obj["module"] = item.module ? item.module : "Activity Log";
            // obj["func"] = item.func ? item.func : item.message.split('>')[0];
            // obj["message"] = item.message;
            item.fields = item.module ? item.fields : JSON.stringify(item);
            // obj["highlights"] = item.highlights;
            // obj["id"] = item.id;
           
          });


          dispatch({
            type: actionTypes.LOAD,
            payload: res.data.results,
            total: res.data.total,
            totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
          });

          // query.filters = []
        }
        else {
          dispatch({
            type: actionTypes.LOAD,
            payload: [],
            total: 0,
            totalRelation: pagingTotalRelation.EQUALS,
          });
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });
};

export const checkServices = (done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const tId = uuidv4();

  axios
    .post(`${orgID}/contentadmin/checkServices/`, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: err
      });
      if (done) done(null, err);
    });
};

export const restartServices = (done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const tId = uuidv4();

  axios
    .post(`${orgID}/contentadmin/restartServices/`, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (done) done(res.data, null);
    })
    .catch((err) => {

      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err
      });

      if (done) done(null, err);
    });
};
