import { PT_Org } from "./ModulePermitTypes";
import { T_Major, T_LeafBool, T_Branch } from "./ModulePropTypes";

export const _canAddContent = {
  id: "msg1",
  type: T_LeafBool,
  default: true,
};

export const _canCreateContentRequest = {
  id: "msg2",
  type: T_LeafBool,
  default: true,
};

export const _canRequestResponse = {
  id: "msg3",
  type: T_LeafBool,
  default: true,
};

export const _Messages = {
  id: "Messages",
  permitType: PT_Org,
  type: T_Major,
  modules: [_canAddContent, _canCreateContentRequest, 
    // _canRequestResponse
  ],
};
