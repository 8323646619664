import store from "redux/Store";
import { _expectPermission } from "./ModuleUtils";

export const _PermittedContent = (modulePath,
    expects) => {
    const {
          user: {
            credentials: { sysPermit, orgPermit, orgID },
          },
        } = store.getState();

        return  _expectPermission(sysPermit, orgPermit, orgID, modulePath, expects);
      }

