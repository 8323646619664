import axios from "axios";
import store from "redux/Store";
import { pagingTotalRelation } from "redux/Types";
import { v4 as uuidv4 } from "uuid";
import { SortTypes } from "../../util/table-view/TableTypes";
import { validateEditTrack } from "../../util/validations/content-validations/UploadValidation";

module = "/updateTrackContent/";
export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,
  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,
  SET_PLAYLIST: "SET_PLAYLIST" + module,
  ADD_SESSION_TRACKS: "ADD_SESSION_TRACKS" + module,
  UPDATE_OK: "UPDATE_OK" + module,
  UPDATING: "UPDATING" + module,
  UPLOADING: "UPLOADING" + module,
  SET_ALBUM_NAME: "SET_ALBUM_NAME" + module,
  REFRESH: "REFRESH" + module,
  GETTING_UPDATE_DATA: "GETTING_UPDATE_DATA" + module,
  UPDATE_DATA_OK: "UPDATE_DATA_OK" + module,
  EMPTY_ITEM: "EMPTY_ITEM" + module,
  EMPTY_SESSION: "EMPTY_SESSION" + module,
  UPLOAD_EDIT_ITEM: "UPLOAD_EDIT_ITEM" + module,
  TRACK_EDIT_ITEM: "TRACK_EDIT_ITEM" + module,
  ALBUM_OK: "ALBUM_OK" + module,
  ALBUM_EDIT_ITEM: "ALBUM_EDIT_ITEM" + module,
  ADD_TRACKS_SHOW: "ADD_TRACKS_SHOW" + module,
  REMOVE_OK: "REMOVE_OK" + module,
  GETTING_ALBUM_UPDATE_DATA: "GETTING_ALBUM_UPDATE_DATA" + module,
  ALBUM_DATA_OK: "ALBUM_DATA_OK" + module,
  EMPTY_ALBUM_TRACK: "EMPTY_ALBUM_TRACK" + module,
  SET_SESSION_ID: "SET_SESSION_ID" + module,
  UPLOAD_COMPLETE: "UPLOAD_COMPLETE" + module,
  UPLOAD_ERROR: "UPLOAD_ERROR" + module,
  SHOW_ALERT_MESSAGE: "SHOW_ALERT_MESSAGE" + module,
  SOURCE_DEST: "SOURCE_DEST" + module,
  LOAD_ITEMS: "LOAD_ITEMS" + module,
  DO_UPDATE: "DO_UPDATE" + module,
  SET_S3_IDS: "SET_S3_IDS" + module,
  SET_SESSION: "SET_SESSION" + module,
  SET_UPLOAD_IDENTIFIER: "SET_UPLOAD_IDENTIFIER" + module,
  SET_UPLOAD_TRACKS: "SET_UPLOAD_TRACKS" + module,
  DO_RELOAD_SESSION: "DO_RELOAD_SESSION" + module,
  SET_SELECTED_ITEM: "SET_SELECTED_ITEM" + module,
  DO_UPDATE_SELECTED: "DO_UPDATE_SELECTED" + module,
  DELETE_DATA_OK: "DELETE_DATA_OK" + module,
  GRID_STATUS_UPDATE: "GRID_STATUS_UPDATE" + module,
  SET_PREVIEW: "SET_PREVIEW" + module,
  DATA_LOADED: "DATA_LOADED" + module,
  SET_CONTRIBUTORS: "SET_CONTRIBUTORS" + module,
  CONTENT_TRACK_EDIT_ITEM: "CONTENT_TRACK_EDIT_ITEM" + module,
  SET_PRS_TO_EDIT_TRACK: "SET_PRS_TO_EDIT_TRACK" + module,
  SET_QUEUE_ITEMS: "SET_QUEUE_ITEMS" + module,
  EMPTY_SELECTED_ITEM: "EMPTY_SELECTED_ITEM" + module,
  SHOW_UPLOADER: "SHOW_UPLOADER" + module,
};

const AWS = require("aws-sdk");

export const doUpdateSelected = (status) => (dispatch) => {
  dispatch({
    type: actionTypes.DO_UPDATE_SELECTED,
    payload: status,
  });
};

export const showUploader = (status) => (dispatch) => {
  dispatch({
    type: actionTypes.SHOW_UPLOADER,
    payload: status,
  });
};

export const setSelectedItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SELECTED_ITEM,
    payload: item,
  });
};

export const editItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.ALBUM_EDIT_ITEM,
    payload: item,
  });
};

export const contentTrackEditItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.CONTENT_TRACK_EDIT_ITEM,
    payload: item,
  });
};

export const setPrsTrackToEditTrack = (item, status, action) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_PRS_TO_EDIT_TRACK,
    payload: item,
    status: status,
    prsAction: action,
  });
};

export const addTracksShow = (status) => (dispatch) => {
  dispatch({
    type: actionTypes.ADD_TRACKS_SHOW,
    status: status,
  });
};

export const emptyAlbumTrackPreview = (status) => (dispatch) => {
  dispatch({
    type: actionTypes.EMPTY_ALBUM_TRACK,
    status: status,
  });
};

export const setRefresh = (status) => (dispatch) => {
  dispatch({
    type: actionTypes.REFRESH,
    status: status,
  });
};

export const uploadEditItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.UPLOAD_EDIT_ITEM,
    payload: item,
  });
};

export const addTracksToAlbum = (action, message, item, done = null) => (
  dispatch
) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/uploads/albums/addTracksToAlbum`, item)
    .then((res) => {
      dispatch({
        type: actionTypes.ALBUM_OK,
        payload: item,
      });
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err,
      });
      if (done) done(null, err.message || "error");
    });
};

export const albumPost = (
  action,
  message,
  item,
  selectedAlbumTracks,
  sessionId,
  done = null
) => (dispatch) => {
  item.selectedTracks = selectedAlbumTracks;
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/uploads/session/uploadAlbum`, {
      albumdata: item,
      sessionId: sessionId,
    })
    .then((res) => {
      dispatch({
        type: actionTypes.ALBUM_OK,
        payload: item,
      });
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err,
      });
      if (done) done(null, err.message || "error");
    });
};

export const albumEdit = (action, message, item, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(
      `${orgID}/uploads/session/editAlbum`,
      { albumdata: item },
      {
        headers: { orgid: orgID },
      }
    )
    .then((res) => {
      dispatch({
        type: actionTypes.ALBUM_OK,
        payload: item,
      });
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err,
      });
      if (done) done(null, err.message || "error");
    });
};

export const saveItem = (items, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  axios
    .post(`${orgID}/uploads/session/save`)
    .then((res) => {
      // dispatch({
      // type: actionTypes.LOAD_EDITABLE_PL,
      // payload: res?.data?.results ? res.data.results : []
      // });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });
};

export const checkAndCreateAlbum = (item, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  axios
    .post(`${orgID}/uploads/session/checkAndCreateAlbum`, item, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      done(res.data, null);
    })
    .catch((err) => {
      done(null, err.message || "error");
    });
};

export const trackEditItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.TRACK_EDIT_ITEM,
    payload: item,
  });
};

export const getSessionById = (id, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  axios
    .post(`${orgID}/uploads/session/getSessionById`, id, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      console.log(err.message);

      if (done) done(null, err.message || "error");
    });
};

export const addSessionItems = (
  action,
  message,
  items,
  sessionId,
  done = null
) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  dispatch({
    type: actionTypes.LOAD,
    payload: items,
    sessionId: sessionId,
  });
};

export const updateGridStatus = (items, sessionId, done = null) => (
  dispatch
) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  dispatch({
    type: actionTypes.GRID_STATUS_UPDATE,
    payload: items,
    sessionId: sessionId,
  });
};

export const showAutoMessage = (message) => (dispatch) => {
  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });
  setTimeout(() => {
    dispatch({
      type: actionTypes.POST_OK,
    });
  }, 3000);
};

export const setError = (errors) => (dispatch) => {
  dispatch({
    type: actionTypes.POST_ERROR,
    payload: errors,
  });
};

export const setContributors = (contributors) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CONTRIBUTORS,
    payload: contributors,
  });
};

export const setErrors = (errors) => (dispatch) => {
  dispatch({
    type: actionTypes.POST_ERROR,
    payload: errors,
  });
};

export const updateUploadTrack = (message, item, done = null) => (dispatch) => {
  const { user } = store.getState();

  if (item) {
    let errors = validateEditTrack(item);
    if (errors) {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: errors,
      });
      return;
    }
  }

  //Track Setting
  var allTags = [];

  if (item.trackMetadata && item.trackMetadata.org_adminTags) {
    item.trackMetadata.org_adminTags.forEach((tag) => {
      allTags.push({ Type: "BBC_ADMIN_TAG", Value: tag });
    });
  }

  // if (item.trackMetadata && item.trackMetadata.org_userTags) {
  //   item.trackMetadata.org_userTags.forEach((tag) => {
  //     allTags.push({ 'Type': `BBC_USER_TAG_${user.id}`, 'Value': tag });
  //   })
  // }

  if (item.trackMetadata && item.trackMetadata.bbc_track_id) {
    allTags.push({
      Type: "BBC_TRACK_ID",
      Value: item.trackMetadata.bbc_track_id,
    });
  }

  if (
    item.trackMetadata &&
    item.trackMetadata.bbc_track_contributor_remix_artist
  ) {
    allTags.push({
      Type: "BBC_TRACK_REMIX_ARTIST",
      Value: item.trackMetadata.bbc_track_contributor_remix_artist,
    });
  }
  if (
    item.trackMetadata &&
    item.trackMetadata.bbc_track_contributor_versus_artist
  ) {
    allTags.push({
      Type: "BBC_TRACK_VERSUS_ARTIST",
      Value: item.trackMetadata.bbc_track_contributor_versus_artist,
    });
  }
  if (
    item.trackMetadata &&
    item.trackMetadata.bbc_track_contributor_orchestra
  ) {
    allTags.push({
      Type: "BBC_TRACK_ORCHESTRA",
      Value: item.trackMetadata.bbc_track_contributor_orchestra,
    });
  }
  if (
    item.trackMetadata &&
    item.trackMetadata.bbc_track_contributor_conductor
  ) {
    allTags.push({
      Type: "BBC_TRACK_CONDUCTOR",
      Value: item.trackMetadata.bbc_track_contributor_conductor,
    });
  }
  if (item.trackMetadata && item.trackMetadata.bbc_track_contributor_choir) {
    allTags.push({
      Type: "BBC_TRACK_CHOIR",
      Value: item.trackMetadata.bbc_track_contributor_choir,
    });
  }
  if (item.trackMetadata && item.trackMetadata.bbc_track_contributor_ensemble) {
    allTags.push({
      Type: "BBC_TRACK_ENSEMBLE",
      Value: item.trackMetadata.bbc_track_contributor_ensemble,
    });
  }
  if (
    item.trackMetadata &&
    item.trackMetadata.bbc_track_contributor_featured_artist
  ) {
    allTags.push({
      Type: "BBC_TRACK_FEATURED_ARTIST",
      Value: item.trackMetadata.bbc_track_contributor_featured_artist,
    });
  }

  if (item.trackMetadata && item.trackMetadata.org_admin_notes) {
    allTags.push({
      Type: "BBC_ADMIN_NOTES",
      Value: item.trackMetadata.org_admin_notes,
    });
  }

  if (item.trackMetadata) {
    item.trackMetadata.orgTags = allTags;
  }

  //Album Setting
  var album_allTags = [];
  if (item.albumMetadata && item.albumMetadata.org_album_adminTags) {
    item.albumMetadata.org_album_adminTags.forEach((tag) => {
      album_allTags.push({ Type: "BBC_ADMIN_TAG", Value: tag });
    });
  }

  // if (item.albumMetadata && item.albumMetadata.org_album_userTags) {
  //   item.albumMetadata.org_album_userTags.forEach((tag) => {
  //     album_allTags.push({ 'Type': `BBC_USER_TAG_${user.id}`, 'Value': tag });
  //   })
  // }

  if (item.albumMetadata && item.albumMetadata.bbc_album_id) {
    album_allTags.push({
      Type: "BBC_ALBUM_ID",
      Value: item.albumMetadata.bbc_album_id,
    });
  }

  if (item.albumMetadata && item.albumMetadata.org_album_admin_notes) {
    album_allTags.push({
      Type: "BBC_ADMIN_NOTES",
      Value: item.albumMetadata.org_album_admin_notes,
    });
  }
  if (item.albumMetadata) {
    item.albumMetadata.album_orgTags = album_allTags;
  }

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();
  dispatch({
    type: actionTypes.UPDATING,
    payload: message,
  });

  axios
    .post(`${orgID}/contentadmin/UpdateTrack`, item, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_OK,
        payload: res.data,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err.response?.data || {
          e: err.message
            ? err.message
            : "Error in creating or updating. please retry",
        },
      });

      if (done) done(null, err);
    });
};

export const updateTrack = (
  message,
  item,
  albumItem,
  isAlbumAlbum,
  done = null
) => (dispatch) => {
  const tId = uuidv4();
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  dispatch({
    type: actionTypes.UPDATING,
    payload: message,
  });

  axios
    .post(
      `${orgID}/uploads/tracks/trackUpdate`,
      { trackData: item, albumdata: albumItem, isAlbumEdit: isAlbumAlbum },
      {
        headers: { tid: tId, orgid: orgID },
      }
    )
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_OK,
        payload: item,
      });
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      if (done) done(null, err.message || "error");
    });
};

export const emptyItem = () => (dispatch) => {
  dispatch({
    type: actionTypes.EMPTY_ITEM,
  });
};

export const emptySelectedItem = () => (dispatch) => {
  dispatch({
    type: actionTypes.EMPTY_SELECTED_ITEM,
  });
};

export const setPreviewFlag = (status) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_PREVIEW,
    payload: status,
  });
};

export const setDataLoadFlag = (status) => (dispatch) => {
  dispatch({
    type: actionTypes.DATA_LOADED,
    payload: status,
  });
};

export const emptySession = () => (dispatch) => {
  dispatch({
    type: actionTypes.EMPTY_SESSION,
  });
};

export const GetUpdateTrack = (message, item, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.GETTING_UPDATE_DATA,
    payload: message,
  });

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  item.orgId = orgID;
  const tId = uuidv4();

  axios
    .post(`${orgID}/contentadmin/GetTrackForEdit`, item, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_DATA_OK,
        payload: res.data,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};

export const GetUpdateAlbum = (message, item, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.GETTING_ALBUM_UPDATE_DATA,
    payload: message,
  });

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  item.orgId = orgID;
  const tId = uuidv4();

  axios
    .post(`${orgID}/contentadmin/GetAlbumForEdit`, item, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.ALBUM_DATA_OK,
        payload: res.data,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};

export const showPermenantMessage = (message) => (dispatch) => {
  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });
};

export const removePermenantMessage = () => (dispatch) => {
  dispatch({
    type: actionTypes.POST_OK,
  });
};

export const BulkRemoveTrack = (message, item, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.GETTING_UPDATE_DATA,
    payload: message,
  });

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  item.orgId = orgID;
  const tId = uuidv4();

  axios
    .post(`${orgID}/uploads/tracks/BulkRemoveTracks`, item, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.DELETE_DATA_OK,
        payload: res.data,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};

export const RemoveTrack = (message, item, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.GETTING_UPDATE_DATA,
    payload: message,
  });

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  item.orgId = orgID;
  const tId = uuidv4();

  axios
    .post(`${orgID}/uploads/tracks/removeTrack`, item, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_DATA_OK,
        payload: res.data,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};

export const RemoveAlbum = (message, item, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.GETTING_UPDATE_DATA,
    payload: message,
  });

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  item.orgId = orgID;
  const tId = uuidv4();

  axios
    .post(`${orgID}/uploads/tracks/removeAlbum`, item, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.DELETE_DATA_OK,
        payload: res.data,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};

export const refreshGrid = (sessionId, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  axios
    .post(
      `${orgID}/uploads/tracks/refresh`,
      { sessionId: sessionId },
      {
        headers: { tid: tId, orgid: orgID },
      }
    )
    .then((res) => {
      if (done) done(res.data.results, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};

export const setUploadIdentifier = () => (dispatch) => {
  let identifier = uuidv4();
  dispatch({
    type: actionTypes.SET_UPLOAD_IDENTIFIER,
    payload: identifier,
  });
};

export const setUploadTracksToSession = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_UPLOAD_TRACKS,
    payload: items,
  });
};

export const doReloadSession = (status) => (dispatch) => {
  dispatch({
    type: actionTypes.DO_RELOAD_SESSION,
    payload: status,
  });
};

export const setQueueItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_QUEUE_ITEMS,
    payload: items,
  });
};

export const getS3Token = (done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  axios
    .post(`${orgID}/uploads/getS3SessionToken`, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      if (done) done(null, err);
    });
};

export const addSessionTracks = (
  action,
  message,
  items,
  sessionId,
  s3Token,
  done = null
) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  let tId = uuidv4();
  var idList = [];
  let completedCount = 0;

  items.forEach((track, i) => {
    tId = uuidv4();

    let _Track = {
      name: track.track_name.replace(/\.[^/.]+$/, ""),
      extention: track.s3_id.split(".").pop(),
      type: track.track_type,
      sessionId: sessionId,
      size: track.size,
      s3Id: track.s3_id,
      mlTrackid: track.id,
      s3Bucket: "bbc-ml-upload",
      artwork: track.artwork ? track.artwork.split(",")[1] : "",
      metadata_json: track.metadata_json,
      mlTrackId: track.id,
      dhTrackJson: track.dh_track_metadata,
      upload_id: track.upload_id,
    };

    const {
      REACT_APP_CONTENT_S3_REGION,
      REACT_APP_CONTENT_S3_BUCKET,
      REACT_APP_CONTENT_S3_TRACK_FOLDER,
    } = process.env;

    AWS.config.update({
      region: REACT_APP_CONTENT_S3_REGION,
      accessKeyId: s3Token.awsKey,
      secretAccessKey: s3Token.secretkey,
      sessionToken: s3Token.sessionToken,
      httpOptions: {
        timeout: 1800000,
        connectTimeout: 5000,
      },
    });
    const s3 = new AWS.S3({
      params: {
        Bucket: `${REACT_APP_CONTENT_S3_BUCKET}/${REACT_APP_CONTENT_S3_TRACK_FOLDER}`,
      },
    });

    // var uploadParams = {
    //   Key: _Track.s3Id,
    //   Body: dataURItoBlob(track.data),
    //   ContentType: _Track.type,
    // };

    var uploadParams = {
      Key: _Track.s3Id,
      Body: track.file,
      ContentType: _Track.type,
    };

    var s3_id = null;    

    s3.upload(uploadParams)
      .on("httpUploadProgress", (progressEvent) => {
        s3_id = progressEvent.key.split("/")[1];
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        const disply_status = "Uploading (" + percentCompleted + "%)";
        dispatch({
          type: actionTypes.UPLOADING,
          payload: { s3_id, disply_status, sessionId },
        });
      })
      .send((err, data) => {
        //  console.log("S3 Upload - Complete");
        if (err) {
          _Track.asset_upload_status = "S3 Failed";
          _Track.status = 3;
          // console.log("saveReportInS3 - Error - ", err);
          completedCount += 1;

          logUploadError(err);

          if (completedCount == items.length) done("COMPLTED", null);
        }
        if (data) {
          _Track.asset_upload_status = "S3 Success";
          _Track.status = 2;
          _Track.assetHash = track.assetHash;
          completedCount += 1;
          if (completedCount == items.length) done("COMPLTED", null);
        }
        axios
          .post(`${orgID}/uploads/tracks/updateUploadStatus`, _Track, {
            headers: { tid: tId, orgid: orgID },
          })
          .then((res) => {
            if (res.data.tid === tId) {
              let s3_id = res.data.reqParams.s3_id;
              let disply_status = "Upload Success";
              let upload_status = 2;
              let trackName = track.track_name.split("-")[1];

              if (
                res.data.data &&
                res.data.reqParams.asset_upload_status == "S3 Success"
              ) {
                track.disply_status = "Upload Success";
                track.upload_status = 2;
                dispatch({
                  type: actionTypes.UPLOAD_COMPLETE,
                  payload: { s3_id, disply_status, upload_status, sessionId },
                });               
              } else {
                disply_status = "Error Uploading";
                upload_status = 3;
                dispatch({
                  type: actionTypes.UPLOAD_ERROR,
                  payload: { s3_id, disply_status, upload_status, sessionId },
                });               
              }
            }

            //  console.log("Upload status update sucesss - ", data);
          })
          .catch((err) => {
            //-- CHECK LATER
            // console.log("Upload status update error - ", err);
          });
      });
  });

  done("START", null);
};

function logUploadError(error) {
  //console.log(error);

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  axios
    .post(`${orgID}/uploads/errorLog`, error, {
      headers: { orgid: orgID },
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
}

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

export const setQuery = (query, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });

  if (done) done();
};

export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
  showUploader(false)(dispatch);
  doUpdateSelected(true)(dispatch);
};

export const searchAlbums = (query, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  query.orderBy = "id";

  dispatch({
    type: actionTypes.LOADING,
  });
  axios
    .post(`${orgID}/uploads/albums/search`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.LOAD,
        payload: res.data.results,
        total: res.data.total,
        totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
      });
      if (done) done(res.data.results, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      if (done) done(null, err);
    });
};

export const setSessionId = (id) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SESSION_ID,
    payload: id,
  });
};

export const setCurrentSession = (done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const { user } = store.getState();
  const tId = uuidv4();

  let query = {
    order: SortTypes.desc,
    orderBy: "date_last_edited",
    page: 0,
    size: 50,
    q: "",
    filters: [],
  };

  axios
    .post(`${orgID}/uploads/session/search`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        var currentSession = null;
        var currentSessionFiltered = res.data.results.filter(
          (a) => a.created_by == user.id
        );
        if (currentSessionFiltered.length > 0)
          currentSession = currentSessionFiltered.reduce((max, obj) =>
            parseInt(max.id) > parseInt(obj.id) ? max : obj
          );
        dispatch({
          type: actionTypes.SET_SESSION,
          payload: currentSession,
        });
        if (done) done(currentSession, null);
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      if (done) done(null, err);
    });
};

export const search = (query, localSessionId, orderby, action, done = null) => (
  dispatch
) => {
  if (!orderby || orderby == "position") {
    query.orderBy = 'upload_session_id","disc_no","position';
  } else {
    query.orderBy = orderby;
  }
  query.order = "asc";

  if (action == "album-tracks") {
    query.filters.push({
      operator: "equals",
      value: [true],
      field: "dh_synced",
    });
  }

  const {
    user: {
      credentials: { orgID },
    },
    mlTrackUpdateReducer: { sessionId },
  } = store.getState();

  if (action != "album-tracks") {
    if (localSessionId) {
      query.filters = [];
      query.filters = [
        ...query.filters,
        { field: "session_id", operator: "equals", value: [localSessionId] },
      ];
    } else if (sessionId) {
      query.filters = [];
      query.filters = [
        ...query.filters,
        { field: "session_id", operator: "equals", value: [sessionId] },
      ];
    }
  }

  const tId = uuidv4();
  dispatch({
    type: actionTypes.LOADING,
  });
  axios
    .post(`${orgID}/uploads/tracks/search`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.LOAD,
          payload: res.data.results,
          total: res.data.total,
        });
        if (done) done(res.data.results, null);
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      if (done) done(null, err);
    });
};

export const tempSearch = (query, sessionId, orderby, done = null) => (
  dispatch
) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  if (!orderby) {
    query.orderBy = "date_last_edited";
  } else if (orderby == "position") {
    query.orderBy = 'upload_session_id","disc_no","position';
    query.order = "asc";
  }

  if (sessionId) {
    query.filters = [];
    query.filters = [
      ...query.filters,
      { field: "session_id", operator: "equals", value: [sessionId] },
    ];
  }

  const tId = uuidv4();
  // dispatch({
  //   type: actionTypes.LOADING,
  // });

  axios
    .post(`${orgID}/uploads/tracks/search`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        // res.data.results.forEach((data) => {
        //   //data.status = "Created"
        // });
        //  dispatch({
        //    type: actionTypes.LOAD,
        //    payload: res.data.results,
        //    total: res.data.total,
        //  });
        if (done) done(res.data.results, null);
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      if (done) done(null, err);
    });
};

export const CheckUploadTrackExsist = (sessionId, done = null) => (
  dispatch
) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const tId = uuidv4();

  axios
    .post(`${orgID}/uploads/tracks/CheckUploadTrackExsist`, sessionId, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        if (done) done(res.data.results, null);
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      if (done) done(null, err);
    });
};

export const ChangeTrackSession = (param, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const tId = uuidv4();

  axios
    .post(`${orgID}/uploads/session/ChangeTrackSession`, param, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        if (done) done(res.data, null);
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      if (done) done(null, err);
    });
};

export const getAlbumById = (albumId, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const tId = uuidv4();

  axios
    .post(`${orgID}/uploads/session/getUploadAlbumById/${albumId}`, albumId, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.SET_ALBUM_NAME,
          payload: res.data.results,
        });
        if (done) done(res.data.results, null);
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      if (done) done(null, err);
    });
};

export const searchAlbumTracks = (query, sessionId, done = null) => (
  dispatch
) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  query.orderBy = "id";
  if (sessionId) {
    query.filters = [
      ...query.filters,
      { field: "session_id", operator: "equals", value: [sessionId] },
    ];
  }

  const tId = uuidv4();
  dispatch({
    type: actionTypes.LOADING,
  });
  axios
    .post(`${orgID}/uploads/session/getUploadAlbumTracks`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.LOAD,
          payload: res.data.results,
          total: res.data.total,
        });
        if (done) done(res.data.results, null);
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      if (done) done(null, err);
    });
};

export const reorderItems = (sourceIndex, destIndex) => (dispatch) => {
  const {
    mlTrackUpdateReducer: { items },
  } = store.getState();

  var result = items;
  const [removed] = result.splice(sourceIndex, 1);
  result.splice(destIndex, 0, removed);

  dispatch({
    type: actionTypes.LOAD_ITEMS,
    payload: result,
  });

  //shiftPlaylistTrack(plid, sourceIndex, destIndex);
};

export const reorderReturnTracks = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.LOAD_ITEMS,
    payload: items,
  });

  //shiftPlaylistTrack(plid, sourceIndex, destIndex);
};

export const showAlertMessage = (alertObject) => (dispatch) => {
  dispatch({
    type: actionTypes.SHOW_ALERT_MESSAGE,
    payload: alertObject,
  });
};

export const updateComponent = (action) => (dispatch) => {
  dispatch({
    type: actionTypes.DO_UPDATE,
    payload: action,
  });
};

export const setSourceDest = (sourceIndex, destIndex) => (dispatch) => {
  dispatch({
    type: actionTypes.SOURCE_DEST,
    source: sourceIndex,
    dest: destIndex,
  });
};

export const reorderTracks = (
  sourceIndex,
  destIndex,
  albumId,
  message,
  done = null
) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();
  dispatch({
    type: actionTypes.UPDATING,
    payload: message,
  });

  axios
    .post(
      `${orgID}/uploads/tracks/reorder`,
      { sourceIndex: sourceIndex, albumId: albumId, destIndex: destIndex },
      {
        headers: { tid: tId, orgid: orgID },
      }
    )
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};

export const uploadTrackSearchForPreview = (
  query,
  selectedItem,
  done = null
) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  var searchTransId = uuidv4();
  const options = {
    headers: {
      tid: searchTransId,
    },
  };

  dispatch({
    type: actionTypes.POSTING,
    payload: "",
  });
  if (selectedItem && selectedItem.upload_id) {
    axios
      .post(
        `${orgID}/uploads/getElasticTrack`,
        { trackId: selectedItem.upload_id },
        options
      )
      .then((res) => {
        if (res.data.tid === searchTransId) {
          if (res.data?.data.length > 0) {
            dispatch({
              type: actionTypes.SET_SELECTED_ITEM,
              payload: res.data?.data[0],
            });
            dispatch({
              type: actionTypes.POST_OK,
              payload: "",
            });
            if (done) done(res.data?.data, null);
          } else {
            var dataToPreview = createObject(selectedItem);
            dispatch({
              type: actionTypes.SET_SELECTED_ITEM,
              payload: dataToPreview,
            });
            dispatch({
              type: actionTypes.POST_OK,
              payload: "",
            });
            if (done) done(res.data?.data, null);
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ERROR,
          payload: { e: err },
        });
      });
  }
};

const createObject = (selected) => {
  var objectToSend = {
    trackTitle: selected.track_name,
    id: selected.upload_id,
    prodArtworkUrl: selected.metadata_json.artwork_url,
    performer: [selected.performer],
    prodName: selected.album_name,
    musicOrigin: selected.metadata_json.musicorigin,
    isrc: selected.isrc,
  };
  return objectToSend;
};
