import { actionTypes } from "../actions/MlPlaylistContentActions";
import { defaultResultsPerPage, pagingTotalRelation } from "../Types";
import trackAllFileds from "../../_module/definition/trackAllFileds";
import albumAllFileds from "../../_module/definition/albumAllFileds";

const getFieldList = () => {
  const fieldList = [];
  [...trackAllFileds, ...albumAllFileds].forEach((r) => {
    if (!fieldList.includes(r)) {
      fieldList.push(r);
    }
  });
  return fieldList;
};

const initialState = {
  items: [],
  totalItems: 0,
  totalRelation: pagingTotalRelation.EQUALS,
  selectedItem: {},
  selectedItems: [],
  loading: false,
  posting: { busy: false, message: "", errors: null },
  editing: null,
  playlist: null,
  postUpdating: false,
  query: {
    page: 0,
    size: defaultResultsPerPage,
    q: "",
    qfs: getFieldList(),
    filters: [],
    plid: null,
  },
  errors: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
        selectedItems: [],
        items: [],
      };
    case actionTypes.LOADING_MORE:
      return {
        ...state,
        loading: false,
        errors: null,
      };

    case actionTypes.LOAD_ITEMS:
      return {
        ...state,
        loading: false,
        items: action.payload,
      };

    case actionTypes.LOAD:
      return {
        ...state,
        loading: false,
        errors: null,
        items: action.payload.items,
        totalItems: action.payload.total,
        totalRelation: action.payload.totalRelation,
        selectedItem:
          action.payload.items && action.payload.items.length > 0
            ? action.payload.items[0]
            : {},
        selectedItems: [],
        postUpdating: action.payload.postUpdating ? true : false,
      };

    case actionTypes.POST_UPDATE:
      return {
        ...state,
        items: action.payload.items,
        postUpdating: false,
      };

    case actionTypes.ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        items: [],
        totalItems: 0,
        totalRelation: pagingTotalRelation.EQUALS,
      };

    case actionTypes.SELECT_ITEMS:
      return {
        ...state,
        selectedItems: action.payload,
      };
    case actionTypes.QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case actionTypes.FOCUS_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
      };

    case actionTypes.EDIT_ITEM:
      return {
        ...state,
        editing: action.payload,
      };

    case actionTypes.POSTING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.FILTER_ACTIVE_TRACKS:
      let filteredItems = [];
      if (action.payload?.items?.length && action.payload.items.length > 0) {
        filteredItems = action.payload.items
          .map((item) => state.items.find((r) => r.id === item.id))
          .filter((r) => r);
      }
      return {
        ...state,
        playlist: action.payload,
        items: filteredItems,
      };

    case actionTypes.STOP_MESSAGE:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
      };

    case actionTypes.POST_OK:
      var items = state.items.find((r) => r.id === action.payload.id)
        ? state.items.map((i) =>
            i.id === action.payload.id ? action.payload : i
          )
        : [action.payload, ...state.items];

      return {
        ...state,
        items,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
      };

    case actionTypes.POST_ERROR:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: action.payload,
        },
      };

    case actionTypes.SET_PLAYLIST:
      return {
        ...state,
        playlist: action.payload,
      };

    case actionTypes.SET_MESSAGE:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.CLEAR_MESSAGE:
      return {
        ...state,
        posting: {
          busy: false,
          message: null,
          errors: null,
        },
      };

    case actionTypes.RESET:
      return initialState;

    case actionTypes.PL_UPDATING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.PL_UPDATE_OK:
      if (state.playlist?.id === action.payload?.id) {
        const updatedItems = [];
        state.items.forEach((r) => {
          if (action.payload?.items) {
            const item = action.payload?.items?.find((s) => r.id === s.id);
            if (item) {
              r.pl_prop_chartDate = item.chartDate;
              r.pl_prop_chartNumber = item.chartNumber;
              r.pl_prop_notes = item.notes;
              r.pl_prop_position = item.position;
              r.pl_prop_tags = item.tags;
              r.pl_prop_type = item.type;
              updatedItems.push(r);
            }
          }
        });

        return {
          ...state,
          items: updatedItems,
          playlist: action.payload,
          posting: {
            busy: false,
            message: "",
            errors: null,
          },
        };
      } else {
        return state;
      }

    case actionTypes.PL_UPDATE_ERROR:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: action.payload,
        },
      };

    case actionTypes.REFRESH_ITEMS:
      return {
        ...state,
        items: action.payload,
      };

    default:
      return state;
  }
}
