import {
  INBOX_START_LOADING_ALL_USERS,
  INBOX_SET_ALL_USERS,
  INBOX_STOP_LOADING_ALL_USERS,
  INBOX_SET_ERROR_LOADING_ALL_USERS,
  INBOX_CLEAR_ERROR_LOADING_ALL_USERS,

  INBOX_START_LOADING_MESSAGES,
  INBOX_SET_MESSAGES,
  INBOX_STOP_LOADING_MESSAGES,
  INBOX_SET_ERROR_LOADING_MESSAGES,
  INBOX_CLEAR_ERROR_LOADING_MESSAGES,

  INBOX_START_LOADING_COMMENTS,
  INBOX_SET_COMMENTS,
  INBOX_STOP_LOADING_COMMENTS,
  INBOX_SET_ERROR_LOADING_COMMENTS,
  INBOX_CLEAR_ERROR_LOADING_COMMENTS,

  INBOX_START_LOADING_USERS,
  INBOX_SET_USERS,
  INBOX_STOP_LOADING_USERS,
  INBOX_SET_ERROR_LOADING_USERS,
  INBOX_CLEAR_ERROR_LOADING_USERS,

  INBOX_SAVE_DRAFT_COMMENT,
  INBOX_SAVE_DRAFT_MESSAGE,
  
  INBOX_START_LOADING_ALL_GROUPS,
  INBOX_SET_ALL_GROUPS,
  INBOX_STOP_LOADING_ALL_GROUPS,
  INBOX_SET_ERROR_LOADING_ALL_GROUPS,
  INBOX_CLEAR_ERROR_LOADING_ALL_GROUPS,
} from "../Types";

const initialState = {
  loading: false,
  loadingGroups: false,
  errors: null,
  allUsers: [],
  allGroups: [],

  loadingMessages: false,
  errorsMessages: null,
  messages: [],

  loadingComments: false,
  errorsComments: null,
  comments: [],

  loadingUsers: false,
  errorsUsers: null,
  users: [],

  draftCommentId: 0,
};

export default function (state = initialState, action) {
  
  switch (action.type) {

    case INBOX_SAVE_DRAFT_COMMENT:
      return {
        ...state,
        draftCommentId: action.payload,
      };
    case INBOX_SAVE_DRAFT_MESSAGE:
      return {
        ...state,
        draftMessageId: action.payload,
      };
    case INBOX_START_LOADING_ALL_USERS:
      return {
        ...state,
        loading: true,
      };
    case INBOX_SET_ALL_USERS:
      return {
        ...state,
        allUsers: action.users,
      };
    case INBOX_STOP_LOADING_ALL_USERS:
      return {
        ...state,
        loading: false,
      };
    case INBOX_SET_ERROR_LOADING_ALL_USERS:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case INBOX_CLEAR_ERROR_LOADING_ALL_USERS:
      return {
        ...state,
        loading: false,
        errors: null,
      };
      
    case INBOX_START_LOADING_ALL_GROUPS:
      return {
        ...state,
        loadingGroups: true,
      };
    case INBOX_SET_ALL_GROUPS:
      return {
        ...state,
        allGroups: action.groups,
      };
    case INBOX_STOP_LOADING_ALL_GROUPS:
      return {
        ...state,
        loadingGroups: false,
      };
    case INBOX_SET_ERROR_LOADING_ALL_GROUPS:
      return {
        ...state,
        loadingGroups: false,
        errors: action.errors,
      };
    case INBOX_CLEAR_ERROR_LOADING_ALL_GROUPS:
      return {
        ...state,
        loading: false,
        errors: null,
      };

    case INBOX_START_LOADING_MESSAGES:
      return {
        ...state,
        loadingMessages: true,
      };
    case INBOX_SET_MESSAGES:
      return {
        ...state,
        messages: action.messages,
      };
    case INBOX_STOP_LOADING_MESSAGES:
      return {
        ...state,
        loadingMessages: false,
      };
    case INBOX_SET_ERROR_LOADING_MESSAGES:
      return {
        ...state,
        loadingMessages: false,
        errorsMessages: action.errors,
      };
    case INBOX_CLEAR_ERROR_LOADING_MESSAGES:
      return {
        ...state,
        loadingMessages: false,
        errorsMessages: null,
      };

    case INBOX_START_LOADING_COMMENTS:
      return {
        ...state,
        loadingComments: true,
      };
    case INBOX_SET_COMMENTS:
      return {
        ...state,
        comments: action.comments,
      };
    case INBOX_STOP_LOADING_COMMENTS:
      return {
        ...state,
        loadingComments: false,
      };
    case INBOX_SET_ERROR_LOADING_COMMENTS:
      return {
        ...state,
        loadingComments: false,
        errorsComments: action.errors,
      };
    case INBOX_CLEAR_ERROR_LOADING_COMMENTS:
      return {
        ...state,
        loadingComments: false,
        errorsComments: null,
      };

    case INBOX_START_LOADING_USERS:
      return {
        ...state,
        loadingUsers: true,
      };
    case INBOX_SET_USERS:
      return {
        ...state,
        users: action.users,
      };
    case INBOX_STOP_LOADING_USERS:
      return {
        ...state,
        loadingUsers: false,
      };
    case INBOX_SET_ERROR_LOADING_USERS:
      return {
        ...state,
        loadingUsers: false,
        errorsUsers: action.errors,
      };
    case INBOX_CLEAR_ERROR_LOADING_USERS:
      return {
        ...state,
        loadingUsers: false,
        errorsUsers: null,
      };

    default:
      return state;
  }
};