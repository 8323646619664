import {T_LeafBool, T_Major } from "./ModulePropTypes";
import { PT_Org } from "./ModulePermitTypes";

// export const _mlCanPublishToPlayout = {
//   id: "mlcpo1",
//   type: T_LeafBool,
//   default: true,
// };

// export const _mlCanManagePlayout = {
//   id: "mlcpo2",
//   type: T_LeafBool,
//   default: true,
// };

export const _playouts = {
  id: "playouts",
  permitType: PT_Org,
  type: T_Major,
  modules: [],
  // modules: [_mlCanPublishToPlayout,
  // _mlCanManagePlayout],
};
