import { fieldType } from "../../redux/Types";

export default {
  selector: { id: "selector", sys: true, type: fieldType.TEXT},
  catNo: { id: "catNo", type: fieldType.TEXT },
  prodName: { id: "prodName", canCloud: true, type: fieldType.TEXT },
  ipRecordLabel: { id: "ipRecordLabel", canCloud: true, type: fieldType.TEXT },
  prodArtist: { id: "prodArtist", canCloud: true, type: fieldType.TEXT, linkable: true },
  musicOrigin: { id: "musicOrigin", skipUserPreff: true, type: fieldType.TEXT },
  musicOriginSubIndicator: { id: "musicOriginSubIndicator", skipUserPreff: true, type: fieldType.TEXT },
  prodArtworkUrl: { id: "prodArtworkUrl", type: fieldType.TEXT },
  trackPerformer: { id: "trackPerformer", canCloud: true, type: fieldType.TEXT, linkable: true },
  trackLyricist: { id: "trackLyricist", canCloud: true, type: fieldType.TEXT, linkable: true },
  cLine: { id: "cLine", type: fieldType.TEXT },
  bbcAlbumId: { id: "bbcAlbumId", type: fieldType.TEXT },
  trackComposerLyricist: { id: "trackComposerLyricist", canCloud: true, type: fieldType.TEXT, linkable: true },
  trackAdaptor: { id: "trackAdaptor", canCloud: true, type: fieldType.TEXT, linkable: true },
  trackAdministrator: { id: "trackAdministrator", canCloud: true, type: fieldType.TEXT, linkable: true },
  trackArranger: { id: "trackArranger", canCloud: true, type: fieldType.TEXT, linkable: true },
  trackComposer: { id: "trackComposer", canCloud: true, type: fieldType.TEXT, linkable: true },
  trackPublisher: { id: "trackPublisher", canCloud: true, type: fieldType.TEXT, linkable: true },
  trackOriginalPublisher: { id: "trackOriginalPublisher", type: fieldType.TEXT, linkable: true },
  trackRecordLabel: { id: "trackRecordLabel", canCloud: true, type: fieldType.TEXT},
  trackSubLyricist: { id: "trackSubLyricist", canCloud: true, type: fieldType.TEXT, linkable: true },
  trackSubAdaptor: { id: "trackSubAdaptor", canCloud: true, type: fieldType.TEXT, linkable: true },
  trackSubArranger: { id: "trackSubArranger", canCloud: true, type: fieldType.TEXT, linkable: true },
  trackSubPublisher: { id: "trackSubPublisher", canCloud: true, type: fieldType.TEXT, linkable: true },
  trackTranslator: { id: "trackTranslator", canCloud: true, type: fieldType.TEXT, linkable: true },
  trackTitle: { id: "trackTitle", canCloud: true, type: fieldType.TEXT },
  trackIsrc: { id: "trackIsrc", type: fieldType.TEXT },
  trackPrs: { id: "trackPrs", type: fieldType.TEXT },
  trackIswc: { id: "trackIswc", type: fieldType.TEXT },
  libName: { id: "libName", canCloud: true, type: fieldType.TEXT },
  libNotes: { id: "libNotes", notetag: true, type: fieldType.TEXT },
  trackCount: { id: "trackCount", type: fieldType.TEXT },
  prodRelease: { id: "prodRelease", type: fieldType.DATE },
  prodSubtitle: { id: "prodSubtitle", type: fieldType.TEXT },
  prodNotes: { id: "prodNotes", notetag: true, type: fieldType.TEXT },
  prodYear: { id: "prodYear", type: fieldType.DATE },
  prodNumberOfDiscs: { id: "prodNumberOfDiscs", type: fieldType.NUMBER },
  prodDiscNr: { id: "prodDiscNr", type: fieldType.TEXT },
  prodTags: { id: "prodTags", canCloud: true, tag: true, type: fieldType.TEXT },
  trackTempo: { id: "trackTempo", canCloud: true, tag: true, type: fieldType.TEXT },
  trackBpm: { id: "trackBpm", canCloud: true, tag: true, type: fieldType.TEXT },
  libId: { id: "libId", type: fieldType.TEXT },
  libLogoFileName: { id: "libLogoFileName", type: fieldType.TEXT },
  trackVersionTitle: { id: "trackVersionTitle", type: fieldType.TEXT },
  trackMoods: { id: "trackMoods", canCloud: true, tag: true, type: fieldType.TEXT },
  trackStyles: { id: "trackStyles", canCloud: true, tag: true, type: fieldType.TEXT },
  trackKeywords: { id: "trackKeywords", canCloud: true, tag: true, type: fieldType.TEXT },
  trackNotes: { id: "trackNotes", notetag: true, type: fieldType.TEXT },
  trackGenres: { id: "trackGenres", canCloud: true, tag: true, type: fieldType.TEXT },
  trackInstruments: { id: "trackInstruments", canCloud: true, tag: true, type: fieldType.TEXT },
  wsId: { id: "wsId", type: fieldType.TEXT },
  wsName: { id: "wsName", canCloud: true, type: fieldType.TEXT },
  cTags: { id: "cTags", type: fieldType.TEXT },
  id: { id: "id", type: fieldType.TEXT },
  ean: { id: "ean", type: fieldType.TEXT },
  upc: { id: "upc", type: fieldType.TEXT },
  grid: { id: "grid", type: fieldType.TEXT },
  prodId: {id: "prodId", type: fieldType.TEXT },
  territories: {id: "territories", type: fieldType.TEXT },
  adminTags: {id: "adminTags", type: fieldType.TEXT },
  adminNotes: {id: "adminNotes", type: fieldType.TEXT },
  charted: { id: "charted", type: fieldType.BOOLEAN },
  chartType: { id: "chartType", type: fieldType.TEXT },
  chartArtist: { id: "chartArtist", type: fieldType.BOOLEAN },
};
