import axios from "axios";
import { focusItem as trackFocusItem, refreshItems as trackRefreshItems } from "redux/actions/MLLibraryActions";
import { focusItem as albumTrackFocusItem, refreshItems as albumTrackRefreshItems } from "redux/actions/MlAlbumTrackActions";
import { refreshSelectedItem as playlistTrackFocusItem, refreshItems as playlistTrackRefreshItems } from "redux/actions/MlPlaylistContentActions";
import store from "redux/Store";
import { prsSearchRefreshDataType, prsUpdateFields } from "util/prs-search/const";
module = "/searchPrs";


export const actionTypes = {
  POSTING: "POSTING" + module,
  POSTING_OK: "POSTINGOK" + module,
  POST_OK: "POSTOK" + module,
  POSTING_ERROR: "POSTING_ERROR" + module,
  POST_ERROR: "POST_ERROR" + module,
  CLEAR_ERRORS: "CLEAR_ERRORS" + module,
};


export const postItem = ( trackId, done = null, searchRefreshDataType) => (dispatch) => {
  
  const {
    user: {
      credentials: { orgID },
    }
  } = store.getState();

  dispatch({
    type: actionTypes.POSTING,
    payload: 'Matching with PRS...',
  });


  axios
  .get(`${orgID}/praManualMatch/${trackId}`)
  .then((res) => {

      dispatch({
        type: actionTypes.POSTING_OK,
        payload: res.data?.prsSearchError ? 'PRS match error' : !res.data?.workTunecode ? 'PRS not matched': (res.data?.workTunecode && res.data?.update) ? 'PRS matched' : 'PRS match error',
      });

      if((!res.data?.prsSearchError)){  //'PRS matched' || 'PRS not matched'
        refreshData(trackId,res.data,searchRefreshDataType);
      }

      setTimeout(()=>{
        dispatch({
          type: actionTypes.POST_OK,
        });
      }, 2000);

      if (done) done();
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POSTING_ERROR,
        payload: `PRS match error`,
      });

      setTimeout(()=>{
        dispatch({
          type: actionTypes.POST_ERROR,
          payload: err.response?.data || { e: (err.message ? err.message : "PRS match error") },
        });
      }, 2000);

      if (done) done();
    });
};


export const manualMatchByTunecode = ( trackData, done = null) => (dispatch) => {
  
  const {
    user: {
      credentials: { orgID },
    }
  } = store.getState();

  dispatch({
    type: actionTypes.POSTING,
    payload: 'Updating...',
  });


  axios
  .post(`${orgID}/manualMatchByTunecode`, trackData, {
    headers: { orgid: orgID },
  })
  .then((res) => {
      dispatch({
        type: actionTypes.POSTING_OK,
        payload: res.data?.prsSearchError ? 'Update error' : !res.data?.workTunecode ? 'Update error': (res.data?.workTunecode && res.data?.update) ? 'Successfully updated' : 'Update error.',
      });

      setTimeout(()=>{
        dispatch({
          type: actionTypes.POST_OK,
        });
      }, 2000);

      if (done) done();
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POSTING_ERROR,
        payload: `Error updating. please retry`,
      });

      setTimeout(()=>{
        dispatch({
          type: actionTypes.POST_ERROR,
          payload: err.response?.data || { e: (err.message ? err.message : "Error updating. please retry") },
        });
      }, 2000);

      if (done) done();
    });
};

export const clearErrors = (done) => (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR_ERRORS,
    payload: {},
  });
  if (done) done();
};

const refreshData = (dhTrackId, data, type) =>{
  const {
    mltracklibrary: {
      items : trackItems,
      selectedItem : trackSelectedItem
    },
    mlalbumtrack: {
      items : albumTrackItems,
      selectedItem : albumTSelectedItem
    },
    mlplaylistcontent: {
      items : playlistItems,
      selectedItem : playlistSelectedItem
    },
  } = store.getState();

  switch (type) {
    case prsSearchRefreshDataType.TRACK:
      trackItems.map(item=>{
        if(item.dhTrackId === dhTrackId){
          prsUpdateFields.map(field=>{
            if(data[field.resultKey] !== undefined && data[field.resultKey] !== null){
              item[field.key] = data[field.resultKey];
            }
          });
        }
        return item;
      });
      store.dispatch(trackRefreshItems(trackItems));

      if(trackSelectedItem.dhTrackId === dhTrackId){
        prsUpdateFields.map(field=>{
          if(data[field.resultKey] !== undefined && data[field.resultKey] !== null){
            trackSelectedItem[field.key] = data[field.resultKey];
          }
        });
        store.dispatch(trackFocusItem(trackSelectedItem));
      }
      break;

    case prsSearchRefreshDataType.ALBUM_TRACKS:
      albumTrackItems.map(item=>{
        if(item.dhTrackId === dhTrackId){
          prsUpdateFields.map(field=>{
            if(data[field.resultKey] !== undefined && data[field.resultKey] !== null){
              item[field.key] = data[field.resultKey];
            }
          });
        }
        return item;
      });
      store.dispatch(albumTrackRefreshItems(albumTrackItems));

      if(albumTSelectedItem.dhTrackId === dhTrackId){
        prsUpdateFields.map(field=>{
          if(data[field.resultKey] !== undefined && data[field.resultKey] !== null){
            albumTSelectedItem[field.key] = data[field.resultKey];
          }
        });
        store.dispatch(albumTrackFocusItem(albumTSelectedItem));
      }
      break;

    case prsSearchRefreshDataType.PLAYLIST_TRACKS:
      playlistItems.map(item=>{
        if(item.dhTrackId === dhTrackId){
          prsUpdateFields.map(field=>{
            if(data[field.resultKey] !== undefined && data[field.resultKey] !== null){
              item[field.key] = data[field.resultKey];
            }
          });
        }
        return item;
      });
      store.dispatch(playlistTrackRefreshItems(playlistItems));

      if(playlistSelectedItem.dhTrackId === dhTrackId){
        prsUpdateFields.map(field=>{
          if(data[field.resultKey] !== undefined && data[field.resultKey] !== null){
            playlistSelectedItem[field.key] = data[field.resultKey];
          }
        });
        store.dispatch(playlistTrackFocusItem(playlistSelectedItem));
      }
      break;
  
    default:
      break;
  }
}
