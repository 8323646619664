import { textLengths } from "../../../../redux/Types";

export const ValidateHelp = (data) => {
    let errors = {}

    if (data) {
        if (!data.title) {
            errors.title = "Title should not be empty";
        } else if (data.title && data.title.length > textLengths.TEXT) {
            errors.title = "Sorry, the title you entered is too long. Please try a shorter title";
        }
        if (data.description && data.description.length > textLengths.TEXT) {
            errors.description = "Sorry, the description you entered is too long. Please try a shorter description";
        }

        if(!data.category){
            errors.category = "Category should not be empty";
        }

        if(!data.order){
            errors.order = "Order should not be empty";
        }
        if(data.order && data.order < 1){
            errors.order = "Invalid order value";
        }
    }


    if (Object.entries(errors).length === 0) {
        return null;
    } else {
        return errors;
    }
}