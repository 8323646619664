import { T_LeafBool, T_Branch, T_Major } from "./ModulePropTypes";
import { PT_Org } from "./ModulePermitTypes";

export const _manageMlUsers = {
  id: "manageMlUsers",
  type: T_LeafBool,
  default: true,
};

export const _manageMlGroups = {
  id: "manageMlGroups",
  type: T_LeafBool,
  default: true,
};

export const _manageMlRoles = {
  id: "manageMlRoles",
  type: T_LeafBool,
  default: true,
};

export const _manageMlSettings = {
  id: "manageMlSettings",
  type: T_LeafBool,
  default: true,
};

export const _manageMlAlbumRank = {
  id: "manageMlAlbumRank",
  type: T_LeafBool,
  default: true,
};

export const _manageMlTrackRank = {
  id: "manageMlTrackRank",
  type: T_LeafBool,
  default: true,
};

export const _manageMlPlaylistCategory = {
  id: "manageMlPlCategory",
  type: T_LeafBool,
  default: true,
};

export const _manageMlActivityLimit = {
  id: "manageMlActivityLimit",
  type: T_LeafBool,
  default: true,
};

export const _manageMlHelp = {
  
  id: "manageMlHelp",
  type: T_LeafBool,
  default: true,

};


export const _mlAdmin = {
  id: "mlAdmin",
  permitType: PT_Org,
  type: T_Major,
  modules: [
    _manageMlUsers,
    _manageMlGroups,
    _manageMlRoles,
    _manageMlSettings,
    _manageMlAlbumRank,
    _manageMlTrackRank,
    _manageMlPlaylistCategory,
    _manageMlActivityLimit,
    _manageMlHelp
  ],
};
