import albumFields from "./albumFields";

export default [
    albumFields.id.id,
    albumFields.catNo.id,
    albumFields.prodName.id,
    albumFields.prodArtist.id,
    albumFields.ipRecordLabel.id,
    albumFields.prodYear.id,
    albumFields.prodRelease.id,
    albumFields.prodNumberOfDiscs.id,
    albumFields.prodDiscNr.id,
    albumFields.musicOrigin.id,
    albumFields.prodTags.id,
    albumFields.trackTempo.id,
    albumFields.trackBpm.id,
    albumFields.trackGenres.id,
    albumFields.trackInstruments.id,
    albumFields.trackStyles.id,
    albumFields.upc.id,
    albumFields.ean.id,

    albumFields.trackTitle.id,
    albumFields.trackIsrc.id,
    albumFields.trackIswc.id,
    albumFields.trackPrs.id,
    albumFields.trackComposer.id,
    albumFields.trackLyricist.id,
    albumFields.trackAdaptor.id,
    albumFields.trackAdministrator.id,
    albumFields.trackArranger.id,
    albumFields.trackPublisher.id,
    albumFields.trackOriginalPublisher.id,
    albumFields.trackPerformer.id,
    albumFields.trackRecordLabel.id,
    albumFields.trackSubLyricist.id,
    albumFields.trackSubAdaptor.id,
    albumFields.trackSubArranger.id,
    albumFields.trackSubPublisher.id,
    albumFields.trackTranslator.id,

    albumFields.libNotes.id,
    albumFields.prodNotes.id,
    albumFields.trackNotes.id,

    albumFields.libId.id,
    albumFields.libName.id,
    albumFields.wsId.id,
    albumFields.wsName.id,
    
    albumFields.prodId.id,   
    
];


export const albumDefaultFields =  [
    albumFields.catNo.id,
    albumFields.prodName.id,
    albumFields.prodArtist.id,
    albumFields.libName.id,
    albumFields.ipRecordLabel.id,
    albumFields.prodNotes.id,
  ]