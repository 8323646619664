import { actionTypes } from "../actions/MlLicFormActions";
import { SortTypes } from "../../util/table-view/TableTypes";
import { defaultResultsPerPage, pagingTotalRelation } from "../Types";

const initialState = {
  items: [],
  totalItems: 0,
  totalRelation: pagingTotalRelation.EQUALS,
  selectedItem: {},
  selectedItems: [],
  loading: false,
  loadingMore: false,
  posting: { busy: false, message: "", errors: null },
  editing: null,
  noMore: true,
  recent: { busy: false, message: "", errors: null },
  recentList: null,
  recentQuery: {
    order: SortTypes.desc,
    orderBy: "updatedAt",
    page: 0,
    size: 3,
    q: "",
    filters: [],
  },
  query: {
    order: SortTypes.desc,
    orderBy: "updatedAt",
    page: 0,
    size: defaultResultsPerPage,
    q: "",
    filters: [
      // { operator: FilterOperatorTypes.equals, value: [0], field: "status" },
    ],
  },
  errors: null,
  lastRefreshed: 0,
  isAllocatedMenuOpen: false,
  history: {}
};

export default function (state = initialState, action) {

  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        loading: true,
        loadingMore: false,
        errors: null,
        selectedItems: [],
        items: [],
      };

    case actionTypes.LOAD:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        errors: null,
        items: action.payload,
        totalItems: action.total,
        totalRelation: action.totalRelation,
        selectedItem: action.payload[0] || {},
        errors: null,
        selectedItems: [],
        noMore: false,
      };

    case actionTypes.ERROR:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        errors: action.payload,
        items: [],
        totalItems: 0,
        totalRelation: pagingTotalRelation.EQUALS,
        noMore: true,
      };

    case actionTypes.LOAD_HISTORY:
      state.history[action.payload.cfId] = action.payload.history
      return {
        ...state
      };

    case actionTypes.REMOVE_HISTORY:
      delete state.history[action.payload];
      return {
        ...state
      };

    case actionTypes.SELECT_ITEMS:
      return {
        ...state,
        selectedItems: action.payload,
      };
    case actionTypes.QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case actionTypes.FOCUS_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
      };

    case actionTypes.EDIT_ITEM:
      return {
        ...state,
        editing: action.payload,
      };

    case actionTypes.POSTING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.POST_OK:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
      };

    case actionTypes.POST_ERROR:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: action.payload,
        },
      };

    case actionTypes.RECENT_QUERY:
      return {
        ...state,
        recentQuery: { ...state.recentQuery, q: action.payload },
      };

    case actionTypes.RECENT_LOADING:
      return {
        ...state,
        recent: { busy: true, message: "", errors: null },
      };

    case actionTypes.RECENT_LOAD:
      return {
        ...state,
        recent: { busy: false, message: "", errors: null },
        recentList: action.payload,
      };
    case actionTypes.RECENT_ERROR:
      return {
        ...state,
        recent: { busy: false, message: "", errors: action.payload },
        recentList: [],
      };
    case actionTypes.REFRESH_ITEMS:
      return {
        ...state,
        items: action.payload,
      };

    case actionTypes.LAST_REFRESH:
      return {
        ...state,
        lastRefreshed: action.payload,
      };

    case actionTypes.IS_ALLOCATED_OPEN:
      return {
        ...state,
        isAllocatedMenuOpen: action.payload,
      };
    case actionTypes.CF_Share:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.CF_Share_OK:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
      };

    default:
      return state;
  }
}
