import { textLengths } from "redux/Types";

export const validateContentAlert = (data) => {
  let errors = {};

  if (data) {
    if (!data.alert) {
      errors.alert = "Alert type should not be empty";
    }

    if (!data.notes || data.notes.trim() === "") {
      errors.notes = "Notes should not be empty";
    }else if(data.notes.length > textLengths.MEDIUMTEXT){
        errors.notes = "Sorry, the note you entered is too long. Please try a shorter note";
    }
  }

  if (Object.entries(errors).length === 0) {
    return null;
  } else {
    return errors;
  }
};
