import {  reportFileStatus } from "redux/Types";



export const reportTypes = {
  ScheduleDelivery: 1,
  OneTime: 2,
}

export const conditionPreviewScreen = {
  AddEdit: 1,
  SidePreview: 2,
}
export const reportDeliveryStatus = {
  Pending: 0,
  Processing: 1,
  Error: 2,
  Completed: 3,
}

export const reportJobStatus = {
  Pending: 0,
  Processing: 1,
  Error: 2,
  Completed: 3,
}

export const processingStatus = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  SCHEDULED: 'SCHEDULED',
  COMPLETED: 'COMPLETED',
}

export const reportDeliveryStatusList = [
  { id: reportDeliveryStatus.Pending, label: "Pending" },
  { id: reportDeliveryStatus.Processing, label: "Processing" },
  { id: reportDeliveryStatus.Error, label: "Error" },
  { id: reportDeliveryStatus.Completed, label: "Completed" }
];

export const processingStatusList = [
  { id: processingStatus.PENDING, label: "PENDING" },
  { id: processingStatus.IN_PROGRESS, label: "IN PROGRESS" },
  { id: processingStatus.SCHEDULED, label: "SCHEDULED" },
  { id: processingStatus.COMPLETED, label: "COMPLETED" }
];

export const reportJobStatusList = [
  { id: reportJobStatus.Pending, label: "Pending" },
  { id: reportJobStatus.Processing, label: "Processing" },
  { id: reportJobStatus.Error, label: "Error" },
  { id: reportJobStatus.Completed, label: "Completed" }
];

export const reportTypesUsage = [
  { id: reportTypes.OneTime, label: "One-Time" },
  { id: reportTypes.ScheduleDelivery, label: "Scheduled" },
  
];

export const usageReportPeriod = {
  Daily: 3,
  Weekly: 1,
  Monthly: 2
}

export const usageReportStatementType = {
  Workspaces: 1,
  Libraries: 2,
  Fields: 3
}

export const usageReportOperatorsType = {
  Equal: "equals",
  NotEqual: "notEquals",
}

export const usageReportPeriodList = [
  { id: usageReportPeriod.Monthly, label: "Monthly" },
  { id: usageReportPeriod.Weekly, label: "Weekly" },
  { id: usageReportPeriod.Daily, label: "Daily" },
  
  
];

export const reportFileStatusA = [
  { id: reportFileStatus.TOBEGENERATED, label: "In progress" },
  { id: reportFileStatus.COMPLETED, label: "Completed" },
  { id: reportFileStatus.ERROR, label: "Error" },
];

export const usageReportStatementTypeList= [
  { id: usageReportStatementType.Workspaces, label: "Workspaces" },
  // { id: usageReportStatementType.Libraries, label: "Libraries" },
  // { id: usageReportStatementType.Fields, label: "Field(s)" },
];

export const usageReportOperatorsTypeList= [
  { id: usageReportOperatorsType.Equal, label: "Is Exactly" },
  // { id: usageReportOperatorsType.NotEqual, label: "Is not Exactly" },
];


export const reportTrackInterestedPartyA = [
  'performer',
  'lyricist',
  'adaptor',
  'administrator',
  'arranger',
  'composer',
  'publisher',
  'originalPublisher',
  'recordLabel',
  'subLyricist',
  'subAdaptor',
  'subArranger',
  'subPublisher',
  'translator'
]

