import {
  PLAY_AUDIO,
  SET_AUDIO,
  PAUSE_AUDIO,
  SET_PLAY_PROGRESS,
  SET_BUFFER_PROGRESS,
  CLEAR_PLAYER,
  MUTE_PLAYER,
  UNMUTE_PLAYER,
  SET_PLAYER_VOLUME,
  LOOP_PLAY,
  UNLOOP_PLAY,
  END_OF_AUDIO,
  AUDIO_ERROR,
  ASSET_LOADING,
  ABUSE_MSG_OPEN,
  ABUSE_MSG_CLOSE,
  SET_PLAY_OBJECT,
  SET_PLAYER_MESSAGE,
  SET_POPOUT_FOR, 
} from "./../Types";

const initialState = {
  playObject: null,
  playableType: null,
  tracks: [],
  track: null,
  tracksLoading: false,
  bufferProgress: 0,
  playProgress: 0,
  bufferTime: 0,
  playTime: 0,
  playDuration: 0,
  playerVolume: 100,
  isPlaying: false,
  isMute: false,
  isLoop: false,
  isBufferCompleted: false,
  isAudioError: false,
  errorMessage: null,
  isAssetLoading: false,
  playbackAudio: null,
  audioPlayer: new Audio(),
  abuseMsg: null,
  snackbar: { snackbarMessage: null },
  popoutFor: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PLAY_AUDIO:
      if (action.payload) {
        if (state?.track?.id !== action.payload.id) {
          return {
            ...state,
            isAudioError: false,
            errorMessage: null,
            track: action.payload,
            isPlaying: true,
            bufferProgress: 0,
            playProgress: 0,
            bufferTime: 0,
            playTime: 0,
            playDuration: 0,
            isAssetLoading: false,
            playbackAudio: action.payload.playbackAudio,
            abuseMsg: action.payload.abuseMsg ?? null,
          };
        } else {
          return {
            ...state,
            track: action.payload,
            isPlaying: true,
            isAssetLoading: false,
          };
        }
      } else {
        return { ...state, isPlaying: true, isAssetLoading: false };
      }

    case SET_AUDIO:
      return {
        ...state,
        isAudioError: false,
        errorMessage: null,
        track: action.payload,
        isPlaying: false,
        bufferProgress: 0,
        playProgress: 0,
        bufferTime: 0,
        playTime: 0,
        playDuration: 0,
        isAssetLoading: false,
        playbackAudio: action.payload.playbackAudio,
        abuseMsg: action.payload.abuseMsg ?? null,
      };

    case ASSET_LOADING:
      return { ...state, isAssetLoading: true };

    case PAUSE_AUDIO:
      return { ...state, isPlaying: false };

    case CLEAR_PLAYER:
      return initialState;

    case SET_PLAY_OBJECT:
      return {
        ...initialState,
        playObject: action.payload.playObject,
        playableType: action.payload.playableType,
        tracks: action.payload.tracks,
        track: action.payload.track,
        tracksLoading: action.payload.tracksLoading,
        popoutFor: state.popoutFor, 
      };

    case SET_PLAY_PROGRESS:
      return {
        ...state,
        playTime: action.payload.playTime,
        playProgress: action.payload.playProgress,

        bufferTime: action.payload?.bufferTime || state.bufferTime,
        bufferProgress: action.payload?.bufferProgress || state.bufferProgress,

        playDuration: action.payload.playDuration,

        isBufferCompleted:
          action.payload.bufferTime === undefined
            ? true
            : action.payload.duration === action.payload.bufferTime,
      };

    case SET_BUFFER_PROGRESS:
      return {
        ...state,
        bufferTime: action.payload.bufferTime,
        bufferProgress: action.payload.bufferProgress,
        playDuration: action.payload.playDuration,
      };

    case SET_POPOUT_FOR:
      return {
        ...state,
        popoutFor: action.payload,
      };

    case SET_PLAYER_VOLUME:
      if (state.playerVolume !== action.payload) {
        return { ...state, isMute: false, playerVolume: action.payload };
      } else {
        return state;
      }

    case MUTE_PLAYER:
      return { ...state, isMute: true };

    case UNMUTE_PLAYER:
      return { ...state, isMute: false };

    case LOOP_PLAY:
      return { ...state, isLoop: true };

    case UNLOOP_PLAY:
      return { ...state, isLoop: false };

    case AUDIO_ERROR:
      return {
        ...state,
        isAudioError: true,
        errorMessage: action.payload,
        isPlaying: false,
      };

    case END_OF_AUDIO:
      if (!state.isLoop) {
        return {
          ...state,
          isPlaying: false,
          bufferProgress: 0,
          playProgress: 0,
          bufferTime: 0,
          playTime: 0,
          playDuration: 0,
        };
      } else {
        return {
          ...state,
          isPlaying: true,
          bufferProgress: 0,
          playProgress: 0,
          bufferTime: 0,
          playTime: 0,
          playDuration: 0,
        };
      }

    case SET_PLAYER_MESSAGE:
      return {
        ...state,
        snackbar: { snackbarMessage: action.payload.message },
      };

    case ABUSE_MSG_OPEN:
        return {
          ...state,
          abuseDialogOpen: true,
        };

    case ABUSE_MSG_CLOSE:
      const st = {
        ...state,
        abuseDialogOpen: false,
      };
      st.track.abuseMsg = null;
      return st;

    default:
      return state;
  }
}
