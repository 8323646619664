import axios from "axios";
import store from "redux/Store";
import { pagingTotalRelation } from "redux/Types";
import { v4 as uuidv4 } from "uuid";
module = "/smlibrary";

var tId = null;




export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,
  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,
  CHECK_STATUS: "CHECK_STATUS" + module,

};

const ML_ADMIN_API = "https://localhost:44335/api/";

export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};

export const setQuery = (query, done = null) => (dispatch) => {
  // console.log("setQuery", query);
  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });

  if (done) done();
};

export const postItem = (action, message, items,id,type, done = null) => (dispatch) => {

  if(id!=null){
    items = [];
    items.push(id);
  }

  let payload = {};
  payload.type = type;
  payload.ids = items;

  const {
    user: {
      credentials: { orgID },      
    },
    smlibraryadmin: { query },
  } = store.getState();  

  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });
 
  axios
  .post(`${orgID}/contentadmin/Workspace/${action}`, payload, {
    headers: { tid: tId, orgid: orgID },
  })    
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
        payload: items
      });  
      
      search(query)(dispatch);

      if (done) done(res.data, null);
      
    })
    .catch((err) => {
    
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err
      }); 

      if (done) done(null, err);
    });  
};

export const updateStatus = (val) => (dispatch) => {
  const param = { dh_status: val };

  //console.log(store.getState());

  const {
    smlibraryadmin: { selectedItems, query },
  } = store.getState();

  axios
    .post(
      `${ML_ADMIN_API}WorkspaceAPI/UpdateBulkStatus?id_list=${selectedItems}`,
      param
    )
    .then((res) => {
      search(query)(dispatch);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });

  //console.log(selectedItems);
};

export const GetElasticTrackCount = (query, done = null) =>  (dispatch) =>{
  tId = uuidv4();
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  query.orgId = orgID;

  axios
  .post(`${orgID}/contentadmin/GetTrackCounts`, query, {
    headers: { tid: tId, orgid: orgID },
  })    
    .then((res) => {    
      if (res.data.tid === tId) {
        done(res.data.counts, null)
      }
    })
    .catch((err) => {
      done(0, null)
    }); 
};



export const pauseContinueWorkspace = (action, message, item, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },      
    },
    smlibraryadmin: { query },
  } = store.getState();  

  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });
 
  axios
  .post(`${orgID}/contentadmin/Workspace/${action}`, item, {
    headers: { tid: tId, orgid: orgID },
  })    
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
        payload: item
      });  
      
      search(query)(dispatch);

      if (done) done(res.data, null);
      
    })
    .catch((err) => {
    
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err
      }); 

      if (done) done(null, err);
    });  
};

export const search = (query) => (dispatch) => {

  tId = uuidv4();
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const options = {
    headers: {
      tid: tId,
    },
  };

  if(!query.orderBy){
    query.orderBy = 'date_last_edited';
  }

  var _apiEndpoint = `${orgID}/contentadmin/WorkspaceSearch`;
  if(query.type == 'lib'){
    _apiEndpoint = `${orgID}/contentadmin/LibrarySearch`; 
    if(query.orderBy == 'library_count'){
        query.orderBy = 'date_last_edited';
    }    
  }

  dispatch({
    type: actionTypes.LOADING,
  });

  axios
      .post(_apiEndpoint, query, options)
      .then((res) => {  
              dispatch({
              type: actionTypes.LOAD,
              payload: res.data.results,
              total: res.data.total,
              totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
              });  
      })
      .catch((err) => {     
      });
};


