import axios from "axios";
import store from "redux/Store";
import { v4 as uuidv4 } from "uuid";
import { validateClearanceWizard } from "../../util/validations/ClearanceWizardValidation";
import { pagingTotalRelation, userPrefType } from "../Types";
import { trimSearchQuery } from "../../util/CommonFunc";
module = "/mlLic/forms/";
export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,
  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,
  RECENT_LOADING: "RECENT_LOADING" + module,
  RECENT_LOAD: "RECENT_LOAD" + module,
  RECENT_ERROR: "RECENT_LOAD" + module,
  RECENT_QUERY: "RECENT_QUERY" + module,
  REFRESH_ITEMS: "REFRESH_ITEMS" + module,
  LAST_REFRESH: "LAST_REFRESH" + module,
  IS_ALLOCATED_OPEN: "IS_ALLOCATED_OPEN" + module,
  LOAD_HISTORY: "LOAD_HISTORY" + module,
  REMOVE_HISTORY: "REMOVE_HISTORY" + module,
  CF_Share: "CF_Share" + module,
  CF_Share_OK: "CF_Share_OK" + module
};

export const editItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.EDIT_ITEM,
    payload: item,
  });
};

export const refreshItems = (items) => (dispatch) => {


 
  dispatch({
    type: actionTypes.REFRESH_ITEMS,
    payload: items,
  });
};

export const setLastRefresh = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.LAST_REFRESH,
    payload: items,
  });
};

export const setIsAllocatedSectionOpen = (state) => (dispatch) => {
  dispatch({
    type: actionTypes.IS_ALLOCATED_OPEN,
    payload: state,
  });
};

export const removeHistory = (cfId) => (dispatch) => {
  dispatch({
    type: actionTypes.REMOVE_HISTORY,
    payload: cfId,
  });
};

export const showAutoMessage = (message) => (dispatch) => {
  dispatch({
    type: actionTypes.CF_Share,
    payload: message,
  });
  setTimeout(() => {
    dispatch({
      type: actionTypes.CF_Share_OK,
    });
  }, 3000);
};


export const getFormHistory = (cfId, done = null) => (dispatch) => {
  //   if (done) done({}, null);
  // }
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  // dispatch({
  //   type: actionTypes.POSTING,
  //   payload: message,
  // });

  axios
    .post(`${orgID}/mllic/forms/searchHistory`, { cfId: cfId }, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      //if (res.data.tid === tId) {
      dispatch({
        type: actionTypes.LOAD_HISTORY,
        payload: {
          cfId:cfId,
          history:res.data
        }
      });

      if (done) done(res.data, null);
      //}
    })
    .catch((err) => {
      // dispatch({
      //   type: actionTypes.POST_ERROR,
      //   // payload: { e: err },
      //   payload: err.response?.data || { e: (err.message ? err.message : "Error in creating or updating. please retry") },
      // });

      // if (done) done(null, err.response?.data?.name == 'Duplicate Name'?err.response?.data?.name:err.message || "error");
    });
};

export const postItem = (action, message, items, done = null) => (dispatch) => {

  if (action === "saveform" && items.cf) {
    let errors = validateClearanceWizard(items.cf);
    if (errors) {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: errors
      });
      return;
    }
  }

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/mllic/forms/${action}`, items, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      //if (res.data.tid === tId) {
      dispatch({
        type: actionTypes.POST_OK,
      });

      if (done) done(res.data, null);
      //}
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        // payload: { e: err },
        payload: err.response?.data || { e: (err.message ? err.message : "Error in creating or updating. please retry") },
      });

      if (done) done(null, err.response?.data?.name == 'Duplicate Name'?err.response?.data?.name:err.message || "error");
    });
};

export const setQuery = (query, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });

  if (done) done();
};

export const setRecentCFQuery = (query, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.RECENT_QUERY,
    payload: query,
  });

  if (done) done();
};

export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};
export const search = (query) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const tId = uuidv4();
  dispatch({
    type: actionTypes.LOADING,
  });

  axios
    .post(`${orgID}/mllic/forms/search`, trimSearchQuery(query), {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.LOAD,
          payload: res.data.results,
          total: res.data.total,
          totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err.message || "error" },
      });
    });
};

export const searchCFRecent = (query) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const tId = uuidv4();
  dispatch({
    type: actionTypes.RECENT_LOADING,
  });

  axios
    .post(`${orgID}/mllic/forms/searchRecent`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.RECENT_LOAD,
          payload: res.data.results,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.RECENT_ERROR,
        payload: { e: err.message || "error" },
      });
    });
};

export const updateSearchUserPref = (pref, message, done = null) => (
  dispatch
) => {
  const {
    user: { id },
  } = store.getState();
  const tId = uuidv4();
  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(
      `${id}/userpref/update`,
      { type: userPrefType.CLERANCEFORMSPREF, pref: pref },
      {
        headers: { tid: tId },
      }
    )
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};
