import axios from "axios";
import store from "redux/Store";
import { pagingTotalRelation } from "redux/Types";
import { v4 as uuidv4 } from "uuid";
import { ValidateSetting, ValidateCondition } from "../../util/validations/MlAdminAbuseActionsValidation";
module = "/mlAdmin/abuse/";
export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,
  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  SAVE_OK: "SAVE_OK" + module,
  POST_ERROR: "POST_ERROR" + module,
  SET_ALL_RULES: "SET_ALL_RULES" + module,
  SET_ERROR_LOADING_ALL_RULES: "SET_ERROR_LOADING_ALL_RULES" + module,
};

export const editItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.EDIT_ITEM,
    payload: item,
  });
};

export const addCondition = (item) => (dispatch) => {

  if (item) {
    let errors = ValidateCondition(item);
    
    if (errors) {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: errors
      });
      return;
    }

    dispatch({
      type: actionTypes.POSTING,
      payload: "",
    });

    item.subrules.push({
      limit: item.limit,
      activity: item.activity,
      period: item.period,
      unit: item.unit
    });
    item.limit = "";
    item.period = "";
    editItem(item);
   
    dispatch({
      type: actionTypes.POST_OK,
    });
  }
}

export const postItem = (action, message, items, done = null) => (dispatch) => {

  if (items.rule) {
    let errors = ValidateSetting(items.rule);
    if (errors) {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: errors
      });
      return;
    }
  }

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/mladmin/abuse/${action}`, items, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      //if (res.data.tid === tId) {

      dispatch({
        type: (action == 'saverule') ? actionTypes.SAVE_OK : actionTypes.POST_OK,
        payload: res.data
      });

      if (done) done(res.data, null);
      //}
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        // payload: { e: err },
        payload: err.response?.data || { e: (err.message ? err.message : "Error in creating or updating. Please retry") },
      });

      if (done) done(null, err);
    });
};

export const setQuery = (query, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });

  if (done) done();
};

export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};

export const search = (query) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const tId = uuidv4();
  dispatch({
    type: actionTypes.LOADING,
  });

  axios
    .post(`${orgID}/mladmin/abuse/search`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.LOAD,
          payload: res.data.results,
          total: res.data.total,
          totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });
};


export const getAllRules = (query) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  axios
    .post(`${orgID}/mladmin/abuse/searchAllRules`, query)
    .then((response) => {
      dispatch({
        type: actionTypes.SET_ALL_RULES,
        allrules: response.data,
      });
      
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.SET_ERROR_LOADING_ALL_RULES,
        allrules: [],
        errors: error
      });
    });
   
};