import React, { Component } from "react";
//import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";

class PageTitle extends Component {
  render() {
    const { pageTitle, siteTitle } = this.props;
    return (
      <Helmet>
        {/* <title>{pageTitle ? pageTitle + " : " + siteTitle : siteTitle}</title> */}
        <title>{pageTitle}</title>
      </Helmet>
    );
  }
}
PageTitle.defaultProps = {
  pageTitle: null,
  siteTitle: "MusicManager",
};

export default PageTitle;
