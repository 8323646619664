import {
  T_LeafBool,
  T_LeafOptionsMulti,
  T_LeafOptionsMultiOrdered,
  T_Major,
  T_LeafOptionsSingle,
} from "./ModulePropTypes";
import { PT_Org } from "./ModulePermitTypes";
import {
  _settingSearchAllFields,
  _settingSearchAllTagTypes,
} from "./SettingMusicSearch";
import getOrgSettings from "../../util/sys-calls/getOrgSettings";
import orgSettingType from "./orgSettingType";

export const _librarySearchHistoryOptions = {
  none: {
    id: "none",
    name: "No Search History",
  },
  own: {
    id: "own",
    name: "Own Search History",
  },
  all: {
    id: "all",
    name: "All Search History",
  },
};

export const _librarySearchHistory = {
  id: "ls3",
  permitType: PT_Org,
  type: T_LeafOptionsSingle,
  options: _librarySearchHistoryOptions,
  default: _librarySearchHistoryOptions.none.id,
};

export const resultViews = {
  single: { id: "single" },
  multi: { id: "multi" },
};

export const searchProfiles = {
  mainSearch: { id: "mainSearch" },
  tagSearch: { id: "tagSearch" },
};

export const timeSorts = {
  mostRelevantFirst: { id: "mostRelevantFirst" },
  defaultOrder: { id: "defaultOrder" },
  newestFirst: { id: "newestFirst" },
  oldestFirst: { id: "oldestFirst" },
};

export const viewerTypes = {
  listView: { id: "listView" },
  cardView: { id: "cardView" },
};

export const _libraryResultView = {
  id: "ls6",
  permitType: PT_Org,
  type: T_LeafOptionsMultiOrdered,
  options: resultViews,
  default: [resultViews.single.id, resultViews.multi.id],
};

export const _libraryAdvSearchType = {
  id: "ls7",
  permitType: PT_Org,
  type: T_LeafOptionsMultiOrdered,
  sysCall: getOrgSettings,
  sysCallParam: orgSettingType.ssearch.id,
  default: ["1", "2", "3"],
};

export const _libraryRankingType = {
  id: "ls8",
  permitType: PT_Org,
  type: T_LeafOptionsSingle,
  sysCall: getOrgSettings,
  sysCallParam: orgSettingType.trackranking.id,
  default: "9",
  removable:true
};

export const _libraryExcludeRestricted = {
  id: "ls9",
  permitType: PT_Org,
  type: T_LeafBool,
  default: false,
};
  
export const _mlLibraries = {
  id: "mlLibraries",
  permitType: PT_Org,
  type: T_Major,
  modules: [
    _librarySearchHistory,
    _libraryResultView,
    _libraryAdvSearchType,
    _libraryRankingType,
    _libraryExcludeRestricted,
  ],
};
