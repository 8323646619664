import trackFields from "_module/definition/trackFields"

export const  MlPrsSearchButtonType ={
    BUTTON :1,
    MENU_ITEM: 2
}

export const prsUpdateFields = [
    {key: trackFields.prsWorkTunecode.id , resultKey : "workTunecode"}, 
    {key: trackFields.prsSearchDateTime.id , resultKey : "dateTime"}, 
    {key: trackFields.prsWorkPublishers.id , resultKey : "workPublishers"}, 
    {key: trackFields.prsWorkWriters.id , resultKey : "workWriters"}, 
    {key: trackFields.prsWorkTitle.id , resultKey : "workTitle"}, 
    {key: trackFields.cTagMcpsOwner.id , resultKey : "cTagMcpsOwner"}, 
    {key: trackFields.cTagNorthAmerican.id , resultKey : "cTagNorthAmerican"}, 
]

export const prsSearchRefreshDataType = {
    TRACK : 1,
    ALBUM_TRACKS : 2,
    PLAYLIST_TRACKS: 3
}