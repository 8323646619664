// ctag1, ctag3 - grp1
// ctag2 - 4,5,6 - grp2
// ctag7 - grp3

// This type is also in CentralAPI, keep consistent if you modify it
export default {
  cTag1: { id: "cTag1", apiid: 1, name: "BBC Restricted artists", group: 1 },
  cTag3: { id: "cTag3", apiid: 3, name: "Is the work 100% controlled by MCPS or in the public domain", group: 3 },
  cTag2: {
    id: "cTag2",
    apiid: 2,
    name: "BBC Prior approvals artists",
    group: 3,
  },

  cTag4: {
    id: "cTag4",
    apiid: 4,
    name: "Does the recording have full MCPS ownership",
    group: 3,
  },
  cTag5: {
    id: "cTag5",
    apiid: 5,
    name: "Does it have any MCPS music",
    group: 3,
  },
  cTag6: {
    id: "cTag6",
    apiid: 6,
    name: "Does the music contain North American copyright",
    group: 3,
  },
  cTag7: {
    id: "cTag7",
    apiid: 7,
    name: "Is the recording PPL Registered",
    group: 2,
  },
};

/*
cTag1 -  BBC_RESTRICTED_ARTIST (Restricted artists)
cTag2 - PRS_PRIOR_APPROVAL_CODE (Prior approvals artists)
cTag3 - EMI_PUBLISHER (Publisher contains emi)
cTag4 - PRS_MCPS_OWNERSHIP (Does the recording have full MCPS ownership)
cTag5 - MCPS_MUSIC (Does it have any MCPS music)
cTag6 - NORTH_AMERICAN_COPYRIGHT (Does the music contain North American copyright)
cTag7 - PPL_LABEL (Is the recording PPL Registered)
*/
