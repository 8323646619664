import React, { Component } from "react";
import { Translation } from "react-i18next";
import i18n from "i18next";
import { getPlaylist } from "../../../redux/actions/MlPlaylistContentActions";
import { styles } from "./Styles.css";
import {
  withStyles,
  Snackbar,
  Button,
  IconButton,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";
import { getDownloadLinkByResolution } from "../../../redux/actions/DownloadActions";
import axios from "axios";
import ConcurrentTaskQueue from "../../../util/ConcurrentTaskQueue";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableDataCol from "./../../../util/TableDataCol";
import Columns from "./Columns";
import trackFields from "../../../_module/definition/trackFields";
import InfoIcon from "@material-ui/icons/Info";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import PageTitle from "./../../../util/PageTitle";
import { Checkbox, MenuItem, Select } from "@material-ui/core";
import { BiDownload } from "react-icons/bi";
import clsx from "clsx";
import { PlaylistItemTypes } from "../../ml-music-search/track/RecentPlaylistMenu";
import Confirm from "../../../util/Confirm";
import { activityLimitActions } from "../../../util/UserActivityLimit";
import { ruleOutcomeTypes } from "../../../redux/Types";
import { getTracks } from "./../../../redux/actions/MlTrackLibraryActions";
import { FilterOperatorTypes } from "../../../util/table-view/TableTypes";
import { keepAlive } from "./../../../util/SysCalls";
import { _PermittedContent } from "_module/utils/PermittedContent";
import {
  _canDownloadHighres,
  _canDownloadMp3,
  _mlPlayer,
} from "_module/definition/MlPlayer";
import JsZip from "jszip";
import { saveAs } from "file-saver";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  getAlbumById,
  getAlbumContent,
} from "redux/actions/MlAlbumTrackActions";

import { Beforeunload } from "react-beforeunload";
import CloseIcon from "@material-ui/icons/Close";

export const DownloadTypes = {
  playlist: "pl",
  album: "ab",
  tracks: "tk",
};

export const DownloadResolutions = {
  low: "l",
  high: "h",
};

export const CompleteStates = {
  pending: "pending",
  progress: "progress",
  completed: "completed",
  error: "error",
};

export const DownloadCompressions = {
  compressed: "c",
  uncompressed: "u",
};

export const OpenPlaylistDownloader = (playlist) => {
  const width = Math.min(window.screen.width, 800);
  const height = Math.min(window.screen.height, 600);
  const left = (window.screen.width - width) / 2;
  const top = (window.screen.height - height) / 3;
  window.open(
    `${process.env.PUBLIC_URL}/downloader/${encodeURI(
      JSON.stringify({ type: DownloadTypes.playlist, id: playlist.id })
    )}`,
    "_blank",
    `toolbar=0,location=0,menubar=0,statusbar=0,directories=0,status=0,scrollbars=0,copyhistory=0,width=${width},height=${height},top=${top},left=${left}`
  );
};

export const OpenAlbumDownloader = (album) => {
  const width = Math.min(window.screen.width, 800);
  const height = Math.min(window.screen.height, 600);
  const left = (window.screen.width - width) / 2;
  const top = (window.screen.height - height) / 3;
  window.open(
    `${process.env.PUBLIC_URL}/downloader/${encodeURI(
      JSON.stringify({
        type: DownloadTypes.album, id: album.prodId,
        ...(album.searchId && { lsi: album.searchId })
      })
    )}`,
    "_blank",
    `toolbar=0,location=0,menubar=0,statusbar=0,directories=0,status=0,scrollbars=0,copyhistory=0,width=${width},height=${height},top=${top},left=${left}`
  );
};

export const OpenTracksDownloader = (trackIds, searchId = null, cfId = null) => {
  const width = Math.min(window.screen.width, 800);
  const height = Math.min(window.screen.height, 600);
  const left = (window.screen.width - width) / 2;
  const top = (window.screen.height - height) / 3;
  window.open(
    `${process.env.PUBLIC_URL}/downloader/${encodeURI(cfId ?
      JSON.stringify({
        cfId: cfId,
        type: DownloadTypes.tracks, tracks: trackIds,
        ...(searchId && { lsi: searchId })
      })
      :
      JSON.stringify({
        type: DownloadTypes.tracks, tracks: trackIds,
        ...(searchId && { lsi: searchId })
      })
    )}`,
    "_blank",
    `toolbar=0,location=0,menubar=0,statusbar=0,directories=0,status=0,scrollbars=0,copyhistory=0,width=${width},height=${height},top=${top},left=${left}`
  );
};

class Downloader extends Component {
  constructor(props) {
    super();
    this.state = {
      title: null,
      desc: null,
      status: null,
      tracks: [],
      selectedIds: [],
      completedTracks: [],
      errorTracks: [],
      type: null,
      zipsize: 0,
      resolution: DownloadResolutions.high,
      compression: DownloadCompressions.uncompressed,
      gridColumns: 5,
      concurrentDownloads: 1,
      downloadable: false,
      downloadReady: false,
      downloadInProgress: false,
      allSelected: false,
      messageOpen: false,
      abuseMsg: null,
      abuseFlag: null,
      canDownloadLoRes: false,
      canDownloadHiRes: false,
      downloadAndZip: true,
      zipFileName: "Multiple Tracks",
      completeMessageShowing: false,
      savedZipFiles: [],
      cfId: null
    };
  }

  columns = null;
  downloadingTracks = [];

  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    let canDownloadLoRes =
      _PermittedContent([_mlPlayer, _canDownloadMp3], []) == "none"
        ? false
        : true;
    let canDownloadHiRes =
      _PermittedContent([_mlPlayer, _canDownloadHighres], []) == "none"
        ? false
        : true;

    this.setState({
      canDownloadLoRes,
      canDownloadHiRes,
      resolution: canDownloadHiRes
        ? DownloadResolutions.high
        : DownloadResolutions.low,
    });

    const request = JSON.parse(params.handle);

    this.setState({
      status: "Searching downloads...",
      type: request.type,
    });
    if (request.type === DownloadTypes.tracks) {
      this.setState({
        title: "Download Multiple Tracks",
        zipFileName: "multiple tracks",
        desc: `Download Tracks`,
      });

      if (request.cfId) {
        this.setState({ cfId: request.cfId })
      }

      if (request?.tracks?.length > 0) {
        getTracks({
          page: 0,
          size: 100,
          filters: [
            {
              value: request.tracks,
              operator: FilterOperatorTypes.equals,
              field: "id",
            },
          ],
        }, true).then((response) => {
          if (response.length > 0) {
            this.initializeDownloadObject(response);
            let tracks = response;
            if (tracks.length === 0) {
              this.setState({
                status: "No tracks found...",
                tracks: response,
              });
            } else {
              if (request.lsi)
                tracks.forEach(e => e.searchId = request.lsi);
              this.setState(
                {
                  status: `${tracks.length} ${tracks.length > 1 ? "tracks" : "track"
                    } found`,
                  tracks: tracks,
                  downloadable: true,
                },
                () => {
                  this.switchSelectAll(true);
                }
              );
            }
          } else {
            this.setState({
              status: "No tracks found...",
              tracks: response,
            });
          }
        });
      } else {
        this.setState({
          status: "No tracks found...",
          tracks: [],
        });
      }
    } else if (request.type === DownloadTypes.album) {
      getAlbumById(request.id).then((response) => {
        if (response.length > 0) {
          const album = response[0];
          this.setState({
            title: album.prodName
              ? `${album.prodName}: downloads`
              : "Downloads",
            zipFileName: album.prodName ?? "untitled album",
            desc: `${album.prodName}`,
          });
        }
      });

      getAlbumContent(request.id).then((response) => {
        if (response.length > 0) {
          this.initializeDownloadObject(response);
          let tracks = response;
          if (tracks.length === 0) {
            this.setState({
              status: "No tracks found...",
              tracks: response,
            });
          } else {
            if (request.lsi)
              tracks.forEach(e => e.searchId = request.lsi);
            this.setState(
              {
                status: `${tracks.length} ${tracks.length > 1 ? "tracks" : "track"
                  } found`,
                tracks: tracks,
                downloadable: true,
              },
              () => {
                this.switchSelectAll(true);
              }
            );
          }
        } else {
          this.setState({
            status: "No tracks found...",
            tracks: response,
          });
        }
      });
    } else if (request.type === DownloadTypes.playlist) {
      getPlaylist(request.id).then((response) => {
        if (response.length > 0) {
          const playlist = response[0];
          this.setState({
            title: playlist.name ? `${playlist.name}: downloads` : "Downloads",
            zipFileName: playlist.name ?? "untitled playlist",
            desc: `${playlist.name}`,
          });

          var playlistTracks = playlist.items
            ?.filter((r) => r.id && r.type === PlaylistItemTypes.track)
            .map((r) => r.id) ?? [];

          getTracks({
            page: 0,
            size: 10000,
            filters: [
              {
                value: playlistTracks,
                operator: FilterOperatorTypes.equals,
                field: "id",
              },
            ],
          }, true).then((response) => {
            if (response.length > 0) {
              this.initializeDownloadObject(response);
              let tracks = playlistTracks.map(p => response.find(r => r.id === p)).filter(r => r);
              if (tracks.length === 0) {
                this.setState({
                  status: "No tracks found...",
                  tracks: response,
                });
              } else {
                this.setState(
                  {
                    status: `${tracks.length} ${tracks.length > 1 ? "tracks" : "track"
                      } found`,
                    tracks: tracks,
                    downloadable: true,
                  },
                  () => {
                    this.switchSelectAll(true);
                  }
                );
              }
            } else {
              this.setState({
                status: "No tracks found...",
                tracks: response,
              });
            }
          });
        } else {
          this.setState({
            status: "No playlist found...",
            tracks: [],
          });
        }
      });
    }

    setInterval(() => {
      if (this.state.downloadInProgress) {
        keepAlive();
      }
    }, 120000);
  }

  showMessage = (message, duration) => {
    this.setState({
      messageOpen: true,
      message: message,
    });
    setTimeout(() => {
      this.setState({
        messageOpen: false,
        message: "",
      });
    }, duration);
  };

  getSelectedTracks = () => {
    return (
      this.state.tracks?.filter((r) => this.state.selectedIds.includes(r.id)) ??
      []
    );
  };

  initializeDownloadObject = (tracks) => {
    tracks.forEach(
      (r) =>
      (r.download = {
        completeState: CompleteStates.pending,
        progress: 0,
        hiResSize: r?.assets?.find(t => t.quality && t.size > 0)?.size ?? 0,
        loResSize: r?.assets?.find(t => !t.quality && t.size > 0)?.size ?? 0,
        downloadSize: 0,
        assetStatus: `Download pending...`,
      })
    );
  };

  getPossibleBlobSize = async () => {
    const { zipsize } = this.state;
    const platform = require("platform");
    const totalMemory = navigator.deviceMemory * 1024 * 1024 * 1024;
    let storage = await navigator.storage.estimate();

    console.group("Download Diagnostic Iformation:");
    console.log("storage.quota: ", `${this.formatBytes(storage.quota)}`);
    console.log("performance.memory: ", performance.memory);
    console.log("zipsize: ", zipsize);
    console.log("totalMemory:", totalMemory);
    console.log("navigator.deviceMemory: ", navigator.deviceMemory);
    console.log("navigator.userAgent: ", navigator.userAgent);
    console.log("platform.name: ", platform.name);
    console.log("platform.version: ", platform.version);
    console.log("platform.layout: ", platform.layout);
    console.log("platform.os: ", platform.os);
    console.log("platform.product: ", platform.product);
    console.log("platform.manufacturer: ", platform.manufacturer);
    console.log("platform.description: ", platform.description);

    const conditions = [
      { regex: /firefox|chrome|opera|edge|safari|ie/i, size: /mobile/i.test(platform.name) ? totalMemory / 5 : 1024 * 1024 * 500 },
    ];

    let blobSize = conditions.find(condition => condition.regex.test(platform.name))?.size || (1024 * 1024 * 500);
    if (blobSize > 1024 * 1024 * 500) {
      blobSize = 1024 * 1024 * 500
    }
    console.log("blobSize: ", ` (${this.formatBytes(blobSize)})`);
    console.groupEnd();

    return blobSize;
  };

  // tryCreateBlob = (blobSize) => {
  //   try {
  //     let blob = new Blob([new Uint8Array(blobSize)]);
  //     console.log("Blob creation successful");
  //     return true;
  //   } catch (error) {
  //     console.log("Blob creation failed with size", blobSize);
  //     return false;
  //   }
  // }

  startDownload = () =>
    new Promise(async (resolve) => {
      this.downloadingTracks = this.getSelectedTracks();
      if (this.state.downloadAndZip) {
        this.zip = new JsZip();
        this.blobSize = await this.getPossibleBlobSize();
        this.currentChunkSize = 0;
        this.currentChunk = 0;
      }

      if (this.downloadingTracks.length === 0) {
        this.showMessage("No selected tracks found...", 5000);
        resolve([]);
        return;
      }

      this.setState({
        status: `0 of ${this.downloadingTracks.length} processed`,
        savedZipFiles: []
      });
      const taskQueue = new ConcurrentTaskQueue(
        this.downloadFunctions(this.downloadingTracks),
        this.state.concurrentDownloads
      );
      taskQueue.runTasks().then((response) => {
        resolve(response);
      });
    });

  sleep = (timeout) => {
    return new Promise((resolve) => setTimeout(resolve, timeout));
  };

  downloadCompleteMessageClose = () => {
    this.setState({
      completeMessageShowing: false,
      savedZipFiles: []
    })
  }

  downloadFunctions = (tracks) => {
    const instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.withCredentials = false;
    instance.defaults.headers["Access-Control-Allow-Origin"] = "*";
    instance.defaults.headers["Access-Control-Allow-Methods"] =
      "GET,PUT,POST,DELETE,PATCH,OPTIONS";

    return tracks.map((track, index) => {
      return () =>
        new Promise(async (resolve, reject) => {
          if (
            this.state.abuseFlag &&
            this.state.abuseFlag != ruleOutcomeTypes.WARN
          )
            return resolve(true);

          try {
            const assetResponse = await getDownloadLinkByResolution(
              track.id,
              this.state.resolution,
              track.searchId,
              this.state.cfId
            );

            if (assetResponse.abuseFlag)
              this.setState({
                abuseMsg: assetResponse.abuseMsg,
                abuseFlag: assetResponse.abuseFlag,
              });

            if (assetResponse.url && assetResponse.resolution) {
              track.download = {
                ...assetResponse,
                completeState: CompleteStates.progress,
                progress: 0,
                assetStatus: `${i18n.t(
                  `downloader.res_${assetResponse.resolution}`
                )} download available`,
                seq: ++index
              };
              this.forceUpdate();
              try {
                let retries = 0;
                let downloadResponse = null;

                while (true) {
                  try {
                    downloadResponse = await instance.get(assetResponse.url, {
                      responseType: "arraybuffer",
                      onDownloadProgress: (progressEvent) => {
                        try {
                          const progress = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                          );
                          if (track.download.progress !== progress) {
                            track.download.progress = progress;
                            this.forceUpdate();
                          }
                        } catch (error) {
                          console.log(
                            "Error while updating the progress: ",
                            error
                          );
                        }
                      },
                    });
                    break;
                  } catch (error) {
                    console.log("Error while requesting the download: ", error);
                    if (++retries > 3) {
                      break;
                    }
                    await this.sleep(retries * 2000);
                  }
                }

                if (!downloadResponse?.data) {
                  throw new Error("Download response data not found");
                }

                track.download.downloadSize = downloadResponse.data.byteLength;

                if (this.state.downloadAndZip) {
                  this.completeState(
                    resolve,
                    CompleteStates.completed,
                    track,
                    `Download completed | Filename: ${track.download.filename}`,
                    this.state.downloadAndZip,
                    downloadResponse.data
                  );
                } else {
                  let url = window.URL.createObjectURL(
                    new Blob([downloadResponse.data], {})
                  );

                  var link = document.createElement("a");
                  document.body.appendChild(link);
                  link.target = "_blank";
                  link.rel = "noopener noreferrer";
                  link.style = "display: none";
                  link.href = url;
                  link.download = track.download.filename;
                  link.click();
                  window.URL.revokeObjectURL(url);
                  link.remove();

                  this.completeState(
                    resolve,
                    CompleteStates.completed,
                    track,
                    `Download filename: ${track.download.filename}`,
                    this.state.downloadAndZip
                  );
                }
              } catch (error) {
                console.log(error);
                this.completeState(
                  resolve,
                  CompleteStates.error,
                  track,
                  "Error while downloading the asset",
                  this.state.downloadAndZip
                );
              }
            } else {
              this.completeState(
                resolve,
                CompleteStates.error,
                track,
                "Downloadable asset not found",
                this.state.downloadAndZip
              );
            }
          } catch {
            this.completeState(
              resolve,
              CompleteStates.error,
              track,
              "Error while requesting the asset",
              this.state.downloadAndZip
            );
          }
        });
    });
  };

  formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  switchSelect = (row, checked) => {
    if (this.state.downloadInProgress) return;
    if (row.id) {
      const existingIndex = this.state.selectedIds.indexOf(row.id);
      if (checked) {
        if (existingIndex === -1) {
          this.state.selectedIds.push(row.id);
          this.setState({
            selectedIds: this.state.selectedIds,
          });
        }
      } else {
        if (existingIndex > -1) {
          this.setState({
            selectedIds: this.state.selectedIds.filter((t) => t !== row.id),
            allSelected: false,
          });
        }
      }
    }
  };

  switchSelectAll = (checked) => {
    if (this.state.downloadInProgress) return;
    let selectedIds = [];
    if (this.state.tracks && this.state.tracks.length > 0) {
      if (checked) {
        selectedIds = this.state.tracks.map((r) => r.id);
      }
    }
    this.setState({
      allSelected: checked,
      selectedIds: selectedIds,
    });
  };

  padWithLeadingZeros = (num, size) => {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  };

  zip = null;
  blobSize = null;
  currentChunk = 0
  currentChunkSize = 0;

  completeState = (
    resolve,
    completeState,
    track,
    assetStatus,
    isZippedOutput,
    downloadResponseData = null
  ) => {
    if (!track.download) track.download = {};
    track.download.assetStatus = assetStatus;
    track.download.completeState = completeState;

    const errors = this.downloadingTracks?.filter(
      (r) => r.download && r.download.completeState === CompleteStates.error
    )?.length;

    const processed = this.downloadingTracks?.filter(
      (r) =>
        r.download &&
        r.download.completeState !== CompleteStates.progress &&
        r.download.completeState !== CompleteStates.pending
    )?.length;

    const processing = this.downloadingTracks?.filter(
      (r) =>
        r.download &&
        (r.download.completeState === CompleteStates.progress ||
          r.download.completeState === CompleteStates.pending)
    )?.length;

    this.setState({
      status: `${processed} of ${this.state.selectedIds.length} processed ${errors && errors === 1 ? `(1 error)` : `(${errors} errors)`
        }`,
    });

    resolve(track);

    if (isZippedOutput) {
      if (downloadResponseData) {
        if (this.currentChunkSize > 0 && (this.blobSize < this.currentChunkSize + downloadResponseData.byteLength)) {
          let filename = this.getZipname(true);
          this.saveZip(filename);
          this.setState({ savedZipFiles: [...this.state.savedZipFiles, { filename, size: this.currentChunkSize }] });
          this.zip = new JsZip();
          this.currentChunkSize = 0;
        }
        this.currentChunkSize += downloadResponseData.byteLength;
        let padLength = this.downloadingTracks.length < 10 ? 1 : (this.downloadingTracks.length < 100 ? 2 : (this.downloadingTracks.length < 1000 ? 3 : 5));
        this.zip.file(
          `${this.padWithLeadingZeros(track.download.seq, padLength)}-${track.download.filename}`,
          downloadResponseData
        );
        console.log("Track added to zip archive:", track.download.filename, "Size: ", this.formatBytes(downloadResponseData.byteLength));
      }

      if (processing === 0) {
        let filename = this.getZipname(this.currentChunk);
        if (this.currentChunkSize > 0) {
          this.saveZip(filename);
          this.setState({ savedZipFiles: [...this.state.savedZipFiles, { filename, size: this.currentChunkSize }] });
        }
        if (this.state.savedZipFiles.length > 0) {
          this.setState({ completeMessageShowing: true });
        }
        this.currentChunkSize = 0;
        this.currentChunk = 0;
        this.zip = null;
      }
    }
  };

  downloadClick = () => {
    this.setState({ downloadInProgress: true });
    this.initializeDownloadObject(this.state.tracks);
    this.startDownload().then((response) =>
      this.setState({ downloadInProgress: false })
    );
  };

  handleCloseAbuseMsg = () => {
    this.setState({ abuseMsg: null });
    activityLimitActions(this.state.abuseFlag);
  };

  getZipname = (isChunked) => {
    return isChunked ? `${this.state.zipFileName}_(set_${this.padWithLeadingZeros(++this.currentChunk, 3)}).zip` : `${this.state.zipFileName}.zip`;
  }

  saveZip(filename) {
    this.zip.generateAsync({
      type: "blob",
      compression: "DEFLATE",
      streamFiles: true,
      compressionOptions: {
        level: 6
      }
    }).then((content) => {
      saveAs(content, filename);
    });
  }

  render() {
    const { classes } = this.props;
    const { canDownloadHiRes, canDownloadLoRes, resolution } = this.state;
    return (
      <Translation>
        {(t, { i18n }) => {
          if (!this.columns) {
            this.columns = [
              "player",
              "prodArtworkUrl",
              trackFields.trackTitle.id,
              trackFields.performer.id,
              trackFields.isrc.id,
              trackFields.prodName.id,
              trackFields.catNo.id,
            ].map((v) => {
              let label =
                !trackFields[v] || trackFields[v]?.sys
                  ? ""
                  : i18n.t(`modules.${v}`);
              return {
                ...(Columns[v] || {}),
                field: v,
                type: Columns[v].type,
                label,
              };
            });
          }

          const zipsizes = [0, 128, 256, 512, 1024];
          const resolutions = [];

          if (canDownloadLoRes) {
            resolutions.push(DownloadResolutions.low);
          }

          if (canDownloadHiRes) {
            resolutions.push(DownloadResolutions.high);
          }

          const compressions = [
            DownloadCompressions.uncompressed,
          ];

          return (
            <>
              <PageTitle pageTitle={this.state.title} />
              {this.state.downloadInProgress && (
                <Beforeunload
                  onBeforeunload={(event) => event.preventDefault()}
                />
              )}
              <Confirm
                name="abuseDialog"
                classes
                isOpen={this.state.abuseMsg ? true : false}
                title="Warning"
                innerHtmlContent={this.state.abuseMsg}
                confirm="Ok"
                onConfirm={() => this.handleCloseAbuseMsg()}
              />

              <div className={classes.grow}>
                <Snackbar
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  open={this.state.messageOpen}
                  message={this.state.message}
                  className={classes.snackbar}
                />
                {this.state.completeMessageShowing &&
                  <Dialog
                    open={this.state.completeMessageShowing}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={classes.dialogWrapper}
                    class="dialogBox">
                    <DialogTitle id="alert-dialog-title" className={classes.popupHeader}>Download Completed

                    </DialogTitle>
                    <DialogContent>
                      <DialogContent><p className={classes.popupMessage}>Kindly inspect your download folder for the following <span>{this.state.savedZipFiles?.length > 1 ? "files" : "file"}:</span> </p></DialogContent>
                      {
                        this.state.savedZipFiles?.map((file) => <DialogContentText>{file.filename} ({this.formatBytes(file.size)})</DialogContentText>)
                      }
                    </DialogContent>
                    <DialogActions>
                      <Button
                        className={classes.dialogNoButton}
                        onClick={this.downloadCompleteMessageClose}
                        color="primary"
                        autoFocus>
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>}

                <AppBar position="static">
                  <Toolbar className={classes.downnloadHeader}>
                    <div className={classes.title}>
                      <Typography variant="h6" noWrap>
                        {this.state.desc && this.state.desc}
                      </Typography>
                      <Typography variant="caption" noWrap>
                        {this.state.status && this.state.status}
                      </Typography>
                    </div>
                    <div className={classes.grow} />
                    <div className={classes.section}>
                      <FormControlLabel
                        className={classes.downloadLabel}
                        control={
                          <Switch
                            disabled={this.state.downloadInProgress}
                            checked={this.state.downloadAndZip}
                            onChange={(e) => {
                              this.setState({
                                downloadAndZip: e.target.checked,
                              });
                            }}
                            style={{
                              transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }],
                            }}
                            name="downloadAndZip"
                            inputProps={{ "aria-label": "Download and Zip" }}
                          />
                        }
                        label="Download and Zip"
                        labelPlacement="end" />

                      {resolutions && resolutions.length > 0 ? (
                        <>
                          {/* <Select
                            id="zipsize"
                            name="zipsize"
                            disabled={this.state.downloadInProgress}
                            disableUnderline
                            value={this.state.zipsize}
                            onChange={(event) => {
                              if (this.state.downloadInProgress) return;
                              this.setState({ zipsize: event.target.value });
                            }}
                            inputProps={{
                              id: "zipsize",
                              "aria-labelledby": "zipsize",
                            }}
                            className={classes.appbarSelect} >
                            {zipsizes.map((opt) => {
                              return (
                                <MenuItem
                                  className={classes.selectOptions}
                                  value={opt}
                                >
                                  {opt ? `Size: ${opt} MB` : "Size: Auto"}
                                </MenuItem>
                              );
                            })}
                          </Select> */}

                          <Select
                            id="resolution"
                            name="resolution"
                            disabled={this.state.downloadInProgress}
                            disableUnderline
                            value={this.state.resolution}
                            onChange={(event) => {
                              if (this.state.downloadInProgress) return;
                              this.setState({ resolution: event.target.value });
                            }}
                            inputProps={{
                              id: "resolution",
                              "aria-labelledby": "resolution",
                            }}
                            className={classes.appbarSelect} >
                            {resolutions.map((opt) => {
                              return (
                                <MenuItem
                                  className={classes.selectOptions}
                                  value={opt}
                                >
                                  {t(`downloader.res_${opt}`)}
                                </MenuItem>
                              );
                            })}
                          </Select>

                          {compressions && compressions.length > 1 && (
                            <Select
                              id="compression"
                              name="compression"
                              disabled={this.state.downloadInProgress}
                              disableUnderline
                              value={this.state.compression}
                              onChange={(event) => {
                                if (this.state.downloadInProgress) return;
                                this.setState({
                                  compression: event.target.value,
                                });
                              }}
                              inputProps={{
                                id: "compression",
                                "aria-labelledby": "compression",
                              }}
                              className={classes.appbarSelect}
                            >
                              {compressions.map((opt) => {
                                return (
                                  <MenuItem
                                    className={classes.selectOptions}
                                    value={opt}
                                  >
                                    {t(`downloader.cmp_${opt}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          )}
                          <Button
                            aria-label="compressions"
                            color="inherit"
                            disabled={this.state.downloadInProgress}
                            onClick={this.downloadClick}
                            startIcon={<BiDownload />}
                            className={classes.downloadButton}
                          >
                            Download
                          </Button>
                        </>
                      ) : (
                        <label>No Download Permission</label>
                      )}
                    </div>
                  </Toolbar>
                </AppBar>
              </div>
              {this.state.downloadable ? (
                <Paper
                  className={clsx(
                    classes.tableMainView,
                    classes.tableMainViewDownloadData
                  )}
                >
                  <TableContainer
                    className={classes.discreteTableData}
                    component={Paper}
                  >
                    <Table
                      stickyHeader
                      className={classes.tableDataList}
                      aria-label={t("downloader.menuTitle")}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell width={30}>
                            <Checkbox
                              disabled={this.state.downloadInProgress}
                              checked={this.state.allSelected}
                              onChange={(event) =>
                                this.switchSelectAll(
                                  event.currentTarget.checked
                                )
                              }
                              inputProps={{
                                "aria-label": "Select/Deselect All",
                              }}
                            />
                          </TableCell>
                          <TableCell width={30}></TableCell>
                          {this.columns.map((column) => (
                            <TableCell width={column.width}>
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.tracks &&
                          this.state.tracks.map((row) => {
                            var estimate = (resolution == DownloadResolutions.high ? row?.download?.hiResSize : row?.download?.loResSize) ?? 0;
                            var infoTitle = `${row?.download?.assetStatus && row.download.assetStatus} ${row?.download?.downloadSize ? ` | Size: ${this.formatBytes(row.download.downloadSize)}` : estimate ? ` | Estimate: ${this.formatBytes(estimate)}` : ''}`

                            return (
                              <TableRow
                                key={row.id}
                                className={classes.tableUnfocusedRow}
                              >
                                <TableCell>
                                  <Checkbox
                                    disabled={this.state.downloadInProgress}
                                    checked={this.state.selectedIds.includes(
                                      row.id
                                    )}
                                    onChange={(event) =>
                                      this.switchSelect(
                                        row,
                                        event.currentTarget.checked
                                      )
                                    }
                                    inputProps={{
                                      "aria-label": "Select/Deselect",
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  {row.download?.completeState ===
                                    CompleteStates.progress ? (
                                    <CircularProgressWithLabel
                                      className={classes.circularWithLabel}
                                      value={row.download?.progress}
                                    />
                                  ) : (
                                    <IconButton
                                      aria-label={`Downloading: ${row.trackTitle}`}
                                      className={classes.icon}
                                      style={{
                                        color:
                                          row?.download?.completeState ===
                                            CompleteStates.progress
                                            ? "blue"
                                            : row?.download?.completeState ===
                                              CompleteStates.completed
                                              ? "green"
                                              : row?.download?.completeState ===
                                                CompleteStates.error
                                                ? "rgb(226 47 112)"
                                                : "gray",
                                      }}
                                      title={infoTitle}
                                    >
                                      <InfoIcon />
                                    </IconButton>
                                  )}
                                </TableCell>

                                {this.columns.map((c, i) => (
                                  <TableDataCol
                                    key={c.field + row.id}
                                    {...this.props}
                                    column={c}
                                    row={row}
                                    rowId={row.id}
                                    selected={false}
                                  />
                                ))}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              ) : null}
            </>
          );
        }}
      </Translation>
    );
  }
}

const CircularProgressWithLabel = (props) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value ?? 0)}%`}</Typography>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(Downloader);
