import { textLengths } from "../../redux/Types";

export const validate2fa = (userdata) => {
  if (!userdata.code.length) {
    return {
      code: "Should not be empty",
    };
  } else {
    return null;
  }
};

export const validateLogin = (userdata) => {
  //TODO:@Dhammika - i think validations are wrong here.
  if (!userdata.password.length && validateEmail(userdata.email)) {
    return {
      email: validateEmail(userdata.email),
      password: "Should not be empty",
    };
  } else if (validateEmail(userdata.email) && userdata.password.length) {
    return { email: validateEmail(userdata.email) };
  } else if (!userdata.password.length && !validateEmail(userdata.email)) {
    return { password: "Should not be empty" };
  } else {
    return null;
  }
};

export const validateSignup = (userdata, bbcSSO, signedup) => {
  if (bbcSSO) return null;
  //TODO:validations here

      let errors = {};

      if (userdata) {
        if (!userdata.password.length) {
          errors.password = "Should not be empty";
        } 
        // else if (userdata.password.length && userdata.password.length < 6) {
        //   errors.password = "Should should be at least 6 characters long";
        // }
        else if (userdata.password.length) {
          var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\-\\]\!@#_'.`+; <>?[{}~=\\\\\"()\\$%\^&\*])(?=.{6,})");
          if(!strongRegex.test(userdata.password)){
            errors.password = "Your password must contain at least 1 lowercase letter, at least 1 uppercase letter, at least 1 numeric character, at least one special character and should be at least 6 characters long.";
          }
          
        }
    
        if (!userdata.confirmPassword.length) {
          errors.confirmPassword = "Should not be empty";
        } else if (
          userdata.password.length &&
          userdata.confirmPassword.length &&
          userdata.password !== userdata.confirmPassword
        ) {
          errors.confirmPassword = "Password and confirm password does not match";
        }
      }
    
      if (Object.entries(errors).length === 0) {
        return null;
      } else {
        return errors;
      }


};

export const validateEmail = (emailAdd = "") => {
  let regEx =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!emailAdd.length) {
    return "Should not be empty";
  } else if (emailAdd.length > textLengths.TEXT) {
    return "Sorry, this email address is too long. Please try a shorter email";
  } else if (!emailAdd.match(regEx)) {
    return "Invalid email address";
  } else {
    return null;
  }
};

export const validatePhoneNumber = (userdata) => {
  if (!userdata.phone.length) {
    return {
      phoneInput: "Should not be empty",
    };
  } else {
    var re =
      /^\+{0,2}([\-\. ])?(\(?\d{0,3}\))?([\-\. ])?\(?\d{0,3}\)?([\-\. ])?\d{3}([\-\. ])?\d{4}/;
    let valid = re.test(userdata.phone);
    if (!valid) {
      return {
        phoneInput: "Invalid phone number, Please re-try",
      };
    } else {
      return null;
    }
  }
};

export const validateResetPassword = (pwData) => {
  let errors = {};

  if (pwData) {
    if (!pwData.password.length) {
      errors.password = "Should not be empty";
    } 
    // else if (pwData.password.length && pwData.password.length < 6) {
    //   errors.password = "Should should be at least 6 characters long";
    // }
    else if (pwData.password.length) {
      var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\-\\]\!@#_'.`+; <>?[{}~=\\\\\"()\\$%\^&\*])(?=.{6,})");
      if(!strongRegex.test(pwData.password)){
        errors.password = "Your password must contain at least 1 lowercase letter, at least 1 uppercase letter, at least 1 numeric character, at least one special character and should be at least 6 characters long.";
      }
      
    }

    if (!pwData.confirmPassword.length) {
      errors.confirmPassword = "Should not be empty";
    } else if (
      pwData.password.length &&
      pwData.confirmPassword.length &&
      pwData.password !== pwData.confirmPassword
    ) {
      errors.confirmPassword = "Password and confirm password does not match";
    }
  }

  if (Object.entries(errors).length === 0) {
    return null;
  } else {
    return errors;
  }
};
