import { T_LeafBool, T_Branch, T_Major } from "./ModulePropTypes";
import { PT_Org } from "./ModulePermitTypes";

export const _canSeeReports = {
  id: "canSeeReports",
  type: T_LeafBool,
  default: true,
};




export const _smReports = {
  id: "smReports",
  permitType: PT_Org,
  type: T_Major,
  modules: [],
};
