import {
  T_LeafBool,
  T_LeafOptionsMultiOrdered,
  T_Major,
} from "./ModulePropTypes";
import { PT_Setting, PT_Org } from "./ModulePermitTypes";
import albumFields from "./albumFields";

export const _settingAlbumMetadataConditional = {
  id: "asm1",
  permitType: PT_Org,
  type: T_LeafBool,
  default: true,
};
export const _settingAlbumMetadataFieldsDefault = {
  id: "asm2",
  permitType: PT_Org,
  type: T_LeafOptionsMultiOrdered,
  options: () => {
    let nonesys = {};
    Object.keys(albumFields).map((key) => {
      if (!albumFields[key].sys) nonesys[key] = albumFields[key];
    });
    return nonesys;
  },
  default: [

    albumFields.prodName.id,

    
  ],
};

export const _settingAlbumMetadataFieldsLibrary = {
  id: "asm3",
  permitType: PT_Org,
  type: T_LeafOptionsMultiOrdered,
  options: () => {
    let nonesys = {};
    Object.keys(albumFields).map((key) => {
      if (!albumFields[key].sys) nonesys[key] = albumFields[key];
    });
    return nonesys;
  },
  default: [
    albumFields.prodName.id,
  ],
};

export const _settingAlbumMetadataFieldsCommercial = {
  id: "asm4",
  permitType: PT_Org,
  type: T_LeafOptionsMultiOrdered,
  options: () => {
    let nonesys = {};
    Object.keys(albumFields).map((key) => {
      if (!albumFields[key].sys) nonesys[key] = albumFields[key];
    });
    return nonesys;
  },
  default: [
    albumFields.prodName.id,
  ],
};

export const _settingAlbumMetadataFieldsCommissioned = {
  id: "asm5",
  permitType: PT_Org,
  type: T_LeafOptionsMultiOrdered,
  options: () => {
    let nonesys = {};
    Object.keys(albumFields).map((key) => {
      if (!albumFields[key].sys) nonesys[key] = albumFields[key];
    });
    return nonesys;
  },
  default: [
    albumFields.prodName.id,
  ],
};

export const _settingAlbumMetadata = {
  id: "_settingAlbumMetadata",
  permitType: PT_Setting,
  type: T_Major,
  modules: [
    _settingAlbumMetadataConditional,
    _settingAlbumMetadataFieldsDefault,
    _settingAlbumMetadataFieldsLibrary,
    _settingAlbumMetadataFieldsCommercial,
    _settingAlbumMetadataFieldsCommissioned,
  ],
};
