import axios from "axios";
import store from "redux/Store";
import { pagingTotalRelation } from "redux/Types";
import { v4 as uuidv4 } from "uuid";

module = "/playoutTrackContent/";
export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,
  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,
  SET_PLAYLIST: "SET_PLAYLIST" + module,
  ADD_SESSION_TRACKS: "ADD_SESSION_TRACKS" + module,
  UPDATE_OK: "UPDATE_OK" + module,
  UPDATING: "UPDATING" + module,
  UPLOADING: "UPLOADING" + module,
  SET_ALBUM_NAME: "SET_ALBUM_NAME" + module,
  REFRESH: "REFRESH" + module,
  GETTING_UPDATE_DATA: "GETTING_UPDATE_DATA" + module,
  UPDATE_DATA_OK: "UPDATE_DATA_OK" + module,
  EMPTY_ITEM: "EMPTY_ITEM" + module,
  UPLOAD_EDIT_ITEM: "UPLOAD_EDIT_ITEM" + module,
  TRACK_EDIT_ITEM: "TRACK_EDIT_ITEM" + module,
  ALBUM_OK: "ALBUM_OK" + module,
  ALBUM_EDIT_ITEM: "ALBUM_EDIT_ITEM" + module,
  ADD_TRACKS_SHOW: "ADD_TRACKS_SHOW" + module,
  REMOVE_OK: "REMOVE_OK" + module,
  SET_SESSION_ID: "SET_SESSION_ID" + module
};

export const editItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.ALBUM_EDIT_ITEM,
    payload: item,
  });
};


export const sendInvalidTracks = (invalidTracks, done = null) => (dispatch) => {
  // const {
  //   user: {
  //     credentials: { orgID },
  //   },
  // } = store.getState();
  // const tId = uuidv4();
  

  // axios
  //   .post(`${orgID}/playouts/tracks/logInvalidTracks`, invalidTracks, {
  //     headers: { tid: tId, orgid: orgID },
  //   })
  //   .then((res) => {
  //    // if (done) done(res.data, null);
  //   })
  //   .catch((err) => {
  //     dispatch({
  //       type: actionTypes.ERROR,
  //       payload: { e: err },
  //     });
  //     console.log(err.message)

  //     if (done) done(null, err.message || "error");
  //   });
}

export const getPlayoutById = (id, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();
  

  axios
    .post(`${orgID}/playouts/session/getPlayoutById`, id, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      console.log(err.message)

      if (done) done(null, err.message || "error");
    });
}

export const deletePlayoutTracks = (message, id, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/playouts/tracks/delete`, id, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
      });
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      console.log(err.message)

      if (done) done(null, err.message || "error");
    });
}

export const publishPlayoutTracks = (message, item, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/playouts/tracks/publish`, item, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
      });
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      console.log(err.message)

      if (done) done(null, err.message || "error");
    });
}

export const craeteAndPublishPlayoutTracks = (message, item, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/playouts/tracks/createPublish`, item, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
      });
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      console.log(err.message)

      if (done) done(null, err.message || "error");
    });
}

export const checkDatahubTrackStatus = (message,items,done=null) => (dispatch) =>{
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  // dispatch({
  //   type: actionTypes.POSTING,
  //   payload: message,
  // });

  axios
    .post(`${orgID}/playouts/tracks/checkDatahubStatus`, items, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      // dispatch({
      //   type: actionTypes.POST_OK,
      // });
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      console.log(err.message)

      if (done) done(null, err.message || "error");
    });
};


export const emptyItem = () => (dispatch) => {
  dispatch({
    type: actionTypes.EMPTY_ITEM,
  });
};


export const setQuery = (query, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });

  if (done) done();
};


export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};


export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};

export const setSessionId = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SESSION_ID,
    payload: item,
  });
};


export const search = (query, sessionId, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
    mlPlayoutTracksReducer: { trackSessionId }
  } = store.getState();


  query.filters = [];
  if (sessionId)
    query.filters = [...query.filters, { 'field': 'session_id', 'operator': 'equals', 'value': [sessionId] }]
  else
    query.filters = [...query.filters, { 'field': 'session_id', 'operator': 'equals', 'value': [trackSessionId] }]


  const tId = uuidv4();
  dispatch({
    type: actionTypes.LOADING,
  });
  axios
    .post(`${orgID}/playouts/tracks/search`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.LOAD,
          payload: res.data.results,
          total: res.data.total,
          totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
        });
        if (done) done(res.data.results, null);
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      if (done) done(null, err);
    });
};

export const tempSearch = (query, sessionId, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
    mlPlayoutTracksReducer: { trackSessionId }
  } = store.getState();

  query.filters = [];
  if (sessionId)
    query.filters = [...query.filters, { 'field': 'session_id', 'operator': 'equals', 'value': [sessionId] }]
  else
    query.filters = [...query.filters, { 'field': 'session_id', 'operator': 'equals', 'value': [trackSessionId] }]


  const tId = uuidv4();

  axios
    .post(`${orgID}/playouts/tracks/search`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        if (done) done(res.data.results, null);
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      if (done) done(null, err);
    });
};

export const downloadXML = (message,downloadType, item, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/playouts/tracks/downloadXML`, {tracks:item,outputType:downloadType},{responseType: 'blob'}, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
      });
      if (done) done(res, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      console.log(err.message)

      if (done) done(null, err.message || "error");
    });
};

export const downloadXMLbyPlayoutId = (message, item, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/playouts/tracks/downloadXMLbyPlayoutId`, {'playoutId':item},{responseType: 'blob'}, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
      });
      if (done) done(res, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      console.log(err.message)

      if (done) done(null, err.message || "error");
    });
};



