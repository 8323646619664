import axios from "axios";
import store from "redux/Store";
import { pagingTotalRelation } from "redux/Types";
import { v4 as uuidv4 } from "uuid";
import { FilterOperatorTypes } from "./../../util/table-view/TableTypes";
import albumFields from "_module/definition/albumFields";
var module = "/albumtrack/";
const trackModule = "/mlTracks/library/";

export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  // LOADING_MORE: "LOADING_MORE" + module,
  // LOAD_MORE: "LOAD_MORE" + module,
  // LOAD_NOMORE: "LOAD_NOMORE" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,
  RESULT_VIEW: "RESULT_VIEW" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,
  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,
  SET_ALBUM: "SET_ALBUM" + module,
  SET_MESSAGE: "SET_MESSAGE" + module,
  CLEAR_MESSAGE: "CLEAR_MESSAGE" + module,
  RESET: "RESET" + module,
  POST_UPDATE: "POST_UPDATE" + module,
  EMPTY_ITEM: "EMPTY_ITEM" + module,
  SHOW_CTAG_RULE: "SHOW_CTAG_RULE" + trackModule,
  GET_CTAG_RULE: "GET_CTAG_RULE" + trackModule,
  RETRIEVING_CTAG_RULE: "RETRIEVING_CTAG_RULE" + trackModule,
  EMPTY_CTAG_RULE: "EMPTY_CTAG_RULE" + trackModule,
  SHOW_CTAG_RULE: "SHOW_CTAG_RULE" + module,
  REFRESH_ITEMS: "REFRESH_ITEMS" + module,
};

export const showMessage = (message) => (dispatch) => {
  if (message) {
    dispatch({
      type: actionTypes.SET_MESSAGE,
      payload: message,
    });
  } else {
    dispatch({
      type: actionTypes.CLEAR_MESSAGE,
    });
  }
};

export const showCtagRulePreviewAlbumTrack = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.SHOW_CTAG_RULE,
    payload: item,
  });
};

export const setResultView = (view) => (dispatch) => {
  dispatch({
    type: actionTypes.RESULT_VIEW,
    payload: view,
  });
};

export const emptyItem = () => (dispatch) => {
  dispatch({
    type: actionTypes.EMPTY_ITEM,
  });
};

export const editItem = (item) => (dispatch) => {
  // console.log("editItem", item);
  dispatch({
    type: actionTypes.EDIT_ITEM,
    payload: item,
  });
};

var getAlbumTid = null;

export const getAlbumById = (id) =>
  new Promise(function (resolve, reject) {
    const {
      user: {
        credentials: { orgID },
      },
    } = store.getState();

    getAlbumTid = uuidv4();

    axios
      .post(
        `/albumserach`,
        {
          filters: [
            {
              operator: FilterOperatorTypes.equals,
              value: [id],
              field: albumFields.prodId.id,
            },
          ],
          page: 0,
          size: 1,
        },
        {
          headers: { tid: getAlbumTid, orgid: orgID },
        }
      )
      .then((res) => {
        //if (res.data.tid === getAlbumTid) {
        resolve(res?.data?.data ? res.data.data : []);
        //}
      })
      .catch((err) => {
        resolve([]);
      });
  });

export const setAlbum = (id, searchId = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  dispatch({
    type: actionTypes.RESET,
  });

  axios
    .post(`/albumserach`, {
      order: "desc",
      orderBy: null,
      filters: [
        { operator: FilterOperatorTypes.equals, value: [id], field: "prodId" },
      ],
      page: 0,
      size: 1, 
      ...(searchId && {searchId: searchId})
    })
    .then((res) => {
      dispatch({
        type: actionTypes.SET_ALBUM,
        payload:
          res?.data?.data && res.data.data.length > 0 ? res.data.data[0] : null,
      });
    });
};

export const setQuery =
  (query, done = null) =>
  (dispatch) => {
    // console.log("setQuery", query);
    dispatch({
      type: actionTypes.QUERY,
      payload: query,
    });

    if (done) done();
  };

export const selectItems = (items) => (dispatch) => {
  // console.log("selectItems", items);
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });

  // Get Rule List of CTAGS
  const {
    user: {
      credentials: { orgID },
    },
    mlalbumtrack: { selectedItem },
  } = store.getState();
  if (
    selectedItem &&
    selectedItem.cTagList &&
    selectedItem.cTagList.length > 0
  ) {
    var cTagIds = [];
    selectedItem.cTagList.forEach((item) => {
      cTagIds.push(item.ctagId);
    });
    dispatch({
      type: actionTypes.RETRIEVING_CTAG_RULE,
    });

    axios
      .post(`${orgID}/mladmin/ctags/getCtagRules`, {
        trackId: selectedItem.id,
        cTagIds: cTagIds,
      })
      .then((res) => {
        dispatch({
          type: actionTypes.GET_CTAG_RULE,
          payload: res.data,
          tagtype: selectedItem.cTagList,
        });
        dispatch({
          type: actionTypes.SHOW_CTAG_RULE,
          payload: true,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.POST_ERROR,
          payload: { e: err },
        });
      });
  } else {
    dispatch({
      type: actionTypes.GET_CTAG_RULE,
      payload: [],
      tagtype: null,
    });
  }
};

var getAlbumContentTId = null;
export const getAlbumContent = async (id) => {
  try {
    const {
      user: {
        credentials: { orgID },
      },
    } = store.getState();
    getAlbumContentTId = uuidv4();
    const response = await axios.post(
      `/albumcontentsearch`,
      {
        albumId: id,
        order: "asc",
        orderBy: "position",
        page: 0,
        size: 10000,
      },
      {
        headers: { tid: getAlbumContentTId, orgid: orgID },
      }
    );
    return response?.data?.data ? response.data.data : [];
  } catch {
    return [];
  }
};

var searchTransId = null;

export const search = (query) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  searchTransId = uuidv4();
  const options = {
    headers: {
      tid: searchTransId,
      orgid: orgID,
    },
  };

  dispatch({
    type: actionTypes.LOADING,
  });
  axios
    .post(`/albumcontentsearch`, query, options)
    .then((res) => {
      if (res.data.tid === searchTransId) {
        if (res.data?.data.length > 0) {
          axios
            .post(
              `${orgID}/ctag/CheckExplicitCtag`,
              {
                trackIdList: res.data.data.map((t) => t.id),
              },
              options
            )
            .then((post) => {
              if (post.data.tid === searchTransId) {
                for (var item of res.data.data) {
                  let trackId = item.id;
                  item.cTagList = post.data.data.find(
                    (opt) => opt.trackId === trackId
                  )?.cTagList;
                }
                dispatch({
                  type: actionTypes.POST_UPDATE,
                  payload: {
                    items: res.data.data,
                  },
                });
              }
            });

          dispatch({
            type: actionTypes.LOAD,
            payload: {
              items: res.data.data,
              total: res.data.total,
              postUpdating: true,
              totalRelation:
                res.data.totalRelation || pagingTotalRelation.EQUALS,
            },
          });
        } else {
          dispatch({
            type: actionTypes.LOAD,
            payload: {
              items: res.data.data,
              total: res.data.total,
              postUpdating: false,
              totalRelation:
                res.data.totalRelation || pagingTotalRelation.EQUALS,
            },
          });
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });
};

export const searchAlbumTracks =
  (query, sessionId, done = null) =>
  (dispatch) => {
    const {
      user: {
        credentials: { orgID },
      },
    } = store.getState();

    query.orderBy = "id";
    if (sessionId) {
      query.filters = [
        ...query.filters,
        { field: "session_id", operator: "equals", value: [sessionId] },
      ];
    }

    const tId = uuidv4();
    dispatch({
      type: actionTypes.LOADING,
    });
    axios
      .post(`${orgID}/uploads/session/getUploadAlbumTracks`, query, {
        headers: { tid: tId, orgid: orgID },
      })
      .then((res) => {
        if (res.data.tid === tId) {
          dispatch({
            type: actionTypes.LOAD,
            payload: res.data.results,
            total: res.data.total,
            totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
          });
          if (done) done(res.data.results, null);
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ERROR,
          payload: { e: err },
        });
        if (done) done(null, err);
      });
  };

export const showAutoMessage = (message) => (dispatch) => {
  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });
  setTimeout(() => {
    dispatch({
      type: actionTypes.CLEAR_MESSAGE,
    });
  }, 3000);
};

export const RemoveTrack =
  (message, item, done = null) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.POSTING,
      payload: message,
    });

    const {
      user: {
        credentials: { orgID },
      },
    } = store.getState();
    item.orgId = orgID;
    const tId = uuidv4();

    axios
      .post(`${orgID}/uploads/tracks/removeTrack`, item, {
        headers: { tid: tId, orgid: orgID },
      })
      .then((res) => {
        dispatch({
          type: actionTypes.CLEAR_MESSAGE,
          payload: res.data,
        });

        if (done) done(res.data, null);
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.POST_ERROR,
          payload: { e: err },
        });

        if (done) done(null, err);
      });
  };

export const copyTrack =
  (message, item, done = null) =>
  (dispatch) => {
    const {
      user: {
        credentials: { orgID },
      },
    } = store.getState();
    const tId = uuidv4();
    item.orgId = orgID;
    dispatch({
      type: actionTypes.POSTING,
      payload: message,
    });

    axios
      .post(`${orgID}/contentadmin/copyTrack`, item, {
        headers: { tid: tId, orgid: orgID },
      })
      .then((res) => {
        dispatch({
          type: actionTypes.POST_OK,
          payload: res.data,
        });

        if (done) done(res.data, null);
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.POST_ERROR,
          payload: { e: err },
        });
      });
  };

export const postItem =
  (action, message, items, id, type, done = null) =>
  (dispatch) => {
    if (id != null) {
      items = [];
      items.push(id);
    }

    let payload = {};
    payload.type = type;
    payload.ids = items;

    const {
      user: {
        credentials: { orgID },
      },
      mlalbumtrack: { query },
    } = store.getState();

    const tId = uuidv4();

    dispatch({
      type: actionTypes.POSTING,
      payload: message,
    });

    //TODO : change the endpoint after backend implementation.
    axios
      .post(`${orgID}/contentadmin/Workspace/${action}`, payload, {
        headers: { tid: tId, orgid: orgID },
      })
      .then((res) => {
        dispatch({
          type: actionTypes.POST_OK,
          payload: items,
        });

        search(query)(dispatch);

        if (done) done(res.data, null);
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.POST_ERROR,
          payload: err,
        });

        if (done) done(null, err);
      });
  };

export const refreshItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.REFRESH_ITEMS,
    payload: items,
  });
};