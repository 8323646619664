import { actionTypes } from "./MessagesActions";
import { SortTypes } from "../../../util/table-view/TableTypes";
import { defaultPopupResults, pagingTotalRelation } from "../../../redux/Types";

const initialState = {
  newCount: 0,
  editing: null,
  onMessageCreated: null,
  closeMessagePanel: null,
  posting: { busy: false, message: "", errors: null },
  items: [],
  totalItems: 0,
  totalUnread: 0,
  totalRelation: pagingTotalRelation.EQUALS,
  selectedItem: {},
  selectedItems: [],
  loading: false,
  loadingMore: false,
  settingAsRead: false,
  noMore: true,
  query: {
    inPage: "all",
    order: SortTypes.desc,
    orderBy: "updatedAt",
    page: 0,
    size: defaultPopupResults,
    q: "",
    filters: [],
  },
  errors: null,
  usePopup: false,
};

export default function (state = initialState, action) {

  switch (action.type) {
    case actionTypes.EDIT_ITEM:
      return {
        ...state,
        editing: action.payload,
      };

    case actionTypes.EDIT_ITEMS_SAVECB:
      return {
        ...state,
        onMessageCreated: action.payload,
      };

    case actionTypes.CLOSE_MESSAGECB:
      return {
        ...state,
        closeMessagePanel: action.payload,
      };

    case actionTypes.NEW_COUNT:
      return {
        ...state,
        newCount: action.payload,
        totalItems: action.payload
      };

    case actionTypes.NEW_UNREAD_COUNT:
      return {
        ...state,
        newCount: action.payload,
        totalUnread: action.payload
      };

    case actionTypes.POSTING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.POST_OK:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
      };

    case actionTypes.POST_ERROR:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: action.payload,
        },
      };

    case actionTypes.LOADING:
      return {
        ...state,
        loading: true,
        loadingMore: false,
        errors: null,
        selectedItems: [],
        items: [],
      };
    // case actionTypes.LOADING_MORE:
    //   return {
    //     ...state,
    //     loading: false,
    //     loadingMore: true,
    //     errors: null,
    //   };

    case actionTypes.LOAD:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        errors: null,
        items: action.payload,
        totalItems: action.total,
        totalUnread: action.unread,
        totalRelation: action.totalRelation,
        selectedItem: action.payload[0] || {},
        errors: null,
        selectedItems: [],
        noMore: false,
      };

      case actionTypes.SETTING_AS_READ:
          return {
            ...state,
            settingAsRead: true
          };
      case actionTypes.SETTING_AS_READ_COMPLETE:
            return {
              ...state,
              settingAsRead: false
            };    
      case actionTypes.SETTING_AS_READ_ERROR:
            return {
              ...state,
              settingAsRead: false
            };    

    // case actionTypes.LOAD_MORE:
    //   return {
    //     ...state,
    //     loading: false,
    //     loadingMore: false,
    //     errors: null,
    //     items: state.items.concat(action.payload),
    //     errors: null,
    //     noMore: false,
    //   };
    // case actionTypes.LOAD_NOMORE:
    //   return {
    //     ...state,
    //     loading: false,
    //     loadingMore: false,
    //     errors: null,
    //     items: state.items.concat(action.payload),
    //     noMore: true,
    //   };

    case actionTypes.ERROR:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        errors: action.payload,
        items: [],
        totalItems: 0,
        totalUnread: 0,
        totalRelation: pagingTotalRelation.EQUALS,
        noMore: true,
      };

    case actionTypes.SELECT_ITEMS:
      return {
        ...state,
        selectedItems: action.payload,
      };
    case actionTypes.QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case actionTypes.FOCUS_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
      };

    case actionTypes.USE_POPUP:
      return {
        ...state,
        usePopup: action.payload,
      };

    default:
      return state;
  }
}
