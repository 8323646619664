import { textLengths } from "../../redux/Types";

export const ValidateRole = (roleData) => {
    let errors = {}

    if (roleData) {
        if (!roleData.name) {
            errors.name = "Should not be empty";
        } else if (roleData.name && roleData.name.length > textLengths.TEXT) {
            errors.name = "Sorry, the name you entered is too long. Please try a shorter name";
        }

        if (!roleData.modules.length) {
            errors.modules = "Please add at least one module"
        }
    }


    if (Object.entries(errors).length === 0) {
        return null;
    } else {
        return errors;
    }
}