import axios from "axios";
import store from "redux/Store";
import { v4 as uuidv4 } from "uuid";
import { OriginTypes, pagingTotalRelation } from "redux/Types";
import { userPrefType } from "../Types";
module = "/smContentTracks";

var tId = null;

export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  LOADING_MORE: "LOADING_MORE" + module,
  LOAD_MORE: "LOAD_MORE" + module,
  LOAD_NOMORE: "LOAD_NOMORE" + module,
  CLEAR: "CLEAR" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,
  RESULT_VIEW: "RESULT_VIEW" + module,
  ADV_SEARCH: "ADV_SEARCH" + module,
  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,
  SET_MESSAGE: "SET_MESSAGE" + module,
  CLEAR_MESSAGE: "CLEAR_MESSAGE" + module,
  LOAD_QUERY: "LOAD_QUERY" + module,
  EDIT_ENABLE: "EDIT_ENABLE" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,
  EMPTY_ITEM: "EMPTY_ITEM" + module,
  GETTING_UPDATE_DATA: "GETTING_UPDATE_DATA" + module,
  UPDATE_DATA_OK: "UPDATE_DATA_OK" + module,
  UPDATING: "UPDATING" + module,
  UPDATE_OK: "UPDATE_OK" + module,
  UPLOADING: "UPLOADING" + module,
  UPLOAD_OK: "UPLOAD_OK" + module,
  SET_TRACK_DATA: "SET_TRACK_DATA" + module,
  TRACK_EDIT_ITEM: "TRACK_EDIT_ITEM" + module,
  POST_UPDATE: "POST_UPDATE" + module,
  
};



export const editItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.EDIT_ITEM,
    payload: item,
  });
};

export const showAutoMessage = (message) => (dispatch) => {
  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });
  setTimeout(() => {
    dispatch({
      type: actionTypes.POST_OK,
    });
  }, 3000);
}

export const emptyItem = () => (dispatch) => {
  dispatch({
    type: actionTypes.EMPTY_ITEM,
  });
};


export const copyTrack = (message, item, done = null) => (dispatch) => {

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();
  item.orgId = orgID;
  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(
      `${orgID}/contentadmin/copyTrack`, item,
      {
        headers: { tid: tId, orgid: orgID },
      }
    )
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
        payload: res.data,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });

}



export const GetUpdateTrack = (message, item, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.GETTING_UPDATE_DATA,
    payload: message,
  });

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  item.orgId = orgID;
  const tId = uuidv4();

  axios
    .post(
      `${orgID}/contentadmin/GetTrackForEdit`, item,
      {
        headers: { tid: tId, orgid: orgID },
      }
    )
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_DATA_OK,
        payload: res.data,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};

export const RemoveTrack = (message, item, done = null) => (dispatch) => {  

  dispatch({
    type: actionTypes.GETTING_UPDATE_DATA,
    payload: message,
  });

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  item.orgId = orgID;
  const tId = uuidv4();

  axios
    .post(
      `${orgID}/uploads/tracks/removeTrack`, item,
      {
        headers: { tid: tId, orgid: orgID },
      }
    )
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_DATA_OK,
        payload: res.data,
      });
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });
      if (done) done(null, err);
    });
};


export const updateSearchUserPref = (pref, message, done = null) => (
  dispatch
) => {
  const {
    user: { id },
  } = store.getState();
  const tId = uuidv4();
  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(
      `${id}/userpref/update`,
      { type: userPrefType.SERACHPREF, pref: pref },
      {
        headers: { tid: tId },
      }
    )
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};

export const setResultView = (view) => (dispatch) => {
  dispatch({
    type: actionTypes.RESULT_VIEW,
    payload: view,
  });
};

export const loadQuery = (query) => (dispatch) => {
  dispatch({
    type: actionTypes.LOAD_QUERY,
    payload: query,
  });
};

export const setAdvSearch = (advsearch) => (dispatch) => {
  dispatch({
    type: actionTypes.ADV_SEARCH,
    payload: advsearch,
  });
};

export const setQuery = (query, done = null) => (dispatch) => {

  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });

  if (done) done();
};

export const showMessage = (message) => (dispatch) => {
  if (message) {
    dispatch({
      type: actionTypes.SET_MESSAGE,
      payload: message,
    });
  } else {
    dispatch({
      type: actionTypes.CLEAR_MESSAGE,
    });
  }
};

export const clear = () => (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR,
  });
};

export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};

export const search = (query) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  query.filters.push([{ 'field': 'sourceDeleted', 'operator': 'equals', 'value': [false] }]);

  tId = uuidv4();

  if (
    query ||
    (query && query.q) ||
    (query && query.origin !== OriginTypes.all) ||
    (query && query.filters && query.filters.length > 0) ||
    (query && query.all && query.all.q) ||
    (query && query.tagAll && query.All.q)
  ) {
    const options = {
      headers: {
        tid: tId,
      },
    };
    dispatch({
      type: actionTypes.LOADING,
    });

    axios
      .post("/librarysearchadmin", query, options)
      .then((res) => {
        if (res.data.tid === tId) {
          if (res.data?.data.length > 0) {
            axios
              .post(
                `${orgID}/ctag/CheckExplicitCtag`,
                {
                  trackIdList: res.data.data.map((t) => t.id),
                },
                options
              ).then((post) => {
                res.data.data.forEach(element => {
                  element.cTagList = post.data.data.find(
                    (opt) => opt.trackId === element.id
                  )?.cTagList;
                });
                const {
                  smContentTrackReducer: { items, originalItems },
                } = store.getState();
                dispatch({
                  type: actionTypes.POST_UPDATE,
                  payload: {
                    items: items,
                    originalItems: originalItems,
                  },
                });
              
              })
              .catch((error) => {
                const {
                  smContentTrackReducer: { items, originalItems },
                } = store.getState();
                dispatch({
                  type: actionTypes.POST_UPDATE,
                  payload: {
                    items: items,
                    originalItems: originalItems,
                  },
                });
              });
            dispatch({
              type: actionTypes.LOAD,
              payload: res.data.data,
              total: res.data.total,
              postUpdating: true,
              totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
            });
          }
          else {
            dispatch({
              type: actionTypes.LOAD,
              payload: [],
              total: 0,
              postUpdating: false,
              totalRelation: pagingTotalRelation.EQUALS,
            });
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ERROR,
          payload: { e: err },
        });
      });
  } else {
    dispatch({
      type: actionTypes.CLEAR,
      payload: [],
    });
  }
};


export const getLibraryWSName = (query, type) => (dispatch) => {

  tId = uuidv4();
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const options = {
    headers: {
      tid: tId,
    },
  };

  var _apiEndpoint = `${orgID}/contentadmin/GetWorkSpaceName`;
  if (type == 'lib')
    _apiEndpoint = `${orgID}/contentadmin/GetLibraryName`;
  axios
    .post(_apiEndpoint, query, options)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_TRACK_DATA,
        payload: res.data.results,
      });
    })
    .catch((err) => {
    });
};

export const postItem = (action, message, items, id, type, done = null) => (dispatch) => {

  if (id != null) {
    items = [];
    items.push(id);
  }

  let payload = {};
  payload.type = type;
  payload.ids = items;

  const {
    user: {
      credentials: { orgID },
    },
    smContentTrackReducer: { query },
  } = store.getState();

  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/contentadmin/Workspace/${action}`, payload, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
        payload: items
      });

      search(query)(dispatch);

      if (done) done(res.data, null);

    })
    .catch((err) => {

      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err
      });

      if (done) done(null, err);
    });
};