import TableStyles from "../../../styles/TableStyles.css";
import CommonStyles from "../../../styles/CommonStyles.css";
export const styles = (theme) => ({
  ...TableStyles,
  ...CommonStyles,
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  popupMessage:{
    margin: "0",
    padding: "0",
    fontSize:"13px",
    "& span":{
      fontWeight:"bold",
      display:"block",
    }
  },
  popupHeader:{
    borderBottom:" 1px solid #7a7a7a",
    paddingBottom: "10px !important",
  },
  discreteTableData: {
    background: "#000",
    height: "calc(100% - 0px)",
    overflow: "auto",
    position: "relative",
    minHeight: "inherit",
    "& .MuiPaper-root": {
      background: "#000",
    },
    "& td": {
      padding: "2px 10px",
    },
    "& th": {
      padding: "0px 10px 0px 10px",
      fontSize: "11px",
    },
  },
  downnloadHeader:{
    background:"#353535",
    minHeight: "52px",
    paddingRight: "10px",
    paddingLeft: "17px",
    "& .MuiAppBar-root":{
      background:"#353535",
    }
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    maxWidth: "300px",
    "& h6":{
      lineHeight:"1",
    }
  },
  section: {
    float:'right',
    display: "flex",
    alignItems: "center",
  },
  gridList: {
    width:'100%',
  },
  tableMainView: {
    width: "100%",
    height:" calc(100% - 52px)",
    overflow: "auto",
    position: "absolute",
    background: "#000",
    marginTop: "0",
   },
   icon:{
     color:"#fff"
   },
   circularWithLabel:{
    fontSize:"12px",
    color:"#fff",
    width: "36px",
    height: "36px",
    "& .MuiTypography-colorTextSecondary":{
      fontSize: "11px !important",
    },
   },
   downloadLabel:{
     "& span":{
      fontSize:"12px",
     }

   }
});
