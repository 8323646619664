import axios from "axios";
import store from "redux/Store";
import { v4 as uuidv4 } from "uuid";
import { ValidateReport } from "../../util/validations/SmUsageReportsActionsValidation";
import { logUserActivity, logUserActivityA, pagingTotalRelation} from "../Types";

module = "/usagereports/";
export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,
  POSTING: "POSTING" + module,
  POST_OK: "POST_OK" + module,
  SAVE_OK: "SAVE_OK" + module,
  POST_ERROR: "POST_ERROR" + module,
  SET_ERROR_LOADING_USERS: "SET_ERROR_LOADING_USERS" + module,
  SET_USERS: "SET_USERS" + module,
  SET_LOG_ACTIVITIES: "SET_LOG_ACTIVITIES" + module,
  SET_ERROR_LOADING_ACTIVITIES: "SET_ERROR_LOADING_ACTIVITIES" + module,
  SET_WORKSPACES: "SET_WORKSPACES" + module,
  SET_ERROR_LOADING_WORKSPACES: "SET_ERROR_LOADING_WORKSPACES" + module,
};

export const editItem = (item) => (dispatch) => {
  
  dispatch({
    type: actionTypes.EDIT_ITEM,
    payload: item,
  });
};

export const postItem = (action, message, items,isNew=true, done = null) => (dispatch) => {
  
  if (action == 'save') {
    let errors = ValidateReport(items.report);
    if (errors) {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: errors
      });
      return;
    }
  }

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/adminreport/${action}`, items, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: action == 'save' && isNew ? actionTypes.SAVE_OK : actionTypes.POST_OK,
        payload: res.data
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        //payload: { e: err },
        payload: err.response?.data || { e: (err.message ? err.message : "Error in creating or updating. Please retry") },
      });

      if (done) done(null, err);
    });
};

export const setQuery = (query, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });

  if (done) done();
};

export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};
export const search = (query) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const tId = uuidv4();
  dispatch({
    type: actionTypes.LOADING,
  });

  axios
    .post(`${orgID}/adminreport/search`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.LOAD,
          payload: res.data.results,
          total: res.data.total,
          totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });
};

export const createdReportSearch = (query,done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const tId = uuidv4();

  axios
    .post(`${orgID}/adminreportjob/search`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (done) done(res.data, null);
    })
    .catch((err) => {
      if (done) done(null, err);
    });
};


export const getUsers = (query) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  axios
    .post(`${orgID}/reports/getallusers`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((response) => {
      dispatch({
        type: actionTypes.SET_USERS,
        users: response.data,
      });
      
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.SET_ERROR_LOADING_USERS,
        users: [],
        errors: error
      });
    });
};




export const getWorkspaces = (query) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  axios
    .post(`${orgID}/adminreport/WorkspaceSearch`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((response) => {
      dispatch({
        type: actionTypes.SET_WORKSPACES,
        workspaces: response.data,
      });
      
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.SET_ERROR_LOADING_WORKSPACES,
        workspaces: [],
        errors: error
      });
    });
};

export const getDownloadLinkReport = (link) =>
new Promise(function (resolve, reject) {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  axios
  .post(`${orgID}/adminreportjob/downloadlink`, {key: link })
  .then((res) => {
      //resolve(res?.data?.url ? res.data.url : '');
      resolve({url: res?.data?.url ? res.data.url : ''});
  })
  .catch((err) => {
    resolve('');
    });
});

export const showAutoMessage = (message) => (dispatch) => {
  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });
  setTimeout(() => {
    dispatch({
      type: actionTypes.POST_OK,
    });
  }, 3000);
};