import axios from "axios";
import store from "redux/Store";
import { pagingTotalRelation } from "redux/Types";
import { v4 as uuidv4 } from "uuid";
import { validateCreateCtag } from "../../util/validations/content-validations/CtagValidation";

module = "/Ctags/main/";
export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  // LOADING_MORE: "LOADING_MORE" + module,
  // LOAD_MORE: "LOAD_MORE" + module,
  // LOAD_NOMORE: "LOAD_NOMORE" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,
  EDIT_CTAG: "EDIT_CTAG" + module,
  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,
};

export const editItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.EDIT_ITEM,
    payload: item,
  });
};

export const editCtagMain= (item) => (dispatch) => {
  dispatch({
    type: actionTypes.EDIT_CTAG,
    payload: item,
  });
};

export const postMainCtagItem = (action, message, items, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  if (items) {
    let errors = validateCreateCtag(items);
    if (errors) {    
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: errors
      });
      return;
    }
  }

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/mladmin/ctags/addCtags`, items, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {

      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err.response?.data || { e: (err.message ? err.message : "Error in creating or updating. please retry") },
      });

      if (done) done(null, err);
    });
};



export const setCtagStatus = (action, message, item, type, done = null) => (dispatch) => {

  var url = '/mladmin/ctags/status';
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });  

  axios
    .post(`${orgID}${url}`, item, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      //if (res.data.tid === tId) {
      dispatch({
        type: actionTypes.POST_OK,
      });

      if (done) done(res.data, null);
      //}
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};

export const deleteRule = (action, message, item, done = null) => (dispatch) => {

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });


  axios
    .post(`${orgID}/mladmin/ctags/deleterule`, item, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      //if (res.data.tid === tId) {
      dispatch({
        type: actionTypes.POST_OK,
      });

      if (done) done(res.data, null);
      //}
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};

export const editCtag = (action, message, items, done = null) => (dispatch) => {

 const {
   user: {
     credentials: { orgID },
   },
 } = store.getState();
 const tId = uuidv4();

 dispatch({
   type: actionTypes.POSTING,
   payload: message,
 });

 axios
   .post(`${orgID}/mladmin/ctags/updateCTagExtended`, items, {
     headers: { tid: tId, orgid: orgID },
   })
   .then((res) => {
     //if (res.data.tid === tId) {
     dispatch({
       type: actionTypes.POST_OK,
     });

     if (done) done(res.data, null);
     //}
   })
   .catch((err) => {
     dispatch({
       type: actionTypes.POST_ERROR,
       payload: { e: err },
     });

     if (done) done(null, err);
   });
};

export const editMainCtag = (action, message, item, done = null) => (dispatch) => {

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  if (item) {
    let errors = validateCreateCtag(item);
    if (errors) {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: errors
      });
      return;
    }
  }
 
  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });
 
  axios
    .post(`${orgID}/mladmin/ctags/updateMainCtag`, item, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      //if (res.data.tid === tId) {
      dispatch({
        type: actionTypes.POST_OK,
      });
 
      if (done) done(res.data, null);
      //}
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: err.response?.data || { e: (err.message ? err.message : "Error in creating or updating. please retry") },
      });
 
      if (done) done(null, err);
    });
 };

export const setQuery = (query, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });

  if (done) done();
};

export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};

export const search = (query) => (dispatch) => {

  
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const tId = uuidv4();

  if(!query.orderBy){
    query.orderBy = 'id';
  }

  dispatch({
    type: actionTypes.LOADING,
  });

  axios
    .post(`${orgID}/ctag/searchCtags`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.LOAD,
          payload: res.data.results,
          total: res.data.total,
          totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });
};
