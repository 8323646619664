import { T_LeafBool, T_Major } from "./ModulePropTypes";
import { PT_System } from "./ModulePermitTypes";



export const _smEmailAdmin = {
  id: "smEmailAdmin",
  permitType: PT_System,
  type: T_Major,
  modules: [],
};
