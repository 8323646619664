import trackFields from "./trackFields";

export default [
  trackFields.id.id,
  trackFields.trackTitle.id,
  trackFields.prodName.id,
  trackFields.performer.id,
  trackFields.recordLabel.id,
  trackFields.libName.id,
  trackFields.catNo.id, 
  trackFields.prodNotes.id,
  trackFields.trackNotes.id,
  trackFields.composer.id,
  trackFields.prsWorkWriters.id,
  trackFields.publisher.id,
  trackFields.prsWorkPublishers.id,    
  trackFields.prs.id,
  trackFields.prsWorkTitle.id,
  trackFields.isrc.id,
  trackFields.iswc.id,
  trackFields.wsId.id,
  trackFields.wsName.id,
  trackFields.dhTrackId.id,
  trackFields.dhExplicit.id,
  trackFields.territories.id,
];


export const trackDefaultFields =  [
  trackFields.id.id,
  trackFields.trackTitle.id,
  trackFields.prodName.id,
  trackFields.performer.id,
  trackFields.recordLabel.id,
  trackFields.libName.id,
  trackFields.catNo.id,
  trackFields.prodNotes.id,
  trackFields.trackNotes.id,
]