import axios from "axios";
import store from "redux/Store";
import { pagingTotalRelation } from "redux/Types";
import { v4 as uuidv4 } from "uuid";
module = "/uploadSessionContent/";
export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,
  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,
  SET_PLAYLIST: "SET_PLAYLIST" + module,
  UPDATE_QUEUE: "UPDATE_QUEUE" + module,
  
};


export const saveItem = (message, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/uploads/session/save`)
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
        sessionId: res.data.id
      });
      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
      if (done) done(null, err.message || "error");
    });
};

export const updateSession = (done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  axios
    .post(`${orgID}/uploads/UpdateSession`)
    .then((res) => {
      if (done) done(res.data, null);
    })
    .catch((err) => {
      if (done) done(null, err.message || "error");
    });
};

export const updateUploadQueue = (queue) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_QUEUE,
    payload: queue,
  });
};

export const getSessionById = (id) =>
  new Promise(function (resolve, reject) {
    const {
      user: {
        credentials: { orgID },
      },
    } = store.getState();

    axios
      .post(`${orgID}/plcontent/search`, {
        plid: id,
        order: "desc",
        orderBy: "updatedAt",
        page: 0,
        size: 10000,
      })
      .then((res) => {
        resolve(res?.data?.results ? res.data.results : []);
      })
      .catch((err) => {
        resolve([]);
      });
  });

export const addSessionItems = (action, message, items, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.LOAD,
    payload: items,
  });
};

export const postItem = (action, message, items, done = null) => (dispatch) => {

  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/playlist/${action}`, items, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
        payload: res.data,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};

export const setQuery = (query, done = null) => (dispatch) => {

  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });

  if (done) done();
};


export const selectItems = (items) => (dispatch) => {

  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};



export const search = (query) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();


  //query.orderBy = "date_last_edited"

  const tId = uuidv4();
  dispatch({
    type: actionTypes.LOADING,
  });
  axios
    .post(`${orgID}/uploads/session/search`, query, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.LOAD,
          payload: res.data.results,
          total: res.data.total,
          totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });
};

