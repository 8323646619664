import {
  T_LeafOptionsMultiOrdered,
  T_Major,
  T_LeafOptionsSingle,
} from "./ModulePropTypes";
import { PT_Setting } from "./ModulePermitTypes";
import albumFields from "./albumFields";
import getOrgSettings from "../../util/sys-calls/getOrgSettings";
import orgSettingType from "./orgSettingType";

export const _settingAlbumMusicResultSingleViewCols = {
  id: "asr1",
  permitType: PT_Setting,
  type: T_LeafOptionsMultiOrdered,
  options: () => {
    let nonesys = {};
    Object.keys(albumFields).map((key) => {
      if (!albumFields[key].sys) nonesys[key] = albumFields[key];
    });
    return nonesys;
  },
  default: [
    albumFields.catNo.id,
    albumFields.prodName.id,
    albumFields.prodArtist.id,
    albumFields.ipRecordLabel.id,
    albumFields.trackPerformer.id,
  ],
};

export const _settingAlbumMusicResultMultiViewCols = {
  id: "asr2",
  permitType: PT_Setting,
  type: T_LeafOptionsMultiOrdered,
  options: () => {
    let nonesys = {};
    Object.keys(albumFields).map((key) => {
      if (!albumFields[key].sys) nonesys[key] = albumFields[key];
    });
    return nonesys;
  },
  default: [
    albumFields.catNo.id,
    albumFields.prodName.id,
    albumFields.prodArtist.id,
    albumFields.ipRecordLabel.id,
    albumFields.trackPerformer.id,
    albumFields.prodYear.id,
    albumFields.prodRelease.id,
    albumFields.prodNumberOfDiscs.id,
    albumFields.prodDiscNr.id,
    albumFields.musicOrigin.id,
    albumFields.prodTags.id,
    albumFields.trackBpm.id,
    albumFields.trackTempo.id,
    albumFields.trackGenres.id,
    albumFields.trackInstruments.id,
    albumFields.trackStyles.id,
    albumFields.upc.id,
    albumFields.ean.id,
  ],
};

export const _settingAlbumMusicResultRankingType = {
  id: "asr3",
  permitType: PT_Setting,
  type: T_LeafOptionsSingle,
  sysCall: getOrgSettings,
  sysCallParam: orgSettingType.albumranking.id,
  default: "",
  removable:true
};

export const _settingAlbumMusicResult = {
  id: "settingAlbumMusicResult",
  permitType: PT_Setting,
  type: T_Major,
  modules: [
    _settingAlbumMusicResultSingleViewCols,
    _settingAlbumMusicResultMultiViewCols,
    _settingAlbumMusicResultRankingType,
  ],
};
