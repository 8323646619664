import { actionTypes } from "../actions/MLLibraryActions";
import { SortTypes } from "../../util/table-view/TableTypes";
import { defaultResultsPerPage, pagingTotalRelation } from "../Types";

const initialState = {
  items: [],
  totalItems: 0,
  totalRelation: pagingTotalRelation.EQUALS,
  selectedItem: {},
  selectedItems: [],
  loading: false,
  loadingMore: false,
  resync: null,
  posting: { busy: false, message: "", errors: null },
  noMore: true,
  query: {
    requestFrom: 'ML_ADMIN',
    type: 'ws',
    order: SortTypes.desc,
    orderBy: null,
    page: 0,
    size: defaultResultsPerPage,
    q: '',
    filters: [],
  },
  errors: null,
  serviceDetails: null
};

export default function (state = initialState, action) {
  //console.log(action);

  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        loading: true,
        loadingMore: false,
        errors: null,
        selectedItems: [],
        items: [],
      };
    // case actionTypes.LOADING_MORE:
    //   return {
    //     ...state,
    //     loading: false,
    //     loadingMore: true,
    //     errors: null,
    //   };

    case actionTypes.LOAD:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        errors: null,
        items: action.payload,
        totalItems: action.total,
        totalRelation: action.totalRelation,
        selectedItem: action.payload[0] || {},
        selectedItems: [],
        noMore: false,
      };

    // case actionTypes.LOAD_MORE:
    //   return {
    //     ...state,
    //     loading: false,
    //     loadingMore: false,
    //     errors: null,
    //     items: paging_type === pagingType.PAGING ? action.payload : state.items.concat(action.payload),
    //     totalItems: action.total,
    //     errors: null,
    //     noMore: false,
    //   };
    // case actionTypes.LOAD_NOMORE:
    //   return {
    //     ...state,
    //     loading: false,
    //     loadingMore: false,
    //     errors: null,
    //     items: paging_type === pagingType.PAGING ? action.payload : state.items.concat(action.payload),
    //     totalItems: action.total,
    //     noMore: true,
    //   };

    case actionTypes.ERROR:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        errors: action.payload,
        items: [],
        totalItems: 0,
        totalRelation: pagingTotalRelation.EQUALS,
        noMore: true,
      };

    case actionTypes.SELECT_ITEMS:
      return {
        ...state,
        selectedItems: action.payload,
      };


  

    case actionTypes.RESYNC:
      return {
        ...state,
        resync: action.payload,
      };
    case actionTypes.QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case actionTypes.FOCUS_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
        resync: null

      };
    
    case actionTypes.EDIT_ITEM:
      return {
        ...state,
        editing: action.payload,
      };

    case actionTypes.POSTING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.POST_OK:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: null,
        },
      };

    case actionTypes.POST_ERROR:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: action.payload,
        },
      };

    case actionTypes.REFRESH_ITEMS:
      return {
        ...state,
        items: action.payload,
      };

    default:
      return state;
  }
}
