import {
  T_LeafBool,
  T_LeafOptionsMultiOrdered,
  T_LeafOptionsSingle,
  T_Major,
} from "./ModulePropTypes";
import { PT_Org } from "./ModulePermitTypes";
import getOrgSettings from "../../util/sys-calls/getOrgSettings";
import orgSettingType from "./orgSettingType";

export const _albumSearchHistoryOptions = {
  none: {
    id: "none",
    name: "No Search History",
  },
  own: {
    id: "own",
    name: "Own Search History",
  },
  all: {
    id: "all",
    name: "All Search History",
  },
};

export const _albumSearchHistory = {
  id: "as3",
  permitType: PT_Org,
  type: T_LeafOptionsSingle,
  options: _albumSearchHistoryOptions,
  default: _albumSearchHistoryOptions.none.id,
};

export const albumresultViews = {
  single: { id: "single" },
  multi: { id: "multi" },
};

export const _albumResultView = {
  id: "as6",
  permitType: PT_Org,
  type: T_LeafOptionsMultiOrdered,
  options: albumresultViews,
  default: [albumresultViews.single.id, albumresultViews.multi.id],
};

export const _albumAdvSearchType = {
  id: "as7",
  permitType: PT_Org,
  type: T_LeafOptionsMultiOrdered,
  sysCall: getOrgSettings,
  sysCallParam: orgSettingType.asearch.id,
  default: ["5", "6", "7"],
};

export const _albumRankingType = {
  id: "as8",
  permitType: PT_Org,
  type: T_LeafOptionsSingle,
  sysCall: getOrgSettings,
  sysCallParam: orgSettingType.albumranking.id,
  default: "10",
  removable:true
};

export const _mlAlbums = {
  id: "mlAlbums",
  permitType: PT_Org,
  type: T_Major,
  modules: [
    _albumSearchHistory,
    _albumResultView,
    _albumAdvSearchType,
    _albumRankingType,
  ],
};
