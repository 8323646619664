import { logUserActivity, reportFileStatus } from "../../redux/Types";



export const reportTypes = {
  UserStatus: 1,
  UserActivityCount: 2,
  UserActivityLog: 3,
  ContentActivityByIP: 4,
  Top1000TrackRanking: 5,
  Clearance: 6,
  ContentSummaryByWorkspace: 7,
  ContentSummaryByLibrary: 8,
  TakendownTracks: 9,
  ContentRequestSummary: 10,
  ContentRequestDetailed: 11,
  NorthAmericanExemption:12,
  SpeechRadioExemption:13,
  AbuseFlag:14
}


export const reportTypesA = [
  { id: reportTypes.UserStatus, label: "All Users Status" },
  { id: reportTypes.UserActivityCount, label: "User Summary Count by Activity" },
  { id: reportTypes.UserActivityLog, label: "User Activity Log" },
  { id: reportTypes.ContentActivityByIP, label: "Content Activity by Interested Party" },
  { id: reportTypes.Top1000TrackRanking, label: "Top 1000 Track Ranking" },
  { id: reportTypes.Clearance, label: "Clearance" },
  { id: reportTypes.TakendownTracks, label: "Taken Down Tracks" },
  { id: reportTypes.ContentSummaryByWorkspace, label: "Content Summary by Workspace" },
  { id: reportTypes.ContentSummaryByLibrary, label: "Content Summary by Library" },
  { id: reportTypes.ContentRequestSummary, label: "Content Request Summary" },
  { id: reportTypes.ContentRequestDetailed, label: "Content Request Detailed" },
  { id: reportTypes.NorthAmericanExemption, label: "North American Exemption" },
  { id: reportTypes.SpeechRadioExemption, label: "Speech Radio Exemption" },
  { id: reportTypes.AbuseFlag, label: "Abuse Flag" },
];


export const reportFileStatusA = [
  { id: reportFileStatus.TOBEGENERATED, label: "In progress" },
  { id: reportFileStatus.COMPLETED, label: "Completed" },
  { id: reportFileStatus.ERROR, label: "Error" },
];


export const reportTrackInterestedPartyA = [
  'performer',
  'lyricist',
  'adaptor',
  'administrator',
  'arranger',
  'composer',
  'publisher',
  'originalPublisher',
  'recordLabel',
  'subLyricist',
  'subAdaptor',
  'subArranger',
  'subPublisher',
  'translator'
]

export const activitiesReportTop1000Tracks = [
  { id: logUserActivity.STREAM_TRACK, label: "Stream" },
  { id: logUserActivity.DOWNLOAD_HI_RES, label: "Download Hi-res" },
  { id: logUserActivity.DOWNLOAD_LOW_RES, label: "Download Low-res" },
  { id: logUserActivity.CF_ADD_TRACK, label: "Add to CF" },
  { id: logUserActivity.PLAYLIST_ADD_TRACK, label: "Add to Playlist" },
]

export const reportAllowedPeriod = [
  {reportType: reportTypes.UserActivityCount, number: 15, unit: 'M'},
  {reportType: reportTypes.UserActivityLog, number: 31, unit: 'D'},
  {reportType: reportTypes.ContentActivityByIP, number: 3, unit: 'M'},
  {reportType: reportTypes.Top1000TrackRanking, number: 31, unit: 'D'},
  {reportType: reportTypes.Clearance, number: 12, unit: 'M'},
  {reportType: reportTypes.ContentSummaryByWorkspace, number: 12, unit: 'M'},
  {reportType: reportTypes.ContentSummaryByLibrary, number: 12, unit: 'M'},
  {reportType: reportTypes.TakendownTracks, number: 12, unit: 'M'},
  {reportType: reportTypes.ContentRequestSummary, number: 12, unit: 'M'},
  {reportType: reportTypes.ContentRequestDetailed, number: 12, unit: 'M'},
  {reportType: reportTypes.NorthAmericanExemption, number: 12, unit: 'M'},
  {reportType: reportTypes.SpeechRadioExemption, number: 12, unit: 'M'},
  {reportType: reportTypes.AbuseFlag, number: 12, unit: 'M'},
]