import axios from "axios";
import store from "redux/Store";
import { trimSearchQuery } from "util/CommonFunc";
import { v4 as uuidv4 } from "uuid";
import { pagingTotalRelation, userPrefType } from "../Types";
var module = "/mlLic/track/";
export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,
  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,
  REFRESH_ITEMS: "REFRESH_ITEMS" + module,
  POST_UPDATE: "POST_UPDATE" + module,
  LAST_REFRESH: "LAST_REFRESH" + module,
};

export const updateSearchUserPref = (pref, message, done = null) => (
  dispatch
) => {
  const {
    user: { id },
  } = store.getState();
  const tId = uuidv4();
  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(
      `${id}/userpref/update`,
      { type: userPrefType.CLERANCEINBOXPREF, pref: pref },
      {
        headers: { tid: tId },
      }
    )
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};

export const refreshItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.REFRESH_ITEMS,
    payload: items,
  });
};

export const setLastRefresh = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.LAST_REFRESH,
    payload: items,
  });
};

export const editItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.EDIT_ITEM,
    payload: item,
  });
};

export const postItem = (action, message, items, done = null) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();
  const tId = uuidv4();

  dispatch({
    type: actionTypes.POSTING,
    payload: message,
  });

  axios
    .post(`${orgID}/mllic/forms/${action}`, items, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      dispatch({
        type: actionTypes.POST_OK,
      });

      if (done) done(res.data, null);
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.POST_ERROR,
        payload: { e: err },
      });

      if (done) done(null, err);
    });
};

export const setQuery = (query, done = null) => (dispatch) => {
  dispatch({
    type: actionTypes.QUERY,
    payload: query,
  });

  if (done) done();
};

export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};
export const search = (query) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  const tId = uuidv4();
  dispatch({
    type: actionTypes.LOADING,
  });

  const options = {
    headers: {
      tid: tId,
      orgid: orgID
    },
  };

  axios
    .post(`${orgID}/mllic/tracks/search`, trimSearchQuery(query), options)
    .then((res) => {
      if (res.data.tid === tId) {
        if (res.data?.results.length > 0) {
          axios
            .post(
              `${orgID}/ctag/CheckExplicitCtag`,
              {
                trackIdList: res.data.results.filter(t => t.trackDetails?.id).map((t) => t.trackDetails.id),
              },
              options
            )
            .then((post) => {
              if (post.data.tid === tId) {
                for (var item of res.data.results) {
                  let trackId = item.trackDetails?.id;
                  if (trackId){
                    item.trackDetails.cTagList = post.data.data.find(
                      (opt) => opt.trackId === trackId
                    )?.cTagList;
                  }
                }
                dispatch({
                  type: actionTypes.POST_UPDATE,
                  payload: {
                    items: res.data.results,
                  },
                });
              }
            });
            dispatch({
              type: actionTypes.LOAD,
              payload: {
                items: res.data.results,
                total: res.data.total,
                postUpdating: true,
                totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
              },
            });
        } else {
        dispatch({
          type: actionTypes.LOAD,
          payload: { items: res.data.results, total: res.data.total, postUpdating: false,totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS, }
        });
      }}

    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });
};
