import { textLengths } from "../../redux/Types";

export const ValidateSetting = (data) => {
    let errors = {}

    if (data) {
        if (!data.name) {
            errors.name = "Should not be empty";
        } else if (data.name && data.name.length > textLengths.TEXT) {
            errors.name = "Sorry, the name you entered is too long. Please try a shorter name";
        }

        if (!data.subrules || data.subrules.length == 0) {
            errors.e = "Please specify at least one condition. ";
        }
    }

    if (Object.entries(errors).length === 0) {
        return null;
    } else {
        return errors;
    }
}


function isNumeric(value) {
    return /^[1-9][0-9]*$/.test(value);
}

export const ValidateCondition = (data) => {
    let errors = {}

    if (data) {
        if (!data.limit || !isNumeric(data.limit)) {
            errors.limit = "Please specify a valid activity count.";
        }
        if (!data.period || !isNumeric(data.period)) {
            errors.period = "Please specify a valid period.";
        }

        // Check for repeated subrules
        if (data.subrules.length > 0) {
            const obj = data.subrules.find(o => o.limit === data.limit &&
                o.period === data.period &&
                o.unit === data.unit &&
                o.activity === data.activity);
            if (obj)
                errors.e = "The specified condition already exists.";
        }
    }

    if (Object.entries(errors).length === 0) {
        return null;
    } else {
        return errors;
    }
}