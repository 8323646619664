import { textLengths } from "../../../redux/Types";

export const validateCreatePPL = (data) => {
    let errors = {};
    if (data) {
      if (!data.member && !data.member?.length) {
        errors.member = "Member should not be empty.";
      }
      if (data.member?.length && data.member?.length > textLengths.TEXT) {
        errors.member = "Member should be less than 256 characters.";
      }

      if (data.label?.length && data.label?.length > textLengths.TEXT) {
        errors.label = "Label should be less than 256 characters.";
      }
      if (data.mlc?.length && data.mlc?.length > textLengths.TEXT) {
        errors.mlc = "MLC should be less than 256 characters.";
      }
      if (data.source?.length && data.source?.length > 5) {
        errors.source = "Source should be less than 6 characters.";
      }
    }
    if (Object.entries(errors).length === 0) {
      return null;
    } else {
      return errors;
    }
};