import axios from "axios";
import store from "redux/Store";
import { pagingTotalRelation } from "redux/Types";
import { v4 as uuidv4 } from "uuid";
import { ValidatePlaylist } from "../../util/validations/MlPlaylistActionsValidation";
module = "/playlistOutbox/";
export const actionTypes = {
  LOADING: "LOADING" + module,
  LOAD: "LOAD" + module,
  ERROR: "ERROR" + module,
  FOCUS_ITEM: "FOCUS_ITEM" + module,
  SELECT_ITEMS: "SELECT_ITEMS" + module,
  QUERY: "QUERY" + module,
  EDIT_ITEM: "EDIT_ITEM" + module,
  POSTING: "POSTING" + module,
  POST_OK: "POSTOK" + module,
  POST_ERROR: "POST_ERROR" + module,
  PL_UPDATING: "PL_UPDATING" + module,
  PL_UPDATE_OK: "PL_UPDATE_OK" + module,
  PL_UPDATE_ERROR: "PL_UPDATE_ERROR" + module,
  LOAD_EDITABLE_PL: "LOAD_EDITABLE_PL" + module,
  LOADING_EDITABLE_PL: "LOADING_EDITABLE_PL" + module,
  SET_PLRECENT_Q: "SET_PLRECENT_Q" + module,
  CLEAR_ERRORS: "CLEAR_ERRORS" + module,
};

var tId = null;

export const loadEdittablePlaylist = (query) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  var requestParam = {
    order: "desc",
    orderBy: "updatedAt",
    fields: ["id", "name", "itemsCount"],
    page: 0,
    size: 3,
  };

  if (query) {
    requestParam.q = query;
  }

  axios
    .post(`${orgID}/playlist/editable`, requestParam)
    .then((res) => {
      dispatch({
        type: actionTypes.LOAD_EDITABLE_PL,
        payload: res?.data?.results ? res.data.results : [],
      });
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });
};

export const editItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.EDIT_ITEM,
    payload: item,
  });
};

export const setPlrecentQuery = (query) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_PLRECENT_Q,
    payload: query,
  });
};

export const showAutoMessage = (message) => (dispatch) => {
  dispatch({
    type: actionTypes.PL_UPDATING,
    payload: message,
  });
  setTimeout(() => {
    dispatch({
      type: actionTypes.PL_UPDATE_OK,
    });
  }, 3000);
};

var messageShowTimer;

export const setPlaylistTracks =
  (action, message, object, done = null) =>
  (dispatch) => {
    const {
      user: {
        credentials: { orgID },
      },
    } = store.getState();
    const tId = uuidv4();

    dispatch({
      type: actionTypes.PL_UPDATING,
      payload: message,
    });

    axios
      .post(`${orgID}/playlist/${action}`, object, {
        headers: { tid: tId, orgid: orgID },
      })
      .then((res) => {
        dispatch({
          type: actionTypes.PL_UPDATE_OK,
          payload: res.data,
        });

        if (done) done(res.data, null);
      })
      .catch((err) => {
        if (done) done(null, err);
        clearTimeout(messageShowTimer);
        dispatch({
          type: actionTypes.PL_UPDATING,
          payload: "Access denied",
        });
        messageShowTimer = setTimeout(
          () =>
            dispatch({
              type: actionTypes.PL_UPDATE_ERROR,
              payload: { e: err },
            }),
          6000
        );
      });
  };

export const postItem =
  (action, message, items, done = null) =>
  (dispatch) => {
    if (items.entity) {
      let errors = ValidatePlaylist(items.entity);
      if (errors) {
        dispatch({
          type: actionTypes.POST_ERROR,
          payload: errors,
        });
        return;
      }
    }

    const {
      user: {
        credentials: { orgID },
      },
    } = store.getState();
    const tId = uuidv4();

    dispatch({
      type: actionTypes.POSTING,
      payload: message,
    });

    axios
      .post(`${orgID}/playlist/${action}`, items, {
        headers: { tid: tId, orgid: orgID },
      })
      .then((res) => {
        dispatch({
          type: actionTypes.POST_OK,
          payload: res.data,
        });

        if (done) done(res.data, null);
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.POST_ERROR,
          payload: { e: err },
        });

        if (done) done(null, err);
      });
  };

export const setQuery =
  (query, done = null) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.QUERY,
      payload: query,
    });

    if (done) done();
  };

export const selectItems = (items) => (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_ITEMS,
    payload: items,
  });
};

export const focusItem = (item) => (dispatch) => {
  dispatch({
    type: actionTypes.FOCUS_ITEM,
    payload: item,
  });
};

export const search = (query) => (dispatch) => {
  const {
    user: {
      credentials: { orgID },
    },
  } = store.getState();

  tId = uuidv4();
  dispatch({
    type: actionTypes.LOADING,
  });

  const myQuery = {
    ...query,
    orderBy:
      query.orderBy === "chartType"
        ? [
            {
              orderBy: "_score",
              order: query.order === "asc" ? "desc" : "asc",
            },
            {
              orderBy: "priority",
              order: query.order === "asc" ? "asc" : "desc",
            },
            {
              orderBy: "updatedAt",
              order: query.order === "asc" ? "desc" : "asc",
            },
          ]
        : query.orderBy,
  };

  axios
    .post(`${orgID}/playlist/search`, myQuery, {
      headers: { tid: tId, orgid: orgID },
    })
    .then((res) => {
      if (res.data.tid === tId) {
        dispatch({
          type: actionTypes.LOAD,
          payload: res.data.results,
          total: res.data.total,
          totalRelation: res.data.totalRelation || pagingTotalRelation.EQUALS,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: actionTypes.ERROR,
        payload: { e: err },
      });
    });
};

export const clearErrors = (done) => (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR_ERRORS,
    payload: {},
  });
  if (done) done();
};
