export const messageTypes = {
    CLEARANCE_FORM : 1,
    CLEARANCE_TRACK:2,
    CONTENT :3,
    TEXT : 4,
    CONTENT_REQUEST:6,
}
export const attachmentTypes = {
    CLEARANCE_FORM : 1,
    CLEARANCE_TRACK:2,
    TRACK: 3,
    PLAYLIST:4,
    CLEARANCE_FORM_TRACK:5,
    CONTENT_REQUEST:6,

    ALBUM:7,
}

export const contentRequestTypes = {
    // TRACK : 1,
    ALBUM : 2,

    TRACK_INSTRUMENTAL : 1,
    TRACK_RADIO_EDIT : 3,
    TRACK_CLEAN_VERSION : 4,
    TRACK_OTHER: 5,

}

// This type is also in CentralAPI, keep consistent if you modify it
// = contentRequestTypesA in centralAPI
export const contRequestTypesList = [
    // {id:contentRequestTypes.TRACK, label:'Track'},
    {id:contentRequestTypes.TRACK_OTHER, label:'Track',infoText:"Track as commercially released (including classical and dance tracks)"},
    {id:contentRequestTypes.TRACK_INSTRUMENTAL, label:'Track - Instrumental',infoText:"An instrumental version of a commercially released vocal track. Use 'Track' above for classical and dance tracks"},
    {id:contentRequestTypes.TRACK_RADIO_EDIT, label:'Track - Radio Edit',infoText:"Shorter edits sometime available for Radio play"},
    {id:contentRequestTypes.TRACK_CLEAN_VERSION, label:'Track - Clean Version',infoText:"A clean version of a commercially released track"},
    {id:contentRequestTypes.ALBUM, label:'Album',infoText:"Album as commercially released"}
  ];

export const contentRequestStatus = {
    REQUESTED : 1,
    ACKNOWLEDGED : 2,
    SUCCESSFUL : 3,
    REJECTED : 4,
    NOTSOURCED: 5,

    WITH_BBC_ARCHIVE: 6,
    FULFILLED_ARCHIVE: 7,
    NOTSOURCED_ARCHIVE: 8,
    IN_MUSICMOX: 9,

}

export const contentRequestStatusList = [
    {id:contentRequestStatus.REQUESTED, label:'Requested', color: '#0c853e'},
    {id:contentRequestStatus.ACKNOWLEDGED, label:'Acknowledged', color: '#705200'},
    {id:contentRequestStatus.SUCCESSFUL, label:'Fulfilled', color: '#015C6A'},
    // {id:contentRequestStatus.REJECTED, label:'Request Rejected', color: '#A22F2F'},
    {id:contentRequestStatus.NOTSOURCED, label:'Unable to Source', color: '#953c3c'},

    {id:contentRequestStatus.WITH_BBC_ARCHIVE, label:'With BBC Archive', color: '#ab289d'},
    {id:contentRequestStatus.FULFILLED_ARCHIVE, label:'Fulfilled - Archive', color: '#008f7f'},
    {id:contentRequestStatus.NOTSOURCED_ARCHIVE, label:'Unable to Source - Archive', color: '#974949'},
    {id:contentRequestStatus.IN_MUSICMOX, label:'In MusicBox', color: '#008799'},
  ];


  export const contentRequestNotSourceReasons = {
      DIGITALLY : 1,
      PRE_RELEASE: 2,
      HIGH_RES: 3,
      LABEL_RESPONSE: 4,
      INSTRUMENTAL: 5,
      CLEAN_VERSION: 6,
      RADIO_EDIT: 7,
      OTHER:8
  }

  export const contentRequestNotSourceReasonsList = [
      {id: contentRequestNotSourceReasons.DIGITALLY, label : "Digitally Unavailable", hover : "Digitally Unavailable"},
      {id: contentRequestNotSourceReasons.PRE_RELEASE, label : "Pre-Release", hover : "Pre-Release"},
      {id: contentRequestNotSourceReasons.HIGH_RES, label : "High Resolution Unavailable", hover : "High Resolution Unavailable"},
      {id: contentRequestNotSourceReasons.LABEL_RESPONSE, label : "Awaiting Label response", hover : "Awaiting Label response"},
      {id: contentRequestNotSourceReasons.INSTRUMENTAL, label : "Instrumental Unavailable", hover : "No Instrumental Non-Vocal edit Is Available Commercially or from the Label"},
      {id: contentRequestNotSourceReasons.CLEAN_VERSION, label : "Clean Version Unavailable", hover : "No Clean Edit Is Available Commercially or from the Label"},
      {id: contentRequestNotSourceReasons.RADIO_EDIT, label : "Radio Edit Unavailable", hover : "No Radio Edit Is Available Commercially or from the Label"},
      {id: contentRequestNotSourceReasons.OTHER, label : "Other", hover : "Other"},
  ]
  
export const contentRequestPriority = {
    P1 : 1,
    P2 : 2,
    P3 : 3,
    P4 : 4,
}

export const contentRequestPriorityList = [
    {id : contentRequestPriority.P4, label: "Content Suggestion", desc : "Non-Time Sensitive", infoText : "Repertoire sourced when available. Please only choose this option if you have no immediate deadline"},
    {id : contentRequestPriority.P3, label: "P3 - Standard", desc : "Non-Urgent requests", infoText : "Sourced within 2 working days"},
    {id : contentRequestPriority.P2, label: "P2 - Urgent", desc : "Urgent requests", infoText : "Sourced within 1 working day"},
    {id : contentRequestPriority.P1, label: "P1 - TX Critical", desc : "Very Urgent requests", infoText : "Sourced within a 4 hour period (working hours)"},
]


export const contentRequestPrposedUsage = {
    PROMO_TRAILER : 1,
    TV_SYNC : 2,
    RADIO : 3,
    FUTURE_USAGE : 4,
}
export const contentRequestPrposedUsageList = [
    {id:contentRequestPrposedUsage.PROMO_TRAILER, label:'Promo / Trailer'},
    {id:contentRequestPrposedUsage.TV_SYNC, label:'TV Sync'},
    {id:contentRequestPrposedUsage.RADIO, label:'Radio'},
    {id:contentRequestPrposedUsage.FUTURE_USAGE, label:'Future Usage'},
]
