import { actionTypes } from "./SmSysAdminOrganizationsActions";
import { FilterOperatorTypes, SortTypes } from "../../../../util/table-view/TableTypes";
import { defaultResultsPerPage } from "../../../../redux/Types";

const initialState = {
  items: [],
  totalItems: 0,
  selectedItem: {},
  selectedItems: [],
  loading: false,
  loadingMore: false,
  posting: { busy: false, message: "", errors: null },
  editing: null,
  noMore: true,
  query: {
    order: SortTypes.desc,
    orderBy: "updatedAt",
    page: 0,
    size: defaultResultsPerPage,
    q: "",
    // filters: [{operator: FilterOperatorTypes.equals, value:[0], field: "status"}],
    filters: [],
  },
  errors: null,
};

export default function (state = initialState, action) {
  //console.log(action);

  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        loading: true,
        loadingMore: false,
        errors: null,
        selectedItems: [],
        items: [],
      };

    case actionTypes.LOAD:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        errors: null,
        items: action.payload,
        totalItems: action.total,
        selectedItem: action.payload[0] || {},
        selectedItems: [],
        noMore: false,
      };

    case actionTypes.ERROR:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        errors: action.payload,
        items: [],
        totalItems: 0,
        noMore: true,
      };

    case actionTypes.SELECT_ITEMS:
      return {
        ...state,
        selectedItems: action.payload,
      };
    case actionTypes.QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case actionTypes.FOCUS_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
      };

    case actionTypes.EDIT_ITEM:
      return {
        ...state,
        editing: action.payload,
      };

    case actionTypes.POSTING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

      case actionTypes.POST_OK:
        // var items = state.items.find((r) => r.id === action.payload.id)
        //   ? state.items.map((i) =>
        //       i.id === action.payload.id ? action.payload : i
        //     )
        //   : [action.payload, ...state.items];
  
        // return {
        //   ...state,
        //   items,
        //   posting: {
        //     busy: false,
        //     message: "",
        //     errors: null,
        //   },
        // };

        return {
          ...state,
          posting: {
            busy: false,
            message: action.payload? action.payload: "",
            errors: null,
          },
        };

    case actionTypes.POST_ERROR:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: action.payload,
        },
      };

    default:
      return state;
  }
}
