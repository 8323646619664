import React, { Component } from "react";
import { IconButton, TextField, Button, CircularProgress } from "@material-ui/core";
import { Translation } from "react-i18next";
import { MenuItem } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { GoPlus } from "react-icons/go";
import { FiSearch } from "react-icons/fi";
import InputAdornment from '@material-ui/core/InputAdornment';
import clsx from "clsx";
import { _mlPlaylists, _mlPlaylistsAdd } from "../../../_module/definition/MlPlaylists";
import { _PermittedContent } from "../../../_module/utils/PermittedContent";
import { MdQueueMusic } from "react-icons/md";
import { ChartIndicators } from "modules/ml-playlist/mylist/Columns";

export const PlaylistItemTypes = {
  album: 1,
  track: 2,
}

export const createPlaylistObject = (id, type) => {
  return {id, type};
}

class RecentPlaylistMenu extends Component {
  constructor(props) {
    super();
    this.state = {
      playlistSelected: null,
      newPlaylistMode: false,
      newPlaylistName: null,
      focusList:false,
    };
  }
  input = null;

  filterChangeTimer = null;

  componentWillUnmount (){
    const { loadEdittablePlaylist, setPlrecentQuery, plrecentQuery  } = this.props;
    if(plrecentQuery !== ""){
      setPlrecentQuery("");
      loadEdittablePlaylist("");
    }
  }

  onFilterChange = (e) => {
    const { loadEdittablePlaylist, setPlrecentQuery } = this.props;
    var filter = e.currentTarget.value;
    setPlrecentQuery(filter);
    clearTimeout(this.filterChangeTimer);
    this.filterChangeTimer = setTimeout(() => {
      loadEdittablePlaylist(filter);
    }, 400);
  };

  onCreatePlaylistConfirm = (e) => {
    const {
      items,
      setPlaylistTracks,
      handleClose,
      loadEdittablePlaylist,
      setPlrecentQuery,
      showMessage,
      searchId,
    } = this.props;
    handleClose(e);
    if (items?.length > 0 && this.state.newPlaylistName.length > 0) {
      showMessage(
        `Adding items(s) to new playlist "${this.state.newPlaylistName}"`
      );
      setPlaylistTracks(
        "pl-create-add",
        "Adding to playlist",
        { playlists: [{ name: this.state.newPlaylistName, chartType: ChartIndicators.None }], items: items, ...(searchId && {searchId: searchId}) },
        (data, error) => {
          this.setState({
            newPlaylistMode: false,
            newPlaylistName: "",
          });
          setPlrecentQuery(null);
          loadEdittablePlaylist();
          handleClose(e);
          if (error) {
            showMessage(error);
          } else {
            showMessage();
          }
        }
      );
    }
  };

  onPlaylistConfirm = (e, playlist) => {
    const {
      items,
      setPlaylistTracks,
      handleClose,
      loadEdittablePlaylist,
      setPlrecentQuery,
      showMessage,
      searchId,
    } = this.props;
    handleClose(e);
    if (items?.length > 0 && playlist && playlist.id) {
      showMessage(`Adding items(s) to playlist "${playlist.name}"`);
      setPlaylistTracks(
        "pl-add",
        "Adding to playlist",
        { playlists: [playlist.id], items: items, ...(searchId && {searchId: searchId}) },
        (data, error) => {
          setPlrecentQuery(null);
          loadEdittablePlaylist();
          handleClose(e);
          if (error) {
            showMessage(error);
          } else {
            showMessage();
          }
        }
      );
    }
  };

  onClose= () =>{
    console.log("onClose")
  }


  render() {
    const { classes, busy, plrecentList, plrecentQuery, items } = this.props;

    const canCreatePlaylist = () => {
      return _PermittedContent([_mlPlaylists]) ? true : false;
    };

    const canAddToPlaylist = () => {
      return _PermittedContent([_mlPlaylists, _mlPlaylistsAdd]) ? true : false;
    };

    const {focusList} = this.state;
    
    return (
      <Translation>
        {(t, { i18n }) => (
          <>
           {!this.state.newPlaylistMode && (
                  
                  <MenuItem onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.setState({ newPlaylistMode: true });
                  }} 
                  autoFocus={focusList}
                  className={clsx(classes.createNewBox,classes.recentPlaylistTopSearch, classes.disableHover)} disableRipple>
                  <Button
                    className={classes.createNewPlaylist}
                    disabled={
                      !items || items?.length === 0 || busy || !canCreatePlaylist() ? true : false
                    }
                    onClickCapture={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      this.setState({ newPlaylistMode: true });
                    }}                   
                  >
                    <GoPlus className={classes.addPlaylistIcons} />
                    Create Playlist and Add
                  </Button>
                  </MenuItem>                
              )}  
              
              
            <div className={clsx(classes.createNewBox,classes.recentPlaylistTopSearch)}>
              {/* {!this.state.newPlaylistMode && (
                  <Button
                    className={classes.createNewPlaylist}
                    disabled={
                      !items || items?.length === 0 || busy || !canCreatePlaylist() ? true : false
                    }
                    onClickCapture={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      this.setState({ newPlaylistMode: true });
                    }}
                  >
                    <GoPlus className={classes.addPlaylistIcons} />
                    Create Playlist and Add
                  </Button>
              )} */}
              
              {this.state.newPlaylistMode && (
                <>
                <div className={classes.createNewWraper}>
                  <div
                  
                    onClickCapture={(e) => {
                      // e.stopPropagation();
                      e.preventDefault();
                    }}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                      // if (e.key === "Enter") {
                      //   if (this.state.newPlaylistName){
                      //     this.onCreatePlaylistConfirm(e);
                      //   }
                      // }
                    }}
                  >
                    <div>
                      
                    </div>
                    <TextField
                      autoComplete="off"
                      name="newPlaylistName"
                      variant="outlined"
                      type="text"
                      autoFocus={true}
                      placeholder={"New playlist name"}
                      className={classes.recentPlaylistItemsSearch}
                      value={this.state.newPlaylistName || ""}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          e.stopPropagation();
                          if (this.state.newPlaylistName){
                            this.onCreatePlaylistConfirm(e);
                          }
                        }
                      }}
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.setState({
                          newPlaylistName: e.currentTarget.value,
                        });
                      }}
                    />
                 
                    <div   className={classes.createButtonsSet}>
                    <IconButton
                      color="primary"
                      className={classes.globalGreenButton}
                      aria-label="Create"
                      tabIndex={0}
                      onKeyDown={(e) => {
                        // e.stopPropagation();
                        if (e.key === "Enter") {
                          e.preventDefault();
                        e.stopPropagation();
                        if (this.state.newPlaylistName){
                          this.onCreatePlaylistConfirm(e);
                        }
                        }
                      }}
                      disabled={this.state.newPlaylistName ? false : true}
                      onClickCapture={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (this.state.newPlaylistName){
                          this.onCreatePlaylistConfirm(e);
                        }
                      }}
                    >
                      <DoneIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      className={classes.globalGrayButton}
                      aria-label="Close"
                      tabIndex={0}
                      onKeyDown={(e) => {
                        // e.stopPropagation();
                        if (e.key === "Enter") {
                          e.preventDefault();
                          e.stopPropagation();
                          this.setState({
                            newPlaylistMode: false,
                            newPlaylistName: "",
                            focusList: true,
                          });
                        }
                      }}
                      onClickCapture={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.setState({
                          newPlaylistMode: false,
                          newPlaylistName: "",
                          focusList: true,
                        });
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                                 
                  </div>
                    
                  </div>
                    </div>
                    
                </>
              )}
            </div>
            {canAddToPlaylist() && (
              <>
                
                <div className={classes.addtoPlaylistList}>Add to Playlist</div>
                <MenuItem className={clsx(classes.disableHover)}  tabIndex={0} onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (this.input) {
                      this.input.focus();
                    }
                  }} disableRipple>
                <div
                 aria-label="Search playlist"
                  onClickCapture={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  onKeyDown={(e) => e.stopPropagation()}
                >
                  <TextField
                    autoComplete="off"
                    name="recentplaylist"
                    variant="outlined"
                    type="text"
                    placeholder={"Search playlist"}
                    inputRef={(input) => (this.input = input)}
                    inputProps={{
                      'aria-label': "Search playlist"
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <FiSearch />
                      
                        </InputAdornment>
                      ),
                    }}
                    className={classes.recentPlaylistItemsSearch}
                    value={plrecentQuery || ""}
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.onFilterChange(e);
                    }}
                  />
                </div>
                </MenuItem>
                {busy && <CircularProgress  className={classes.circularProgress} />}
                {!busy && (!plrecentList || !plrecentList.length) && <span  class="noDataToPreview">Nothing to list </span>}

                {!busy && Array.isArray(plrecentList) && plrecentList?.map((r, index) => {
                  return (
                    <MenuItem
                      className={classes.recentPlaylistItems}
                      disabled={
                        !items || items?.length === 0 ? true : false
                      }
                      tabIndex={0}
                      onClick={(e) => {
                        this.onPlaylistConfirm(e, r);
                      }}
                      title={r.name}
                    >
                     <MdQueueMusic title ="Playlists" style={{ fontSize: "20px" }} className={classes.contextMenuIcon} />   
                     {r.name} ({r.itemsCount})
                    </MenuItem>
                  );
                })}
             </>
            )}
          </>
        )}
      </Translation>
    );
  }
}

export default withRouter(RecentPlaylistMenu);
