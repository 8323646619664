import { textLengths } from "../../redux/Types";

export const ValidateGroup = (groupData) => {
    let errors = {}

    if (groupData) {
        if (!groupData.name) {
            errors.name = "Should not be empty";
        } else if (groupData.name && groupData.name.length > textLengths.TEXT) {
            errors.name = "Sorry, the name you entered is too long. Please try a shorter name";
        }
    }


    if (Object.entries(errors).length === 0) {
        return null;
    } else {
        return errors;
    }
}