import React, { Component } from "react";
import { Translation } from "react-i18next";
import { ChartIndicators } from "../mylist/Columns";
import StarIcon from "@material-ui/icons/Star";

export class ChartIndicator extends Component {
  constructor(props) {
    super();
  }
 
  render() {
    const {
      playlist,
    } = this.props;

    return (
      <Translation>
        {(t, { i18n }) => (
          playlist?.chartType === ChartIndicators.Chart ?
          <div class={"activeChart"} title={"Chart Indicator"} style={{ color: 'gold' }}><StarIcon /></div>:<div title={"Not a Chart"} style={{ color: 'gray' }} ><StarIcon /></div>
        )}
      </Translation>
    );
  }
}
