import { T_LeafBool, T_Branch, T_Major } from "./ModulePropTypes";
import { PT_Org } from "./ModulePermitTypes";

export const _mlContentCanEditTracksAndAlbums = {
  id: "mlca1",
  type: T_LeafBool,
  default: true,
};

export const _mlContentCanDeleteTracks = {
  id: "mlca2",
  type: T_LeafBool,
  default: true,
};

export const _mlContentCanAddNotes = {
  id: "mlca3",
  type: T_LeafBool,
  default: true,
};

export const _mlContentCanCreateAdminNotes = {
  id: "mlca4",
  type: T_LeafBool,
  default: true,
};

export const _mlContentCanCreatePublicNotes = {
  id: "mlca5",
  type: T_LeafBool,
  default: true,
};

// export const _mlContentCanpublishToPlayout = {
//   id: "mlca6",
//   type: T_LeafBool,
//   default: true,
// };

export const _mlContentCanManageRestricted = {
  id: "mlca7",
  type: T_LeafBool,
  default: true,
};

export const _mlContentCanManagePreRelease = {
  id: "mlca8",
  type: T_LeafBool,
  default: true,
};

export const _mlContentCanArchive = {
  id: "mlca9",
  type: T_LeafBool,
  default: true,
};

export const _mlContentCanExplicitContent = {
  id: "mlca10",
  type: T_LeafBool,
  default: true,
};

export const _mlContentAdmin = {
  id: "mlContentAdmin",
  permitType: PT_Org,
  type: T_Major,
  modules: [
    _mlContentCanExplicitContent,
    _mlContentCanManagePreRelease,
    _mlContentCanManageRestricted,
    //_mlContentCanpublishToPlayout,
    //_mlContentCanCreatePublicNotes,
    _mlContentCanCreateAdminNotes,
    //_mlContentCanAddNotes,
    _mlContentCanDeleteTracks,
    _mlContentCanEditTracksAndAlbums,
    _mlContentCanArchive,
  ],
};
