import {
  SET_ERRORS,
  CLEAR_ERRORS,
  LOADING_UI,
  STOP_LOADING_UI,
  LOADING_RECENT_PL,
  STOP_LOADING_RECENT_PL,
  SET_ERRORS_RECENT_PL,
  CLEAR_ERRORS_RECENT_PL,
  POSTING_UI,
  STOP_POSTING_UI,
  SET_TABLE_HEADER_HEIGHT,
} from "../Types";

const initialState = {
  posting: false,
  mainpageIsLoading: false,
  mainpageErrors: null,
  loadingRecentPl: false,
  errorsRecentPL: null,
  tableHeaderHeight: 63,
};

export default function (state = initialState, action) {
  switch (action.type){
    case SET_ERRORS:
      return {
        ...state,
        posting: false,
        mainpageIsLoading: false,
        errors: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        mainpageIsLoading: false,
        errors: null,
        posting: false,
      };
    case LOADING_UI:
      return {
        ...state,
        mainpageIsLoading: true,
      };
    case STOP_LOADING_UI:
      return {
        ...state,
        mainpageIsLoading: false,
      };
    case POSTING_UI:
      return {
        ...state,
        posting: true,
      };
    case STOP_POSTING_UI:
      return {
        ...state,
        posting: false,
      };

    case LOADING_RECENT_PL:
      return {
        ...state,
        loadingRecentPl: true,
      };
    case STOP_LOADING_RECENT_PL:
      return {
        ...state,
        loadingRecentPl: false,
      };
    case SET_ERRORS_RECENT_PL:
      return {
        ...state,
        loadingRecentPl: false,
        errorsRecentPL: action.payload,
      };
    case CLEAR_ERRORS_RECENT_PL:
      return {
        ...state,
        loadingRecentPl: false,
        errorsRecentPL: null,
      };
      case SET_TABLE_HEADER_HEIGHT:
        var height = document.getElementById("subHeader")?.childNodes[0]?.childNodes[0]?.clientHeight;
        return {
          ...state,
          tableHeaderHeight: height
        };
    default:
      return state;
  }
}
