import axios from "axios";
import store from "redux/Store";
import { v4 as uuidv4 } from "uuid";
import { PlaylistItemTypes } from "../../modules/ml-music-search/track/RecentPlaylistMenu";
import { PlayableTypes } from "./../../modules/ml-player/PlayerRef/PlayerRef";
import {
  PLAY_AUDIO,
  SET_AUDIO,
  PAUSE_AUDIO,
  CLEAR_PLAYER,
  SET_PLAY_PROGRESS,
  SET_BUFFER_PROGRESS,
  MUTE_PLAYER,
  UNMUTE_PLAYER,
  SET_PLAYER_VOLUME,
  LOOP_PLAY,
  UNLOOP_PLAY,
  END_OF_AUDIO,
  AUDIO_ERROR,
  ASSET_LOADING,
  ABUSE_MSG_OPEN,
  ABUSE_MSG_CLOSE,
  SET_PLAY_OBJECT,
  SET_PLAYER_MESSAGE,
  SET_POPOUT_FOR, 
} from "./../Types";

var tId;
var trackLoadingId;

//var messageShowTimer;

export const playAudio = (payload) => async (dispatch) => {
  const myPlayObject = payload?.item;
  let playTrack = null;
  let myPlayableType = null;
  if (myPlayObject) {
    myPlayableType = payload?.playableType ?? PlayableTypes.track;
    const {
      player: { audioPlayer, playObject, playableType },
    } = store.getState();

    if (
      playObject &&
      myPlayObject &&
      playObject.id === myPlayObject.id &&
      playableType === myPlayableType
    ) {
      dispatch({ type: PLAY_AUDIO, payload: playTrack });
      audioPlayer.play();
      return;
    }

    audioPlayer.pause();
    if (myPlayableType === PlayableTypes.track) {
      dispatch({
        type: SET_PLAY_OBJECT,
        payload: {
          playObject: myPlayObject,
          playableType: myPlayableType,
          tracks: [myPlayObject],
          track: myPlayObject,
          tracksLoading: false,
        },
      });
      playTrack = myPlayObject;
      audioPlayer.volume=1;
    } else if (myPlayableType === PlayableTypes.playlist) {
      dispatch({
        type: SET_PLAY_OBJECT,
        payload: {
          playObject: myPlayObject,
          playableType: myPlayableType,
          tracks: [],
          track: null,
          tracksLoading: true,
        },
      });
      const {
        user: {
          credentials: { orgID },
        },
      } = store.getState();
      trackLoadingId = uuidv4();
      const response = await axios.post(
        `${orgID}/plcontent/search`,
        {
          plid: myPlayObject.id,
          order: "desc",
          orderBy: "updatedAt",
          page: 0,
          size: 10000,
        },
        {
          headers: { tid: trackLoadingId, orgid: orgID },
        }
      );

      if (response.data.tid !== trackLoadingId) {
        return;
      }

      let tracks =
        response?.data?.results?.filter(
          (t) =>
            (!t.pl_prop_type || t.pl_prop_type === PlaylistItemTypes.track) &&
            t.trackTitle !== undefined
        ) || [];
      playTrack = tracks.length > 0 ? tracks[0] : null;

      dispatch({
        type: SET_PLAY_OBJECT,
        payload: {
          playObject: myPlayObject,
          playableType: myPlayableType,
          tracks: tracks,
          track: playTrack,
          tracksLoading: false,
        },
      });
    } else if (myPlayableType === PlayableTypes.album) {
      dispatch({
        type: SET_PLAY_OBJECT,
        payload: {
          playObject: myPlayObject,
          playableType: myPlayableType,
          tracks: [],
          track: null,
          tracksLoading: true,
        },
      });
      const {
        user: {
          credentials: { orgID },
        },
      } = store.getState();

      trackLoadingId = uuidv4();
      const response = await axios.post(
        `/albumcontentsearch`,
        {
          albumId: myPlayObject.prodId,
          order: "asc",
          orderBy: "position",
          page: 0,
          size: 10000,
          ...(myPlayObject.searchId && {searchId: myPlayObject.searchId}),
        },
        {
          headers: { tid: trackLoadingId, orgid: orgID },
        }
      );
      if (response.data.tid !== trackLoadingId) {
        return;
      }
      let tracks = response?.data?.data || [];
      playTrack = tracks.length > 0 ? tracks[0] : null;
      dispatch({
        type: SET_PLAY_OBJECT,
        payload: {
          playObject: myPlayObject,
          playableType: myPlayableType,
          tracks: tracks,
          track: playTrack,
          tracksLoading: false,
        },
      });
    }
  } else {
    const {
      player: { track },
    } = store.getState();

    playTrack = track;
  }

  if (playTrack) {
    playTrackStart(playTrack, dispatch);
  } else {
    if (payload?.showMessage) {
      payload.showMessage("No tracks found");
    }
    //clearTimeout(messageShowTimer);
    // dispatch({
    //   type: SET_PLAYER_MESSAGE,
    //   payload: { message: "No tracks found" },
    // });
    // messageShowTimer = setTimeout(
    //   () => dispatch({ type: SET_PLAYER_MESSAGE, payload: { message: null } }),
    //   10000
    // );
  }
};

const playTrackStart = (playTrack, dispatch) => {
  if (playTrack.playbackAudio === undefined) {
    const {
      user: {
        credentials: { orgID },
      },
    } = store.getState();

    let trackId = playTrack.id;

    if (playTrack.play_source == "playout") {
      trackId = playTrack.track_id;
      playTrack.id = trackId;
      playTrack.performer = [playTrack.performer];
      playTrack.trackTitle = playTrack.title;
    } else if (playTrack.upload_id) {
      trackId = playTrack.upload_id;
      playTrack.id = trackId;
      playTrack.trackTitle = playTrack.track_name;
    }

    tId = uuidv4();
    dispatch({ type: ASSET_LOADING });
    axios
      .post(
        `${orgID}/playbackasset`,
        { id: trackId, ...(playTrack.searchId && {searchId: playTrack.searchId}) },
        {
          headers: { tid: tId, orgid: orgID },
        }
      )
      .then((res) => {
        if (res.data.tid === tId) {
          // check if an abuse msg has to be displayed
          if (res.data.abuseFlag) {
            playTrack.abuseMsg = res.data.abuseMsg;
            playTrack.abuseFlag = res.data.abuseFlag;
          }

          if (res?.data?.url) {
            playTrack.playbackAudio = res.data.url;
            dispatch({ type: PLAY_AUDIO, payload: playTrack });
            doPlay(playTrack.playbackAudio);
          } else {
            playTrack.playbackAudio = "";
            dispatch({ type: PLAY_AUDIO, payload: playTrack });
            doPlay(playTrack.playbackAudio);
          }
        }
      })
      .catch((err) => {
        playTrack.playbackAudio = "";
        dispatch({ type: PLAY_AUDIO, payload: playTrack });
        doPlay(playTrack.playbackAudio);
      });
  } else {
    if (playTrack && !playTrack.playbackAudio) {
      playTrack.playbackAudio = "";
    }
    dispatch({ type: PLAY_AUDIO, payload: playTrack });
    doPlay(playTrack?.playbackAudio);
  }
};

const doPlay = (playbackAudio) => {
  const {
    player: { audioPlayer },
  } = store.getState();

  if (
    playbackAudio !== undefined &&
    (!audioPlayer.src || audioPlayer.src !== playbackAudio)
  ) {
    audioPlayer.src = playbackAudio ? playbackAudio : "";
  }
  audioPlayer.play();
};

export const pauseAudio = () => (dispatch) => {
  const {
    player: { audioPlayer },
  } = store.getState();
  audioPlayer.pause();
  dispatch({ type: PAUSE_AUDIO });
};

export const clearAudio = () => (dispatch) => {
  dispatch({ type: CLEAR_PLAYER });
};

export const setPopoutFor = (payload) => (dispatch) => {
  dispatch({ type: SET_POPOUT_FOR, payload: payload });
};

export const setAudioProgress = (payload) => (dispatch) => {
  dispatch({ type: SET_PLAY_PROGRESS, payload: payload });
};

export const setAudioBuffer = (payload) => (dispatch) => {
  dispatch({ type: SET_BUFFER_PROGRESS, payload: payload });
};

export const muteAudio = () => (dispatch) => {
  const {
    player: { audioPlayer },
  } = store.getState();
  audioPlayer.muted = true;
  dispatch({ type: MUTE_PLAYER });
};

export const unmuteAudio = () => (dispatch) => {
  const {
    player: { audioPlayer },
  } = store.getState();
  audioPlayer.muted = false;
  dispatch({ type: UNMUTE_PLAYER });
};

export const setVolume = (payload) => (dispatch) => {
  const {
    player: { audioPlayer },
  } = store.getState();

  if (audioPlayer.volume !== payload / 100) {
    audioPlayer.volume = payload / 100;
  }

  dispatch({ type: SET_PLAYER_VOLUME, payload: payload });
};

export const loopPlay = () => (dispatch) => {
  dispatch({ type: LOOP_PLAY });
};

export const unloopPlay = () => (dispatch) => {
  dispatch({ type: UNLOOP_PLAY });
};

export const playPlaylistTrack = (payload) => (dispatch) => {
  const {
    player: { tracks },
  } = store.getState();
  if (tracks && tracks.length > 0) {
    let playbackTrack = tracks?.find((t) => t.id === payload.id);
    if (playbackTrack) {
      playTrackStart(playbackTrack, dispatch);
    }
  }
};

export const endAudio = (payload) => (dispatch) => {
  const {
    player: { audioPlayer, track, tracks, isLoop },
  } = store.getState();

  const {withError, errorMessage} = payload ?? {withError: null, errorMessage: null};

  let playableTracks = tracks.filter((r) => !r.withPlaybackError);
  let playingIndex = playableTracks?.findIndex((t) => t.id === track.id);

  if (withError && playableTracks[playingIndex]) {
    playableTracks[playingIndex].withError = true;
    playableTracks[playingIndex].errorMessage = errorMessage;
  }

  if (playableTracks.length === 1) {
    if (isLoop) {
      audioPlayer.play();
    }
  } else if (playableTracks.length > 1) {
    if (playingIndex >= 0) {
      if (playableTracks.length > playingIndex + 1) {
        let newTrack = playableTracks[playingIndex + 1];
        playTrackStart(newTrack, dispatch);
      } else if (playableTracks.length === playingIndex + 1) {
        let newTrack = playableTracks[0];
        
        if (isLoop) {
          playTrackStart(newTrack, dispatch);
        }
        else {
          if (withError) {
            dispatch({ type: END_OF_AUDIO });
          } else {
            dispatch({ type: SET_AUDIO, payload: newTrack });
          }
        }
      }
    }
  } else {
    dispatch({ type: END_OF_AUDIO });
  }
};

export const abuseMsgClose = () => (dispatch) => {
  dispatch({ type: ABUSE_MSG_CLOSE });
};
export const abuseMsgOpen = () => (dispatch) => {
  dispatch({ type: ABUSE_MSG_OPEN });
};

export const audioError = (payload) => (dispatch) => {
  dispatch({ type: AUDIO_ERROR, payload: payload });
};
