import { actionTypes } from "./HelpActions";
import { SortTypes } from "../../../../util/table-view/TableTypes";
import { defaultPopupResults, pagingTotalRelation } from "../../../../redux/Types";

const initialState = {
  editing: null,
  onMessageCreated: null,
  closeMessagePanel: null,
  posting: { busy: false, message: "", errors: null },
  items: [],
  helpItems: {},
  allItems: {},
  totalItems: 0,
  totalRelation: pagingTotalRelation.EQUALS,
  selectedItem: {},
  selectedItems: [],
  loading: false,
  loadingContent: {},
  drawerOpen: false,
  cConfig: null,
  query: {
    inPage: "all",
    order: SortTypes.desc,
    orderBy: "updatedAt",
    page: 0,
    size: defaultPopupResults,
    q: "",
    filters: [],
  },

  contentQuery: {
    category: "",
    title: "",
  },
  errors: null,
  privateVideoList:[],
  privateVideoListLoading : false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOADING_CONTENT:
      return {
        ...state,
        loadingContent: Object.assign({}, state.loadingContent, action.loading),
        errors: null,
      };

    case actionTypes.LOAD_CONTENT:
      return {
        ...state,
        loadingContent: Object.assign({}, state.loadingContent, action.loading),

        errors: null,
        helpItems: Object.assign({}, state.helpItems, action.payload),
        allItems: Object.assign({}, state.allItems, action.payload),
      };

    case actionTypes.LOAD_SEARCH_CONTENT:
      return {
        ...state,
        loadingContent: Object.assign({}, state.loadingContent, action.loading),
        errors: null,
        helpItems: Object.assign({}, state.helpItems, action.payload),
      };

    case actionTypes.LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
        selectedItems: [],
        items: [],
      };

    case actionTypes.LOAD:
      return {
        ...state,
        loading: false,
        errors: null,
        items: action.payload,
        totalItems: action.total,
        totalRelation: action.totalRelation,
        selectedItem: action.payload[0] || {},
        errors: null,
        selectedItems: [],
      };

    case actionTypes.FILTER_SEARCH:
      return {
        ...state,
        loadingContent: false,
        errors: null,
        helpItems: Object.assign({}, state.helpItems, action.payload),
      };

    case actionTypes.ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        items: [],
        totalItems: 0,
        totalRelation: pagingTotalRelation.EQUALS,
      };

    case actionTypes.ERROR_CONTENT:
      return {
        ...state,
        loading: false,
        loadingContent: Object.assign({}, state.loadingContent, action.loading),
        errors: action.payload,
        helpItems: {},
        allItems: {},
      };

    case actionTypes.QUERY:
      return {
        ...state,
        query: action.payload,
      };

    case actionTypes.CONTENT_QUERY:
      return {
        ...state,
        contentQuery: action.payload,
      };

    case actionTypes.DRAWER_OPEN:
      return {
        ...state,
        drawerOpen: action.payload.drawerOpen,
        cConfig: action.payload.config
      };

    case actionTypes.ALL_ITEMS:
      return {
        ...state,
        allItems: action.payload,
      };

    case actionTypes.FOCUS_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
      };

    case actionTypes.EDIT_ITEM:
      return {
        ...state,
        editing: action.payload,
      };

    case actionTypes.POSTING:
      return {
        ...state,
        posting: {
          busy: true,
          message: action.payload,
          errors: null,
        },
      };

    case actionTypes.POST_OK:
      return {
        ...state,
        posting: {
          busy: false,
          message: action.payload ? action.payload : "",
          errors: null,
        },
      };

    case actionTypes.POST_ERROR:
      return {
        ...state,
        posting: {
          busy: false,
          message: "",
          errors: action.payload,
        },
      };

    case actionTypes.SELECT_ITEMS:
      return {
        ...state,
        selectedItems: action.payload,
      };

    case actionTypes.CLEAR_ERRORS:
      return {
        ...state,
        posting: {
          errors: null,
        },
      };

    case actionTypes.LOADING_PRIVATE_VIDEOS:
      return {
        ...state,
        privateVideoList: [],
        privateVideoListLoading: true,
        errors: null,
      };

    case actionTypes.LOAD_PRIVATE_VIDEOS:
      return {
        ...state,
        privateVideoList: action.payload,
        privateVideoListLoading: false,
        errors: null,
      };
    case actionTypes.ERROR_PRIVATE_VIDEOS:
      return {
        ...state,
        privateVideoList: [],
        privateVideoListLoading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
}
