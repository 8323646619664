import { T_LeafBool, T_Branch, T_Major } from "./ModulePropTypes";
import { PT_Org } from "./ModulePermitTypes";

export const _manageMlLiStatuses = {
  id: "mlli1",
  type: T_LeafBool,
  default: true,
};

export const _manageMlLiParams = {
  id: "mlli2",
  type: T_LeafBool,
  default: true,
};

export const _manageMlLiRules = {
  id: "mlli3",
  type: T_LeafBool,
  default: true,
};
export const _canRaiseContentAlert = {
  id: "mlli4",
  type: T_LeafBool,
  default: false,
};

export const _mlLicensingAdmin = {
  id: "mlLicesingAdmin",
  permitType: PT_Org,
  type: T_Major,
  modules: [_manageMlLiParams, _manageMlLiStatuses, _manageMlLiRules, _canRaiseContentAlert],
};
