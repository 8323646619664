import firebase from "firebase/app";
import "firebase/firestore";

const {
  REACT_APP_FB_apiKey,
  REACT_APP_FB_authDomain,
  REACT_APP_FB_databaseURL,
  REACT_APP_FB_projectId,
  REACT_APP_FB_storageBucket,
  REACT_APP_FB_messagingSenderId,
  REACT_APP_FB_appId,
  REACT_APP_FB_measurementId,
} = process.env;


firebase.initializeApp({
  apiKey: REACT_APP_FB_apiKey,
  authDomain: REACT_APP_FB_authDomain,
  databaseURL: REACT_APP_FB_databaseURL,
  projectId: REACT_APP_FB_projectId,
  storageBucket: REACT_APP_FB_storageBucket,
  messagingSenderId: REACT_APP_FB_messagingSenderId,
  appId: REACT_APP_FB_appId,
  measurementId: REACT_APP_FB_measurementId,
});
export const fs = firebase.firestore();