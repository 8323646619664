import { actionTypes } from "../actions/ClearanceRuntimeActions";

const initialState = {
  loading: false,
  runtime: null,
  errors: null,
  ctags: {},
  ctagErrors: {},
  adminOverride: {},
  copyLiveIds: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CTAG_ERROR:
      return {
        ...state,
        ctagErrors: action.payload,
      };
    case actionTypes.CTAG:
      return {
        ...state,
        ctags: action.payload,
      };

    case actionTypes.RESET_CTAG:
      return {
        ...state,
        ctags: {},
        ctagErrors: {},
      };

    case actionTypes.LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
        runtime: null,
      };
    case actionTypes.LOAD:
      return {
        ...state,
        errors: null,
        runtime: action.payload,
        loading: false,
      };
    case actionTypes.ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        runtime: {
          _rules: [],
          _statuses: [],
          _params: [],
        },
      };
    case actionTypes.SET_COPY_LIVE_IDS:
      return {
        ...state,
        copyLiveIds: { ...state.copyLiveIds, ...action.payload },
      };
    case actionTypes.REMOVE_COPY_LIVE_IDS:
      delete state.copyLiveIds[action.payload];
      return {
        ...state,
        copyLiveIds: state.copyLiveIds,
      };
    default:
      return state;
  }
}
